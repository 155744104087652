/**
 * Courses Routing File
 */

import AuthProvider from 'context/AuthContext';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncNotificationManuelleComponent,
	AsyncNotificationAutomatiqueComponent,
    AsyncNotificationSupportComponent,
    AsyncNotificationStatusComponent

} from 'routes';
const Notification = ({ match }) => (
	<Switch>
            <AuthProvider>
				<ProtectedRoute path={`${match.url}/manuelle_notifikation`} component={AsyncNotificationManuelleComponent} route={"manuelle_notifikation"} ></ProtectedRoute>
				<ProtectedRoute path={`${match.url}/automatischen_notifikation`} component={AsyncNotificationAutomatiqueComponent} route={"automatischen_notifikation"} ></ProtectedRoute>		
				<Route path={`${match.url}/auftragsstatus`} component={AsyncNotificationStatusComponent}></Route>		
				<Route path={`${match.url}/servicemitteilung`} component={AsyncNotificationSupportComponent}></Route>		
                </AuthProvider>
        
	</Switch>
)
export default Notification;