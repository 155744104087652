import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { userService } from "../../src/_services/user.service.js";
import { companyService } from "../../src/_services/company.service.js";

const ProtectedRoute = ({ component: Component, route, ...rest }) => {
  const user = userService.getId();
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState("");
  const [authorized, setAuthorized] = useState(true);
  const CheckUserAdmin = async () => {
    const userDoc = await userService.getUser(user);

    userDoc.forEach(async (doc) => {
      const userObject = doc.data();
      const companyId = userObject.company_id;
      setCompanyId(companyId);
      const company = await companyService.getCompany(companyId);
      setCompany(company);
      switch (route) {
        case "groups":
        case "realestate_import":
        case "realestate_export":
        case "Rechtevergabe":
        case "Emaileinstellungen":
          if (
            (userObject?.role === "admin" ||
              userObject?.role === "tester" ||
              userObject?.role === "makler") &&
            (company?.company_account_type === "premium" ||
              company?.company_account_type === "power")
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        case "tickets":
        case "ticket_id":
          if (userObject?.role === "admin") {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        case "Unternehmen":
          if (
            userObject?.role === "makler" ||
            userObject?.role === "admin" ||
            userObject?.role === "tester"
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        case "ticket":
          if (
            (userObject?.role != "admin" &&
              company.company_account_type == "premium") ||
            userObject?.role === "admin"
          ) {
            setAuthorized(true);
          }
          break;
        case "switch_user":
          if (userObject?.role === "admin" || userObject?.role === "tester") {
            setAuthorized(true);
          }
          break;
        case "manuelle_notifikation":
        case "automatischen_notifikation":
          if (userObject?.role === "admin" || userObject?.role === "tester") {
            setAuthorized(true);
          }
          break;
        case "archiv_list":
          if (
            userObject?.role === "admin" ||
            userObject?.role === "tester" ||
            company.company_account_type == "premium"
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        case "product_import":
          if (userObject?.role == "admin") {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        case "mitarbeiter":
        case "protocol_pattern":
          if (
            (userObject?.role === "admin" ||
              userObject?.role === "tester" ||
              userObject?.role === "makler") &&
            (company?.company_account_type === "premium" ||
              company?.company_account_type === "power")
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
        case "promo_code":
          if (
            userObject?.role === "admin" ||
            (userObject?.role != "admin" &&
              company?.company_account_type === "basic")
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
          break;
        default:
          break;
      }
    });
  };
  useEffect(async () => {
    await CheckUserAdmin();
  }, [user, route]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authorized ? <Component {...props} /> : <Redirect to="/app/dashboard" />
      }
    />
  );
};
export default ProtectedRoute;
