import {
   query,
   setDoc,
   updateDoc,
   deleteDoc,
   doc,
   where,
   collection,
   getDocs,
   Timestamp,
   serverTimestamp,
 } from "firebase/firestore";
 import auth, { db } from "../config/firebase";
 import jwt_decode from "jwt-decode";
 import { DateFormatter } from "../helpers/DateFormatter";
 
 export const userService = {
   createUser,
   updateUser,
   getUser,
   getAllUsers,
   getUsersbyCompanyID,
   getSubMaklers,
   getMaklers,
   getAdminUser,
   decodeToken,
   getId,
   deleteUser
 };
 
 
 
 const formatQuerySnapshotToArray = async (querySnapshot) => {
   const dataArray = [];
 
   querySnapshot.forEach((doc) => {
     const data = doc.data();
     dataArray.push(data);
   });
 
   return dataArray;
 };
 
 async function createUser(user, type = null) {
   const docRef = doc(db, "xc_user", user.id);
   if (user) {
     if (type == null && user.agb_date != null) {
       user.agb_date = new Timestamp(
         user?.agb_date.seconds,
         user?.agb_date.nanoseconds
       );
     }
   }
 
   return await setDoc(docRef, {
     ...user,
     last_login: DateFormatter.initDate(),
     created_at: DateFormatter.initDate(),
     updated_at: serverTimestamp(),
   });
 }
 
 async function updateUser(user) {
   const docRef = doc(db, "xc_user", user.id); 
   return await updateDoc(docRef, user);
 }
 
 async function getUser(user_id) {
   let userQuerry = query(
     collection(db, "xc_user"),
     where("firebase_auth_id", "==", user_id)
   );
   const userDoc = await getDocs(userQuerry);
   return userDoc;
 }
 async function deleteUser(user_id) {
   const docRef = doc(db, "xc_user", user_id);
   await deleteDoc(docRef);
 }
 async function getUsersbyCompanyID(company_id) {
   let userQuerry = query(
     collection(db, "xc_user"),
     where("company_id", "==", company_id)
   );
   const userDoc = await getDocs(userQuerry);
   const formattedArray = await formatQuerySnapshotToArray(userDoc);
   return formattedArray;
 }
 
 async function getAllUsers() {
   let userQuerry = query(collection(db, "xc_user"));
   const userDoc = await getDocs(userQuerry);
   const formattedArray = await formatQuerySnapshotToArray(userDoc);
   return formattedArray;
 }
 
 async function getSubMaklers(company_id) {
   let userQuerry = query(
     collection(db, "xc_user"),
     where("role", "==", "subMakler"),
     where("company_id", "==", company_id)
   );
   const userDoc = await getDocs(userQuerry);
   const formattedArray = await formatQuerySnapshotToArray(userDoc);
   return formattedArray;
 }
 
 async function getMaklers(company_id) {
   let userQuerry = query(
     collection(db, "xc_user"),
     where("role", "==", "makler"),
     where("company_id", "==", company_id)
   );
   const userDoc = await getDocs(userQuerry);
   const formattedArray = await formatQuerySnapshotToArray(userDoc);
   return formattedArray;
 }
 async function getAdminUser(company_id) {
   let userQuerry = query(
     collection(db, "xc_user"),
     where("role", "==", "admin"),
     where("company_id", "==", company_id)
   );
   const userDoc = await getDocs(userQuerry);
   const formattedArray = await formatQuerySnapshotToArray(userDoc);
   return formattedArray;
 }
 function decodeToken(token) {
   var decoded = jwt_decode(token);
   return decoded;
 }
 
 function getId() {
   if (
     localStorage.getItem("switchedcustomer") &&
     localStorage.getItem("switchedcustomer") !== ""
   ) {
     return JSON.parse(localStorage.getItem("switchedcustomer"));
   } else if (localStorage.getItem("currentuser")) {
     return decodeToken(JSON.parse(localStorage.getItem("currentuser"))).user_id;
   }
 }
 