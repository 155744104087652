import React, { useState, useCallback, useRef, useEffect } from "react";
import keyIcon from "../../assets/Icons/key-icon.svg";
import ValidIcon from "../../assets/Icons/valid-icon.svg";
import RaumeIcon from "../../assets/Icons/raume-icon.svg";
import CalenderIcon from "../../assets/Icons/calander-icon.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";
import HouseIcon from "@mui/icons-material/House";

import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import Previous from "@mui/icons-material/ArrowBackIos";
import Next from "@mui/icons-material/ArrowForwardIos";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Direction from "@mui/icons-material/SubdirectoryArrowRightSharp";
import damage from "../../assets/Icons/damage.svg";
import Config from "@mui/icons-material/Construction";
import DateIcon from "@mui/icons-material/CalendarMonth";

import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import WohnungsdatenUserIcon from "../../assets/Icons/user-icon.svg";

import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ZumIcon from "../../assets/Icons/zum-icon.svg";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import swal from "sweetalert";
import swalAlert from "../../components/SaveButton/index";
import moment from "moment";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import Dialog from "../../components/Dialog/dialog-autocomplete";
import HideComment from "@mui/icons-material/CommentsDisabled";
import Comment from "@mui/icons-material/Comment";
import { protocolService } from "../../_services/protocol.service";
import { userService } from "../../_services/user.service.js";
import { makeStyles } from "@material-ui/core";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: "relative",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    padding: "10px",
    width: "100%",
    textAlign: "justify",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}));
function ProtokollOvwerView(props) {
  const history = useHistory();
  const location = useLocation();
  const [protocolToShow, setProtocolToShow] = useState();
  const [showImage, setShowImage] = useState(false);
  const [idImageShow, setIdImageShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState({ label: "", value: "" });
  const [status, setStatus] = useState(null);
  const [showstatus, setShowStatus] = useState(true);
  const [level, setLevel] = useState(0);
  const [value, setvalue] = useState("");
  const [type, setType] = useState("");
  const [index, setIndex] = useState(null);
  const [secondIndex, setSecondIndex] = useState(null);
  const [placeholder, setPlaceHolder] = useState("");
  const [CurrentPageByRoom, setCurrentPageByRoom] = useState([]);
  const imagesPerPage = 6;
  const [selectedId, setSelectedId] = useState("");
  const [comment, setComment] = useState(false);
  const classes = useStyles();
  const [clickedOnBullet, setClickedOnBullet] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexMangel, setActiveIndexMangel] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesByRoom, setPagesByRoom] = useState([]);
  const [show, setShow] = useState(false);
  const toggleImage = (index) => {
    setIdImageShow(index);
    setShowImage(!showImage);
  };
  let firstcounterID = generateID(36);
  let secondcounterID = generateID(36);
  let thirdcounterID = generateID(36);
  let firstkeyid = generateID(36);
  let secondkeyid = generateID(36);
  const idRoom1 = generateID(36);
  const idRoom2 = generateID(36);
  const idRoom3 = generateID(36);
  const idRoom4 = generateID(36);
  const now_date = new Date();
  const utc_time = new Date(
    now_date.getTime() + now_date.getTimezoneOffset() * 60000
  );
  const [alert, setAlert] = useState(null);
  let note = window.location.pathname.startsWith("/app/protocolcontrol")
    ? "create"
    : window.location.pathname.startsWith("/app/update_protocolcontrol") &&
    "update";

  function cancel() {
    history.push("/app/overview");
  }

  function generateID(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
  const [protocol, setProtocol] = useState(
    protocolToShow || {
      protocol_type: "",
      date_of_hand_over: null,
      id: "",
      protocol_counter: [
        {
          counter_name: null,
          counter_is_double: "singleCounter",
          counter_number: null,
          counter_value_1: null,
          market_location_number: "",
          counter_location: null,
          counter_type: "electricity",
          counter_value_2: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: firstcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
        {
          counter_name: null,
          counter_number: null,
          counter_value_1: null,
          market_location_number: "",
          counter_location: null,
          counter_type: "gas",
          counter_is_double: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: secondcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
        {
          counter_name: null,
          counter_location: null,
          counter_number: null,
          counter_type: "watter",
          counter_is_double: null,
          counter_value_1: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: thirdcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
      ],
      protocol_keys: [
        {
          controle_comment: null,
          created_at: utc_time,
          id: firstkeyid,
          key_type: "frontDoor",
          key_name: null,
          key_notice: null,
          key_date_of_forwarding: null,
          key_number: "",
          number_keys_returned: 0,
          number_keys_returned_in_main_protocol: null,
          property_key_ref_id: null,
          updated_at: utc_time,
          image: {
            key_image_local_path: null,
            key_image_remote_url: null,
            key_image_name: null,
            key_image_thumb: null,
            key_image_timestamp: null,
            id: null,
          },
        },
        {
          controle_comment: null,
          created_at: utc_time,
          id: secondkeyid,
          key_type: "mainEntrance",
          key_name: null,
          key_notice: null,
          key_date_of_forwarding: null,
          key_number: "",
          number_keys_returned: 0,
          number_keys_returned_in_main_protocol: null,
          property_key_ref_id: null,
          updated_at: utc_time,
          image: {
            key_image_local_path: null,
            key_image_remote_url: null,
            key_image_name: null,
            key_image_thumb: null,
            key_image_timestamp: null,
            id: null,
          },
        },
      ],
      protocol_rooms: [
        {
          room_deffect: null,
          id: idRoom1,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "kitchen",
          room_type: "kitchen",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom2,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "bedroom",
          room_type: "bedroom",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom3,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "livingRoom",
          room_type: "livingRoom",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom4,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "bathroom",
          room_type: "bathroom",
          room_updated_at: utc_time,
        },
      ],
      has_corenter: false,
      renter: {
        renter_date_of_birth: null,
        renter_email: null,
        renter_first_name: null,
        renter_last_name: null,
        renter_phone: null,
        renter_salutation: "noChoice",
        renter_title: "noChoice",
        address: {
          renter_address_note: null,
          renter_city: null,
          renter_house_number: null,
          renter_plz: null,
          renter_previous_new_adress: false,
          renter_srteet: null,
        },
        other_renter: null,
        signature_renter: {
          renter_refuse_signature: false,
          signature_renter: false,
          signature_renter_image_name: null,
          signature_renter_image_remote_url: null,
          signature_renter_local_path_image: null,
          signature_renter_refuse_reason: null,
          signature_renter_time_stamp: null,
          signature_renter_thumb: null,
        },
      },
      coRenter: {
        first_name: null,
        last_name: null,
        salutation: "noChoice",
        signature_coRenter: {
          image_name: null,
          local_path: null,
          refuse_reason: null,
          refuse_signature: false,
          remote_url: null,
          signature: false,
          thumb: null,
          time_stamp: null,
        },
        title: "noChoice",
      },
      owner_is_makler: false,
      object: {
        object_id: "",
        object_status: null,
        object_type: null,
        real_state_id: null,
        real_state_object_number: 1,
        hasManyObject: false,
        object_date_of_renovation: null,
        address: {
          object_street: null,
          object_house_nbr: null,
          object_position: null,
          object_address_note: null,
          object_plz: null,
          object_city: null,
          object_notice: null,
        },
      },
      landlord: {
        landlord_company_name: null,
        landlord_email: null,
        landlord_first_name: null,
        landlord_last_name: null,
        landlord_phone: null,
        landlord_salutation: "noChoice",
        landlord_title: "noChoice",
        landlord_type: "noChoice",
        landlord_mobile: null,
        address: {
          landlord_address_note: null,
          landlord_city: null,
          landlord_house_number: null,
          landlord_plz: null,
          landlord_street: null,
        },
        signature_landlord: {
          signature_landlord: false,
          signature_landlord_image_name: null,
          signature_landlord_image_remote_url: null,
          signature_landlord_local_path_image: null,
          signature_landlord_time_stamp: null,
        },
      },
      previous_renter: {
        previous_renter_email: null,
        previous_renter_first_name: null,
        previous_renter_last_name: null,
        previous_renter_phone_number: null,
        previous_renter_salutation: "noChoice",
        previous_renter_title: "noChoice",
        address: {
          previous_renter_address_note: null,
          previous_renter_city: null,
          previous_renter_housnumber: null,
          previous_renter_plz: null,
          previous_renter_new_adress: false,
          previous_renter_srteet: null,
        },
        signature_previous_renter: {
          previous_renter_refuse_signature: false,
          signature_previous_renter: false,
          signature_previous_renter_image_name: null,
          signature_previous_renter_image_remote_url: null,
          signature_previous_renter_local_path_image: null,
          signature_previous_renter_refuse_reason: null,
          signature_previous_renter_time_stamp: null,
        },
      },
      owner: {
        owner_Type: "noChoice",
        owner_company_name: null,
        owner_salutation: "noChoice",
        owner_title: "noChoice",
        owner_first_name: null,
        owner_last_name: null,
        owner_email: null,
        owner_phone: null,
        address: {
          owner_address_note: null,
          owner_city: null,
          owner_house_number: null,
          owner_plz: null,
          owner_street: null,
        },
      },
      log_book_id: null,
      note_taker: {
        note_taker_is_makler: false,
        note_taker_is_landlord: false,
        note_taker_first_name: null,
        note_taker_last_name: null,
        note_taker_title: null,
        note_taker_gender: null,
      },
    }
  );
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const inputRefs = useRef([]);
  const handleInputClick = (e) => {
    const name = e.target.name;
    setShrinkLabel((prev) => ({ ...prev, [name]: true }));
  };
  // get protocol
  const getProtocolToShow = async () => {
    if (location.state.state.hasOwnProperty("doc_type")) {
      await protocolService
        .getProtocol(location.state.state.id_doc)
        .then((result) => {
          if (result) {
            setProtocolToShow(result.data());
          }
        });
    } else {
      setProtocolToShow(location.state.state);
    }
  };
  useEffect(() => {
    getProtocolToShow();
  }, [location.state]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      for (let i = 0; i < 20; i++) {
        if (
          inputRefs.current[i] &&
          !inputRefs.current[i].contains(event.target)
        ) {
          setShrinkLabel(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [protocol]);
  useEffect(() => {
    if (protocolToShow) {
      setProtocol((prevProtocol) => ({ ...prevProtocol, ...protocolToShow }));

      if (protocolToShow.note_taker.note_taker_is_landlord)
        setSelectedValue({
          label: "Protkollant/in aus Wohnungsgeber übernehmen",
          value: "note_taker_from_landlord",
        });
      if (protocolToShow.note_taker.note_taker_is_makler)
        setSelectedValue({
          label: "Protokollant/in aus Benutzerkonto der App übernehmen",
          value: "note_taker_from_current_user",
        });
    }
  }, [protocolToShow]);
  const next = (index, type = null) => {
    if (animating || clickedOnBullet) return;
    const nextIndex =
      activeIndex === protocolToShow.protocol_rooms[index].room_image.length - 1
        ? 0
        : activeIndex + 1;
    if (type == null) setActiveIndex(nextIndex);
    else setActiveIndexMangel(nextIndex);
  };
  const previous = (index, type = null) => {
    if (animating) return;
    const prevIndex =
      activeIndex === 0
        ? protocolToShow.protocol_rooms[index].room_image.length - 1
        : activeIndex - 1;
    if (type == null) setActiveIndex(prevIndex);
    else setActiveIndexMangel(prevIndex);
  };
  const goToIndexMangel = (newIndex) => {
    if (animating) return;
    setClickedOnBullet(true);
    setActiveIndexMangel(newIndex);
  };
  const handleExiting = () => {
    setAnimating(true);
  };
  // Function to update selected index to true
  const handleCurrentPageByRoom = (page, i) => {
    setCurrentPage(page)
    // Updating the array based on condition index === i
    setCurrentPageByRoom(prev => {
      const updatedCurrentPageByRoom = CurrentPageByRoom.map((value, index) => {
        return index === i ? page : value;
      });
      return updatedCurrentPageByRoom;
    });
  }
  // Function to handle pagination for the selected room
  const paginate = (pageNumber, id) => {
    handleCurrentPageByRoom(pageNumber, id)


  };
  function handleNoteTakerChange(event) {
    const { name, value } = event.target;

    setProtocol((prevProtocol) => ({
      ...prevProtocol,
      note_taker: {
        ...prevProtocol.note_taker,
        [name]: value,
      },
    }));
  }

  async function getNoteTakeInfo() {
    try {
      const userDoc = await userService.getUser(userService.getId());

      if (userDoc.empty) {
        console.error("User not found");
        return;
      }

      const promises = userDoc.docs.map(async (user_doc) => {
        const note_taker = {
          note_taker_first_name: user_doc.data().first_name,
          note_taker_last_name: user_doc.data().last_name,
          note_taker_gender:
            user_doc.data().gender?.toLowerCase() || "noChoice",
          note_taker_title: user_doc.data()?.title || "noChoice",
        };

        return {
          note_taker,
        };
      });

      const results = await Promise.all(promises);

      results.forEach(({ note_taker }) => {
        setProtocol((prevProtocol) => ({
          ...prevProtocol,

          note_taker: {
            ...prevProtocol.note_taker,
            note_taker_is_makler: true,
            note_taker_is_landlord: false,
            note_taker_first_name: note_taker.note_taker_first_name,
            note_taker_last_name: note_taker.note_taker_last_name,
            note_taker_gender: note_taker.note_taker_gender,
            note_taker_title: note_taker.note_taker_title,
          },
        }));
      });
    } catch (err) {
      console.error(err);
    }
  }

  const CustomDropdown = ({ options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setSelectedValue(option);
      if (option.value == "note_taker_from_landlord") {
        const requiredOwnerFields = [
          "landlord_salutation",
          "landlord_first_name",
          "landlord_last_name",
        ];
        const hasMissingField = requiredOwnerFields.some((field) => {
          return (
            !protocol.landlord ||
            !protocol.landlord[field] ||
            protocol.landlord[field] === ""
          );
        });
        if (hasMissingField) {
          swal({
            title: "HINWEIS",
            text: "Bitte füllen Sie vorher die Daten des Wohnungsgebers aus um diesen als Protokollanten zu übernehmen.",
            button: "OK",
          }).then((value) => {
            if (value) {
              window.scrollTo(0, 0);
            }
          });
        } else {
          setProtocol((prevProtocol) => ({
            ...prevProtocol,

            note_taker: {
              ...prevProtocol.note_taker,
              note_taker_is_landlord: true,
              note_taker_is_makler: false,
              note_taker_first_name: protocol.landlord.landlord_first_name,
              note_taker_last_name: protocol.landlord.landlord_last_name,
              note_taker_gender: protocol.landlord.landlord_salutation,
              note_taker_title: protocol.landlord.landlord_title,
            },
          }));
        }
      }
      if (option.value == "note_taker_from_current_user") {
        getNoteTakeInfo();
      }
      if (option.value == "new_note_taker") {
        setProtocol((prevProtocol) => ({
          ...prevProtocol,

          note_taker: {
            note_taker_is_landlord: false,
            note_taker_is_makler: false,
            note_taker_first_name: null,
            note_taker_last_name: null,
            note_taker_gender: null,
            note_taker_title: null,
          },
        }));
      }

      setIsOpen(false);
    };
    return (
      <div className="custom-dropdown">
        <div className="selected-option" onClick={toggleDropdown}>
          {selectedValue.label !== ""
            ? selectedValue.label
            : selectedOption.label}
          <KeyboardArrowDownIcon />
        </div>
        {isOpen && (
          <div className="options">
            {options.map((option, index) => (
              <div
                key={option.value}
                className="option"
                value={
                  option.value === selectedValue.value
                    ? option.value
                    : selectedOption.value
                }
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const handleOk = useCallback((e, index, i) => {
    setStatus(true);
    setShowStatus(false);
    setProtocol((prevProtocol) => {
      const roomImage = prevProtocol.protocol_rooms[index].room_deffect[i];
      const updatedRoom = { ...roomImage, ["controle_corrected"]: true };
      prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
      return {
        ...prevProtocol,
        protocol_rooms: prevProtocol.protocol_rooms,
      };
    });
  }, []);

  const handleMangel = useCallback((e, index, i) => {
    setStatus(false);
    setShowStatus(false);
    setProtocol((prevProtocol) => {
      const roomImage = prevProtocol.protocol_rooms[index].room_deffect[i];
      const updatedRoom = { ...roomImage, ["controle_corrected"]: false };
      prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
      return {
        ...prevProtocol,
        protocol_rooms: prevProtocol.protocol_rooms,
      };
    });
  }, []);

  function handleChangeComment(name, value, index, i = null) {
    if (i !== null) {
      setProtocol((prevProtocol) => {
        const roomDeffect = prevProtocol.protocol_rooms[index].room_deffect[i];
        const updatedRoom = {
          ...roomDeffect,
          [name]: value,
        };
        prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    } else {
      setProtocol((prevProtocol) => {
        const room = prevProtocol.protocol_rooms[index];
        const updatedRoom = { ...room, [name]: value };
        prevProtocol.protocol_rooms[index] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    }
  }

  const [counters, setCounters] = useState(
    Array(protocol?.protocol_keys?.length).fill(0)
  );

  const CompteuraddKey = useCallback(
    (e, s) => {
      let myBasicInt;
      if (
        protocolToShow.protocol_keys[s].key_number !== "" &&
        protocolToShow.protocol_keys[s].key_number !== null
      ) {
        myBasicInt = parseInt(protocolToShow.protocol_keys[s].key_number, 10);
        const myInt = parseInt(
          protocolToShow.protocol_keys[s].number_keys_returned_in_main_protocol,
          10
        );
        let newINT = myInt + 1;
        // let max = myInt + counters[s];
        let max = myInt + protocolToShow.protocol_keys[s].number_keys_returned;
        if (max != myBasicInt) {
          setCounters((prevCounters) => {
            const updatedCounters = [...prevCounters];
            updatedCounters[s] = updatedCounters[s] + 1;
            return updatedCounters;
          });
          if (
            window.location.pathname.startsWith("/app/update_protocolcontrol")
          ) {
            let ReturnedKey =
              protocol.protocol_keys[s].number_keys_returned + 1;
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: ReturnedKey,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          } else {
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: counters[s] + 1,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          }
        }
      }
    },
    [counters]
  );

  const CompteurRemoveKey = useCallback(
    (e, s) => {
      if (
        window.location.pathname.startsWith("/app/protocolcontrol") ||
        window.location.pathname.startsWith("/app/update_protocolcontrol")
      ) {
        const myInt = parseInt(
          protocolToShow?.protocol_keys[s]
            ?.number_keys_returned_in_main_protocol,
          10
        );
        let newINT = myInt - 1;
        if (protocol.protocol_keys[s].number_keys_returned > 0) {
          setCounters((prevCounters) => {
            const updatedCounters = [...prevCounters];
            updatedCounters[s] = updatedCounters[s] - 1;
            return updatedCounters;
          });
          if (
            window.location.pathname.startsWith("/app/update_protocolcontrol")
          ) {
            let ReturnedKey =
              protocol.protocol_keys[s].number_keys_returned - 1;
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: ReturnedKey,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          } else {
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: counters[s] - 1,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          }
        }
      }
    },
    [counters]
  );

  function handleChangeKey(name, value, index) {
    setProtocol((prevProtocol) => {
      const myKey = prevProtocol.protocol_keys[index];
      const updatedKey = { ...myKey, [name]: value };
      prevProtocol.protocol_keys[index] = updatedKey;
      return {
        ...prevProtocol,
        protocol_keys: prevProtocol.protocol_keys,
      };
    });
  }

  const onSubmit = () => {
    const alert = swalAlert(handleCancel, handleThirdButton, handleConfirm);
    setAlert(alert);
  };

  const handleCancel = () => {
    setAlert(null);
  };

  const handleThirdButton = () => {
    setAlert(null);
    history.push("/overview?type=protocol");
  };

  const handleConfirm = () => {
    setAlert(null);
    let newProtocol;
    if (window.location.pathname.startsWith("/app/protocolcontrol")) {
      newProtocol = protocolService.initProtocolControl(
        protocol,
        protocolToShow.company_id
      );
    } else if (
      window.location.pathname.startsWith("/app/update_protocolcontrol")
    ) {
      newProtocol = protocolService.updateProtocolControl(
        protocol,
        protocolToShow.company_id
      );
    }

    try {
      protocolService.createProtocol(newProtocol).then((result) => {
        swal({
          title: "HINWEIS",
          text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
          button: "OK",
        }).then((result) => {
          if (result) {
            history.push("/app/protocol/list");

          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  function formatReason(reason) {
    if (reason == "notPresent") return "Nicht anwesend";
    else if (reason == "refuseToSign") return "Verweigert Unterschrift";
    else return "Keine Unterschrift";
  }

  const handleClickTextfield = (
    type,
    value,
    level,
    index = null,
    placeholder,
    i
  ) => {
    setPlaceHolder(placeholder);
    setIndex(index);
    setSecondIndex(i);
    setLevel(level);
    setvalue(value);
    setType(type);
    setShow(true);
  };
  return (
    <>
      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div className="row layoutcontent full-height mr-0 ml-0">
          <div className="einzug width">
            <HouseIcon
              className="RaumDetailsIcon"
              style={{
                width: "40px",
                height: "40px"
              }}
            />
            <h3>
              kontroll-protokoll
            </h3>
          </div>
          <div className="Weiter align-end-button">
            <button
              onClick={() => cancel()}
              className="WeiterButton"
              type="submit"
            >
              Zurück
              <ArrowForwardIosIcon
                className="icon-registrieren"
                sx={{ fontSize: 16 }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">


            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "uppercase" }}> Protokolltyp </h3>
            </div>
            <div className="card-shadow" style={{ marginBottom: "2rem" }}>

              <div className="after-cards" >
                <div className="formAbschlussOvwerview">
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6">
                      <span className="uppercase">Art des Protokolls</span>
                      <div className=" text-style">
                        <span>
                          {protocol?.protocol_type == null && "Kein Angabe "}
                          {protocol?.protocol_type == "move" && "Einzug "}
                          {protocol?.protocol_type == "moveIn" && "Auszug "}
                          {protocol?.protocol_type == "moveInAndExit" &&
                            "Ein- und Auszug "}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <span className="uppercase">Datum der Übergabe</span>
                      <div className="text-style">
                        <span>
                          {protocol?.date_of_hand_over
                            ? protocol?.date_of_hand_over
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "uppercase" }}> Abschluss </h3>
            </div>
            <div className="card-shadow" >

              <div className="after-cards pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase">Wohnungsdaten</span>
                  </div>
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6">
                      <span className="uppercase">Adresse</span>
                      <div className="mb-2 text-style">
                        <span>
                          {protocolToShow?.object?.address?.object_street
                            ? protocolToShow?.object?.address?.object_street
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_house_nbr
                            ? protocolToShow?.object?.address?.object_house_nbr
                            : "Hausnummer fehlt"}
                        </span>
                        <br />
                        <span>
                          {protocolToShow?.object?.address?.object_plz
                            ? protocolToShow?.object?.address?.object_plz
                            : "PLZ fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_city
                            ? protocolToShow?.object?.address?.object_city
                            : "Ort fehlt"}
                        </span>
                      </div>
                    </div>
                    {protocolToShow?.object?.object_date_of_renovation &&
                      <div className="col-md-6 ">
                        <span className="uppercase">Renovierungsdatum</span>
                        <div className="mb-2 text-style">
                          <span>
                            {protocolToShow?.object?.object_date_of_renovation
                              ? protocolToShow?.object?.object_date_of_renovation
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                  {protocolToShow?.object?.address.object_address_note &&
                    <div className="LigneAdresszustazOvwerview row w-full">
                      <div className="col-md-6">
                        <span className="uppercase">Adresszusatz/Lage oder Wohnungsnummer</span>

                        <div className="mb-2 text-style">
                          <span>
                            {protocolToShow?.object?.address.object_address_note
                              ? protocolToShow?.object?.address.object_address_note
                              : "Angabe fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_position
                              ? protocolToShow?.object?.address?.object_position
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <hr className="hrOvwerview" />
              </div>
            </div>
            <div className="card-shadow">
              <div className="aftercardOvwerview pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase mb-2">Eigentümer</span>
                  </div>
                  {protocolToShow?.owner_is_landlord == true ? (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                        <span style={{ fontWeight: "bold", color: "#000000" }}>
                          {protocolToShow?.landlord?.landlord_company_name
                            ? protocolToShow?.landlord?.landlord_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                        <span className="uppercase" style={{ fontSize: "16px" }}>Art des Wohnungsgebers</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }} className="mb-2">
                          <span >
                            {protocolToShow?.landlord?.landlord_salutation ==
                              null &&
                              protocolToShow?.landlord?.landlord_salutation == "" ||
                              protocolToShow?.landlord?.landlord_salutation == null
                              && "Anrede fehlt "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.landlord?.landlord_title ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_title == "dr" &&
                              "Dr. "}
                            {protocolToShow?.landlord?.landlord_title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.landlord?.landlord_title == "ing" &&
                              "Ing. "}
                            {protocolToShow?.landlord?.landlord_first_name ==
                              null &&
                              protocolToShow?.landlord?.landlord_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.landlord?.landlord_first_name ==
                              null ||
                              protocolToShow?.landlord?.landlord_first_name ==
                              "") &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null &&
                              " Vorname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              (protocolToShow?.landlord?.landlord_last_name ==
                                null ||
                                protocolToShow?.landlord?.landlord_last_name ==
                                "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.landlord?.landlord_first_name}{" "}
                                  {protocolToShow?.landlord?.landlord_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_street
                              ? protocolToShow?.landlord?.address?.landlord_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address
                              ?.landlord_house_number
                              ? protocolToShow?.landlord?.address
                                ?.landlord_house_number
                              : "Hausnummer fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_plz
                              ? protocolToShow?.landlord?.address?.landlord_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address?.landlord_city
                              ? protocolToShow?.landlord?.address?.landlord_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                        <div style={{ width: "50%" }} className="mb-2">
                          {(protocolToShow?.landlord?.landlord_type == null ||
                            protocolToShow?.landlord?.landlord_type == "") && (
                              <span>Angabe fehlt</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "noChoice" && (
                              <span style={{ color: "#000000" }}>Keine Angabe</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "company" && (
                              <span style={{ color: "#000000" }}>Gesellschaft</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "private" && (
                              <span style={{ color: "#000000" }}>Privat</span>
                            )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.landlord?.landlord_email &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                        }
                        {protocolToShow?.landlord?.landlord_phone &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.landlord?.landlord_email &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_email
                                ? protocolToShow?.landlord?.landlord_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                        {protocolToShow?.landlord?.landlord_phone &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_phone
                                ? protocolToShow?.landlord?.landlord_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold" }}>
                          {protocolToShow?.owner?.owner_company_name
                            ? protocolToShow?.owner?.owner_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                        <span className="uppercase" style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }}>
                          <span>
                            {protocolToShow?.owner?.owner_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.owner?.owner_salutation == "male" &&
                              "Herr "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "association" && "WEG "}
                            {protocolToShow?.owner?.owner_title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.owner?.owner_title == "dr" && "Dr. "}
                            {protocolToShow?.owner?.owner_title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.owner?.owner_title == "ing" &&
                              "Ing. "}
                            {protocolToShow?.owner?.owner_first_name == null &&
                              protocolToShow?.owner?.owner_last_name == null && (
                                <span>Name und Vorname fehlt</span>
                              )}
                            {(protocolToShow?.owner?.owner_first_name == null ||
                              protocolToShow?.owner?.owner_first_name == "") &&
                              protocolToShow?.owner?.owner_last_name != null &&
                              " Vorname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              (protocolToShow?.owner?.owner_last_name == null ||
                                protocolToShow?.owner?.owner_last_name == "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              protocolToShow?.owner?.owner_last_name != null && (
                                <>
                                  {protocolToShow?.owner?.owner_first_name}{" "}
                                  {protocolToShow?.owner?.owner_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_street
                              ? protocolToShow?.owner?.address?.owner_street
                              : "Straße fehlt"}
                            ,
                            {protocolToShow?.owner?.address?.owner_house_number
                              ? protocolToShow?.owner?.address?.owner_house_number
                              : "Hausnummer fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_plz
                              ? protocolToShow?.owner?.address?.owner_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.owner?.address?.owner_city
                              ? protocolToShow?.owner?.address?.owner_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                        <div style={{ width: "50%" }}>
                          {protocolToShow?.owner?.owner_Type == null && (
                            <span>Angabe fehlt</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "noChoice" && (
                            <span style={{ color: "#000000" }}>Keine Angabe</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "company" && (
                            <span style={{ color: "#000000" }}>Gesellschaft</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "private" && (
                            <span style={{ color: "#000000" }}>Privat</span>
                          )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.owner?.owner_email &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                        }
                        {protocolToShow?.owner?.owner_phone &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.owner?.owner_email &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_email
                                ? protocolToShow?.owner?.owner_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                        {protocolToShow?.owner?.owner_phone &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_phone
                                ? protocolToShow?.owner?.owner_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </>
                  )}
                </div>
                <hr className="hrOvwerview" />
              </div>
            </div>
            <div className="card-shadow">

              <div className="aftercardOvwerview pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase mb-2">Wohnungsabnehmer</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      {protocolToShow?.landlord?.landlord_company_name
                        ? protocolToShow?.landlord?.landlord_company_name
                        : "Unternehmensname fehlt"}
                    </span>
                    <span style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                  </div>
                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                    <div style={{ width: "50%" }} className="mb-2">
                      <span>
                        {protocolToShow?.landlord?.landlord_salutation == null &&
                          "Anrede fehlt "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "male" && "Herr "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "female" && "Frau "}
                        {protocolToShow?.landlord?.landlord_title == "noChoice" &&
                          "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_title == "dr" &&
                          "Dr. "}
                        {protocolToShow?.landlord?.landlord_title == "prof" &&
                          "Prof. "}
                        {protocolToShow?.landlord?.landlord_title == "ing" &&
                          "Ing. "}
                        {protocolToShow?.landlord?.landlord_first_name == null &&
                          protocolToShow?.landlord?.landlord_last_name ==
                          null && <span>Name und Vorname fehlt</span>}
                        {(protocolToShow?.landlord?.landlord_first_name == null ||
                          protocolToShow?.landlord?.landlord_first_name == "") &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name
                                ? protocolToShow?.landlord?.landlord_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          (protocolToShow?.landlord?.landlord_last_name == null ||
                            protocolToShow?.landlord?.landlord_last_name ==
                            "") && (
                            <>
                              {protocolToShow?.landlord?.landlord_last_name
                                ? protocolToShow?.landlord?.landlord_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name}{" "}
                              {protocolToShow?.landlord?.landlord_last_name}
                            </>
                          )}
                      </span>
                      <br />
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_street
                          ? protocolToShow?.landlord?.address?.landlord_street
                          : "Straße fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_house_number
                          ? protocolToShow?.landlord?.address
                            ?.landlord_house_number
                          : "Hausnummer fehlt"}
                      </span>
                      <br />
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_plz
                          ? protocolToShow?.landlord?.address?.landlord_plz
                          : "PLZ fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_city
                          ? protocolToShow?.landlord?.address?.landlord_city
                          : "Ort fehlt"}
                      </span>
                    </div>
                    <div style={{ width: "50%" }} className="mb-2">
                      {(protocolToShow?.landlord?.landlord_type == null ||
                        protocolToShow?.landlord?.landlord_type == "") && (
                          <span>Angabe fehlt</span>
                        )}
                      {protocolToShow?.landlord?.landlord_type == "noChoice" && (
                        <span style={{ color: "#000000" }}>Keine Angabe</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "company" && (
                        <span style={{ color: "#000000" }}>Gesellschaft</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "private" && (
                        <span style={{ color: "#000000" }}>Privat</span>
                      )}
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.landlord?.landlord_email &&
                      <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                    }
                    {protocolToShow?.landlord?.landlord_phone &&
                      <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                    }
                  </div>
                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.landlord?.landlord_email &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_email
                            ? protocolToShow?.landlord?.landlord_email
                            : "Angabe fehlt"}
                        </span>

                      </div>
                    }
                    {protocolToShow?.landlord?.landlord_phone &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_phone
                            ? protocolToShow?.landlord?.landlord_phone
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <hr className="hrOvwerview" />
              </div>


              <div className="aftercardOvwerview pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase mb-2">Mieter</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontWeight: "bold", color: "#000000" }} >
                      {protocolToShow?.renter?.renter_salutation == null &&
                        "Anrede fehlt "}
                      {protocolToShow?.renter?.renter_salutation == "noChoice" &&
                        "Keine Angabe "}
                      {protocolToShow?.renter?.renter_salutation == "male" &&
                        "Herr "}
                      {protocolToShow?.renter?.renter_salutation == "female" &&
                        "Frau "}
                      {protocolToShow?.renter?.renter_title == "noChoice" &&
                        "Keine Angabe "}
                      {protocolToShow?.renter?.renter_title == "dr" && "Dr. "}
                      {protocolToShow?.renter?.renter_title == "prof" && "Prof. "}
                      {protocolToShow?.renter?.renter_title == "ing" && "Ing. "}
                      {protocolToShow?.renter?.renter_first_name == null &&
                        protocolToShow?.renter?.renter_last_name == null && (
                          <span>Name und Vorname fehlt</span>
                        )}
                      {(protocolToShow?.renter?.renter_first_name == null ||
                        protocolToShow?.renter?.renter_first_name == "") &&
                        protocolToShow?.renter?.renter_last_name != null && (
                          <>
                            {protocolToShow?.renter?.renter_first_name
                              ? protocolToShow?.renter?.renter_first_name
                              : " Vorname fehlt "}
                          </>
                        )}
                      {protocolToShow?.renter?.renter_first_name != null &&
                        (protocolToShow?.renter?.renter_last_name == null ||
                          protocolToShow?.renter?.renter_last_name == "") && (
                          <>
                            {protocolToShow?.renter?.renter_last_name
                              ? protocolToShow?.renter?.renter_last_name
                              : " Nachname fehlt "}
                          </>
                        )}
                      {protocolToShow?.renter?.renter_first_name != null &&
                        protocolToShow?.renter?.renter_last_name != null && (
                          <>
                            {protocolToShow?.renter?.renter_first_name}{" "}
                            {protocolToShow?.renter?.renter_last_name}
                          </>
                        )}
                    </span>
                    {protocolToShow?.renter?.renter_date_of_birth &&
                      <span className="uppercase  ">Geburtsdatum</span>
                    }
                  </div>
                  <div class="LigneDataAdresse-RenviernungsdatumOvwerview">
                    <div style={{ width: "50%" }} className="mb-2"></div>
                    {protocolToShow?.renter?.renter_date_of_birth &&
                      <div style={{ width: "50%" }} className="mb-2">
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ color: "#000000" }}>
                            {protocolToShow?.renter?.renter_date_of_birth
                              ? protocolToShow?.renter?.renter_date_of_birth
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.renter?.renter_email &&
                      <span className="uppercase">E-mail:</span>
                    }
                    {protocolToShow?.renter?.renter_phone &&
                      <span className="uppercase">Telefonnummer:</span>
                    }
                  </div>
                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.renter?.renter_email &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.renter?.renter_email
                            ? protocolToShow?.renter?.renter_email
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    }
                    {protocolToShow?.renter?.renter_phone &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.renter?.renter_phone
                            ? protocolToShow?.renter?.renter_phone
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <hr className="hrOvwerview" />
              </div>

              {window.location.pathname.startsWith("/app/show_protocolcontrol") &&
                protocolToShow &&
                protocolToShow.note_taker && (
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase mb-2">Protokollant</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold", color: "#000000" }}>
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            null && "Anrede fehlt "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "male" && "Herr "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "female" && "Frau "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_title == "dr" &&
                            "Dr. "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "prof" && "Prof. "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "ing" && "Ing. "}
                          {protocolToShow?.note_taker?.note_taker_first_name ==
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name ==
                            null && <span>Name und Vorname fehlt</span>}
                          {(protocolToShow?.note_taker?.note_taker_first_name ==
                            null ||
                            protocolToShow?.note_taker?.note_taker_first_name ==
                            "") &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_first_name
                                  ? protocolToShow?.note_taker
                                    ?.note_taker_first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            (protocolToShow?.note_taker?.note_taker_last_name ==
                              null ||
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              "") && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_last_name
                                  ? protocolToShow?.note_taker
                                    ?.note_taker_last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {
                                  protocolToShow?.note_taker
                                    ?.note_taker_first_name
                                }{" "}
                                {protocolToShow?.note_taker?.note_taker_last_name}
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                )}
              {protocolToShow?.renter?.address?.renter_previous_new_adress && (
                <div className="aftercardOvwerview pt-0 pb-0">
                  <div className="formAbschlussOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span className="uppercase mb-2">Vorherige Adresse</span>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ color: "#000000" }}>
                        {protocolToShow?.renter?.address?.renter_srteet
                          ? protocolToShow?.renter?.address?.renter_srteet
                          : "Straße fehlt"}
                        ,{" "}
                        {protocolToShow?.renter?.address?.renter_house_number
                          ? protocolToShow?.renter?.address?.renter_house_number
                          : "Hausnummer fehlt"}
                      </span>
                      {protocolToShow?.renter?.address?.renter_address_note &&
                        <span className="uppercase">Adresszusatz</span>
                      }
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "50%" }} className="mb-2">
                        <span>
                          {protocolToShow?.renter?.address?.renter_plz
                            ? protocolToShow?.renter?.address?.renter_plz
                            : "PLZ fehlt"}
                          ,{" "}
                          {protocolToShow?.renter?.address?.renter_city
                            ? protocolToShow?.renter?.address?.renter_city
                            : "Ort fehlt"}
                        </span>
                      </div>
                      {protocolToShow?.renter?.address?.renter_address_note &&
                        <div style={{ width: "50%" }} className="mb-2">
                          <span style={{ fontWeight: "bold" }}>
                            {protocolToShow?.renter?.address?.renter_address_note
                              ? protocolToShow?.renter?.address?.renter_address_note
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>
              )}
              {protocolToShow?.has_corenter && (
                <>
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase mb-2">Weitere Mieter</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold", color: "#000000" }}>
                          {protocolToShow?.coRenter?.salutaion == null &&
                            "Anrede fehlt "}
                          {protocolToShow?.coRenter?.salutaion == "noChoice" &&
                            "Keine Angabe "}
                          {protocolToShow?.coRenter?.salutaion == "male" &&
                            "Herr "}
                          {protocolToShow?.coRenter?.salutaion == "female" &&
                            "Frau "}
                          {protocolToShow?.coRenter?.title == "noChoice" &&
                            "Keine Angabe "}
                          {protocolToShow?.coRenter?.title == "dr" && "Dr. "}
                          {protocolToShow?.coRenter?.title == "prof" && "Prof. "}
                          {protocolToShow?.coRenter?.title == "ing" && "Ing. "}
                          {(protocolToShow?.coRenter?.first_name == null ||
                            protocolToShow?.coRenter?.first_name == "") &&
                            protocolToShow?.coRenter?.last_name != null && (
                              <>
                                {protocolToShow?.coRenter?.first_name
                                  ? protocolToShow?.coRenter?.first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {protocolToShow?.coRenter?.first_name != null &&
                            (protocolToShow?.coRenter?.last_name == null ||
                              protocolToShow?.coRenter?.last_name == "") && (
                              <>
                                {protocolToShow?.coRenter?.last_name
                                  ? protocolToShow?.coRenter?.last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {protocolToShow?.coRenter?.first_name != null &&
                            protocolToShow?.coRenter?.last_name != null && (
                              <>
                                {protocolToShow?.coRenter?.first_name}{" "}
                                {protocolToShow?.coRenter?.last_name}
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                </>
              )}
              {protocolToShow?.roommates &&
                protocolToShow?.roommates.length > 0 && (
                  <>
                    {protocolToShow?.roommates.map((element, index) => {
                      return (
                        <div className="aftercardOvwerview pt-0 pb-0">
                          <div className="formAbschlussOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span className="uppercase">{index + 1}. Bewohner</span>
                            </div>
                            <div className="LigneAdresse-RenviernungsdatumOvwerview">
                              <span
                                style={{ fontWeight: "bold", color: "#000000" }}
                              >
                                {element.first_name} {element.last_name}
                              </span>
                            </div>
                          </div>
                          <hr className="hrOvwerview" />
                        </div>
                      );
                    })}
                  </>
                )}
              {protocolToShow?.protocol_type == "moveInAndExit" && (
                <>
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase mb-2">Vormieter</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold", color: "#000000" }} className=" mb-2">
                          {protocolToShow?.previous_renter
                            ?.previous_renter_salutation == null &&
                            "Anrede fehlt "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_salutation == "noChoice" &&
                            "Keine Angabe "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_salutation == "male" && "Herr "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_salutation == "female" && "Frau "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_title == "noChoice" &&
                            "Keine Angabe "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_title == "dr" && "Dr. "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_title == "prof" && "Prof. "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_title == "ing" && "Ing. "}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_first_name
                            ? protocolToShow?.previous_renter
                              ?.previous_renter_first_name + " "
                            : "Vorname fehlt"}
                          {protocolToShow?.previous_renter
                            ?.previous_renter_last_name
                            ? protocolToShow?.previous_renter
                              ?.previous_renter_last_name
                            : "Nachname fehlt"}
                        </span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.previous_renter
                          ?.previous_renter_email &&
                          <span className="uppercase">E-mail:</span>
                        }
                        {protocolToShow?.previous_renter
                          ?.previous_renter_phone_number &&
                          <span className="uppercase">Telefonnummer:</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.previous_renter
                          ?.previous_renter_email &&
                          <div style={{ width: "50%" }} className="mb-2">
                            <span>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_email
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                        {protocolToShow?.previous_renter
                          ?.previous_renter_phone_number &&
                          <div style={{ width: "50%" }} className="mb-2">
                            <span>
                              {" "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_phone_number
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_phone_number
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                  {protocolToShow?.previous_renter?.address
                    ?.previous_renter_new_adress && (
                      <div className="aftercardOvwerview pt-0 pb-0">
                        <div className="formAbschlussOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            {protocolToShow?.previous_renter?.address
                              ?.previous_renter_address_note &&
                              <span className="uppercase mb-2">Verzugs-Adresse</span>
                            }
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span style={{ color: "#000000" }}>
                              {protocolToShow?.previous_renter.address
                                .previous_renter_srteet
                                ? protocolToShow?.previous_renter.address
                                  ?.previous_renter_srteet
                                : "Straße fehlt"}
                              ,{" "}
                              {protocolToShow?.previous_renter?.address
                                ?.previous_renter_housnumber
                                ? protocolToShow?.previous_renter?.address
                                  ?.previous_renter_housnumber
                                : "Hausnummer fehlt"}
                            </span>
                            <span className="uppercase">Adresszusatz</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }} className="mb-2">
                              <span>
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_plz
                                  ? protocolToShow?.previous_renter?.address
                                    ?.previous_renter_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_city
                                  ? protocolToShow?.previous_renter?.address
                                    ?.previous_renter_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            {protocolToShow?.previous_renter?.address
                              ?.previous_renter_address_note &&
                              <div style={{ width: "50%" }} className="mb-2">
                                <span style={{ fontWeight: "bold" }}>
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_address_note
                                    ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_address_note
                                    : "Angabe fehlt"}
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="SonCard mt-3">
            <div className="neuer">
              <img className="NeuerIcon" src={RaumeIcon} alt="Räume Icon" />
              <h3 className="uppercase"> Räume und Zimmer </h3>
            </div>

            {(window.location.pathname.startsWith(
              "/app/update_protocolcontrol"
            ) ||
              window.location.pathname.startsWith(
                "/app/show_protocolcontrol"
              ) ||
              window.location.pathname.startsWith("/app/protocolcontrol")) &&
              protocolToShow?.protocol_rooms &&
              protocolToShow.protocol_rooms.length > 0 ? (
              <>
                {protocolToShow?.protocol_rooms.map((room, index) =>
                  protocolToShow?.protocol_rooms[index]?.room_status == "1" ? (
                    <>
                      <div
                        className="VerteilerContainer"
                        style={{ backgroundColor: "rgba(0, 159, 227,0.10)", paddingLeft: "20px" }}
                      >
                        <div className={
                          (protocolToShow.protocol_rooms[index].room_image &&
                            protocolToShow.protocol_rooms[index].room_image.length >
                            0) ||
                            protocolToShow.protocol_rooms[index].room_status == 1
                            ? "formAbschlussOvwerview"
                            : "formAbschlussOvwerview-withImages"
                        }
                          style={{ backgroundColor: "transparent" }}>
                          <div
                            className={
                              protocolToShow.protocol_rooms[index].room_image &&
                                protocolToShow.protocol_rooms[index].room_image.length > 3
                                ? "row w-full"
                                : "room-deffect-contain "
                            }
                          >
                            <div
                              className={
                                protocolToShow.protocol_rooms[index].room_image &&
                                  protocolToShow.protocol_rooms[index].room_image.length >
                                  3
                                  ? "col-md-6"
                                  : "first-part-formverteilerOvwerview row "
                              }
                            >
                              <div className="LigneWohnungsdatenOvwerview">
                                <span>
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "livingRoom" && (
                                      <>Wohnzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bedroom" && <>Schlafzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "kitchen" && <>Küche</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bathroom" && <>Badezimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "basement" && <>Keller</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "corridor" && <>Flur</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbedroom" && (
                                      <>Zweites Schlafzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbathroom" && (
                                      <>Zweites Badezimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "attic" && <>Dachboden</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garage" && <>Garage</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "balcony" && <>Balkon</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "shed" && <>Schuppen</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garden" && <>Garten</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "nursery" && <>Kinderzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "other" && (
                                      <>
                                        {
                                          protocolToShow?.protocol_rooms[index]
                                            ?.room_name
                                        }
                                      </>
                                    )}
                                </span>
                              </div>
                              <div className="LigneZustandPROTOKOLLTYPE">
                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == null && (
                                    <>
                                      <div className="LigneRaumeundZimmerAddIcon">
                                        <MinimizeOutlinedIcon
                                          sx={{
                                            fontSize: "2rem",
                                            paddingBottom: "12px",
                                            color: "rgb(141 133 133)",
                                          }}
                                        />

                                        <span
                                          style={{
                                            color: "rgb(141 133 133)",
                                            fontSize: "1.3rem",
                                          }}
                                        >
                                          Zustand: Angabe fehlt
                                        </span>
                                      </div>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 0 && (
                                    <>
                                      <CheckIcon
                                        className="icon-AngabeButton"
                                        sx={{ fontSize: 40, color: "#62AB12" }}
                                      />
                                      <span
                                        style={{
                                          color: "#62AB12",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: IN ORDNUNG
                                      </span>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 1 && (
                                    <>
                                      <CloseIcon
                                        className="icon"
                                        sx={{ fontSize: 25 }}
                                      />

                                      <span
                                        style={{
                                          color: "#D00000",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: Mangel vorhanden
                                      </span>
                                    </>
                                  )}
                              </div>
                              <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                <div style={{ width: "100%" }}>
                                  <span>
                                    {protocolToShow?.protocol_rooms[index]
                                      ?.room_generale_description != null
                                      ? protocolToShow.protocol_rooms[index]
                                        ?.room_generale_description
                                      : "KEIN KOMMENTAR"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                protocolToShow.protocol_rooms[index].room_image &&
                                  protocolToShow.protocol_rooms[index].room_image.length >
                                  2
                                  ? "col-md-6 mt-3 w-100"
                                  : "image-grid grid-counter "
                              }
                            >
                              {protocolToShow.protocol_rooms[index].room_image &&
                                protocolToShow.protocol_rooms[index].room_image.length >
                                0 ? (
                                protocolToShow.protocol_rooms[index].room_image.length >
                                  2 ? (
                                  // CurrentPageByRoom[index]*imagesPerPage ,
                                  // CurrentPageByRoom[index]*imagesPerPage+imagesPerPage
                                  <>
                                    {protocolToShow.protocol_rooms[index].room_image &&
                                      protocolToShow.protocol_rooms[index].room_image
                                        .length > 0
                                      ? protocolToShow.protocol_rooms[index].room_image
                                        .slice(CurrentPageByRoom[index] * imagesPerPage, CurrentPageByRoom[index] * imagesPerPage + imagesPerPage)
                                        ?.filter(
                                          (Rimage) => Rimage?.deffect_id == null
                                        )
                                        .reduce((chunks, item, i, arr) => {
                                          if (i % 3 === 0) {
                                            chunks.push(arr.slice(i, i + 3));
                                          }
                                          return chunks;
                                        }, [])
                                        .map((imageSet, setIndex) => (
                                          <div className="row">
                                            {imageSet.map((Rimage, rimg) => (
                                              <div
                                                className="col-md-4"
                                                style={{
                                                  //display: "flex",
                                                  //justifyContent: "center",
                                                  marginBottom: "30px",
                                                }}
                                              >
                                                <div
                                                  className="image--container image-right"
                                                  key={rimg}
                                                >
                                                  {showImage &&
                                                    Rimage?.id ==
                                                    idImageShow && (
                                                      <div
                                                        className="image-overlay"
                                                        onClick={() =>
                                                          toggleImage(
                                                            Rimage?.id
                                                          )
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            classes.imageContainer
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              Rimage?.room_image_remote_url
                                                            }
                                                            alt={
                                                              Rimage?.room_image_name
                                                            }
                                                            className="image"
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  {!showImage && !comment && (
                                                    <div
                                                      className="icon overview"
                                                      onClick={() =>
                                                        toggleImage(Rimage?.id)
                                                      }
                                                    >
                                                      <i className="fas fa-search-plus"></i>
                                                    </div>
                                                  )}
                                                  {(!comment ||
                                                    selectedId !=
                                                    Rimage.id) && (
                                                      <div
                                                        className="icon-comment overview"
                                                        onClick={() => {
                                                          setComment(!comment);
                                                          setSelectedId(
                                                            Rimage?.id
                                                          );
                                                        }}
                                                      >
                                                        <Comment
                                                          sx={{
                                                            fontSize: "1rem",
                                                            //color: "rgb(141 133 133)",
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  {comment &&
                                                    selectedId == Rimage.id && (
                                                      <div
                                                        className="icon-comment overview"
                                                        onClick={() => {
                                                          setComment(!comment);
                                                          setSelectedId(
                                                            Rimage?.id
                                                          );
                                                        }}
                                                      >
                                                        <HideComment
                                                          sx={{
                                                            fontSize: "1rem",
                                                            //color: "rgb(141 133 133)",
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  <img
                                                    src={
                                                      Rimage?.room_image_remote_url
                                                    }
                                                    alt={
                                                      Rimage?.room_image_name
                                                    }
                                                    className={`w-full ${comment &&
                                                      selectedId == Rimage.id
                                                      ? "d-none"
                                                      : ""
                                                      } `}
                                                    style={{
                                                      height: "200px",
                                                    }}
                                                  />
                                                  <div
                                                    className={`description-contain w-100 ${comment &&
                                                      selectedId == Rimage.id
                                                      ? ""
                                                      : "d-none"
                                                      }`}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 1)",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {
                                                        Rimage?.image_description
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        ))
                                      : null}

                                    {/* Pagination controls */}
                                    {protocolToShow.protocol_rooms[index].room_image && protocolToShow.protocol_rooms[index].room_image.length > 3 && (
                                      <div className="pagination pt-2 pb-2 justify-content-center" style={{ marginTop: "-30px" }}>
                                        {(CurrentPageByRoom[index] > 0) && (
                                          <div className="icon overview" onClick={() => {
                                            paginate(currentPage - 1, index)
                                          }}>
                                            <Previous sx={{ fontSize: "2rem", color: "#009fe3", cursor: "pointer" }} />
                                          </div>
                                        )}
                                        {CurrentPageByRoom[index] < pagesByRoom[index] - 1 && (
                                          <div className="icon overview" onClick={() => {
                                            paginate(currentPage + 1, index)
                                          }}>
                                            <Next sx={{ fontSize: "2rem", color: "#009fe3", cursor: "pointer" }} />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {protocolToShow.protocol_rooms[
                                      index
                                    ]?.room_image?.map(
                                      (Rimage, rimg) =>
                                        protocolToShow?.protocol_rooms[index]
                                          ?.room_image[rimg]?.deffect_id ==
                                        null && (
                                          <div
                                            className="image--container image-right"
                                            style={{ flexDirection: "column" }}
                                            key={rimg}
                                          >
                                            {showImage &&
                                              protocolToShow?.protocol_rooms[index]
                                                ?.room_image[rimg]?.id ==
                                              idImageShow && (
                                                <div
                                                  className="image-overlay"
                                                  onClick={() =>
                                                    toggleImage(
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]?.id
                                                    )
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      classes.imageContainer
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        protocolToShow?.protocol_rooms[
                                                          index
                                                        ]?.room_image[rimg]
                                                          ?.room_image_remote_url
                                                      }
                                                      alt={
                                                        protocolToShow?.protocol_rooms[
                                                          index
                                                        ]?.room_image[rimg]
                                                          ?.room_image_name
                                                      }
                                                      className="image"
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            {!showImage && (
                                              <div
                                                className="icon overview"
                                                onClick={() =>
                                                  toggleImage(
                                                    protocolToShow?.protocol_rooms[
                                                      index
                                                    ]?.room_image[rimg]?.id
                                                  )
                                                }
                                              >
                                                {/* Add your icon here */}
                                                <i className="fas fa-search-plus"></i>
                                              </div>
                                            )}
                                            <img
                                              src={
                                                protocolToShow?.protocol_rooms[index]
                                                  ?.room_image[rimg]
                                                  ?.room_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_rooms[index]
                                                  ?.room_image[rimg]
                                                  ?.room_image_name
                                              }
                                            />
                                            {protocolToShow?.protocol_rooms[index]
                                              ?.room_image[rimg]
                                              ?.image_description && (
                                                <div className="description-contain">
                                                  <span
                                                    style={{
                                                      //backgroundColor: "#fff",
                                                      color: "rgba(0, 0, 0, 1)",
                                                    }}
                                                  >
                                                    {
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]
                                                        ?.image_description
                                                    }
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        )
                                    )}
                                  </>
                                )
                              ) : (
                                <div className="second-part-formverteilerOvwerview">
                                  {/* <PhotoLibraryIcon
                                  className="defaultImageIcon"
                                  sx={{ width: 425, height: 200 }}
                                /> */}
                                </div>
                              )}
                            </div>
                          </div>

                          {protocolToShow.protocol_rooms[index].room_deffect &&
                            protocolToShow.protocol_rooms[index].room_deffect
                              .length > 0 ? (
                            <>
                              {protocolToShow.protocol_rooms[
                                index
                              ].room_deffect.map(
                                (deffect, i) =>
                                  protocolToShow.protocol_rooms[index]
                                    .room_deffect[i].room_deffect_who_fix !=
                                  "accepted" && (
                                    <>
                                      {protocolToShow.protocol_rooms[index]
                                        .room_status == 1 && (
                                          <div
                                            className="sub-formAbschlussOvwerview"
                                            style={{ backgroundColor: "#fff", width: "100%", marginLeft: "-10px", paddingBottom: "40px" }}
                                          >
                                            <div className="sub-formverteilerOvwerview">
                                              <div className="first-part-formverteilerOvwerview w-100 pt-4 pb-4">
                                                <div className="LigneZustandPROTOKOLLTYPE">
                                                  <img
                                                    className="notvalidateSclusselOvwerviewIcon"
                                                    src={NotValidIcon}
                                                    alt="notvalidateSclusselOvwerview Icon"
                                                  />
                                                  <h2
                                                    style={{
                                                      fontSize: "1.3rem",
                                                      marginBottom: "0px",
                                                    }}
                                                  >
                                                    Mangel {i + 1}
                                                  </h2>
                                                </div>
                                                <div className="row w-100">
                                                  <div className="col-md-6">
                                                    <div className="row" hidden={protocolToShow.protocol_rooms[
                                                      index
                                                    ].room_deffect[i]
                                                      .deffect_type == null || protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_type == ""}>
                                                      <div className="col-md-2">
                                                        <MeetingRoomIcon
                                                          className="BeschreibungIcon"
                                                          sx={{
                                                            fontSize: 33,
                                                            color: "#009fe3",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-md-10 mangel-deffect">
                                                        <span className="deffect-Label">
                                                          Objekt
                                                        </span>
                                                        <span className="deffect-value">
                                                          {protocolToShow.protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .deffect_type != null
                                                            ? protocolToShow.protocol_rooms[
                                                              index
                                                            ].room_deffect[i]
                                                              .deffect_type
                                                            : "keine angabe"}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="row" hidden={protocolToShow.protocol_rooms[
                                                      index
                                                    ].room_deffect[i]
                                                      .deffect_name == null || protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_name == ""}>
                                                      <div className="col-md-1 ">
                                                        <Direction
                                                          className="BeschreibungIcon pl-2"
                                                          sx={{
                                                            fontSize: 33,
                                                            color: "rgba(0,0,0,0.3)",
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="col-md-2 d-flex align-items-center">
                                                        <img
                                                          className="PenIcon"
                                                          src={damage}
                                                          alt="Baskets Icon"
                                                        />
                                                      </div>
                                                      <div className="col-md-9 mangel-deffect">
                                                        <span className="deffect-Label">
                                                          Art des Mangels
                                                        </span>
                                                        <span className="deffect-value">
                                                          {protocolToShow.protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .deffect_name != null
                                                            ? protocolToShow.protocol_rooms[
                                                              index
                                                            ].room_deffect[i]
                                                              .deffect_name
                                                            : "keine angabe"}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-md-6 pt-4 pb-2"
                                                    style={{
                                                      backgroundColor: "#f3f3f3",
                                                    }}
                                                    hidden={protocolToShow.protocol_rooms[
                                                      index
                                                    ].room_deffect[i]
                                                      .room_deffect_who_fix ==
                                                      null || protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .room_deffect_who_fix ==
                                                      ""}
                                                  >
                                                    <div className="row" >
                                                      <div className="col-md-6 d-flex">
                                                        <Config
                                                          className="BeschreibungIcon mr-3"
                                                          sx={{
                                                            fontSize: 33,
                                                            color: "#009fe3",
                                                          }}
                                                        />
                                                        <div className="mangel-deffect">
                                                          <span className="deffect-Label">
                                                            Maßnahme
                                                          </span>
                                                          <span className="deffect-value">
                                                            {protocolToShow.protocol_rooms[
                                                              index
                                                            ].room_deffect[i]
                                                              .room_deffect_who_fix !=
                                                              null
                                                              ? protocolToShow
                                                                .protocol_rooms[
                                                                index
                                                              ].room_deffect[i]
                                                                .room_deffect_who_fix
                                                              : "keine angabe"}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-6 d-flex">
                                                        <DateIcon
                                                          className="BeschreibungIcon mr-3"
                                                          sx={{
                                                            fontSize: 33,
                                                            color: "#009fe3",
                                                          }}
                                                        />
                                                        <div className="mangel-deffect">
                                                          <span className="deffect-Label">
                                                            Zu erledigen bis
                                                          </span>
                                                          <span className="deffect-value">
                                                            {protocolToShow.protocol_rooms[
                                                              index
                                                            ].room_deffect[i]
                                                              .room_deffect_date_of_fix
                                                              ? protocolToShow.protocol_rooms[
                                                                index
                                                              ].room_deffect[i]
                                                                .room_deffect_date_of_fix
                                                              : "Angabe fehlt"}

                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                                  <span className="titles">
                                                    Bemerkung
                                                  </span>
                                                </div>
                                                <div
                                                  className="LigneDataAdresse-RenviernungsdatumOvwerview"
                                                  style={{ marginBottom: "20px" }}
                                                >
                                                  <div style={{ width: "50%" }}>
                                                    {window.location.pathname.startsWith(
                                                      "/app/show_protocolcontrol"
                                                    ) ? (
                                                      <div className="col-md-15">
                                                        <span>
                                                          {protocolToShow
                                                            .protocol_rooms[index]
                                                            .room_deffect[i]
                                                            .controle_comment !=
                                                            null
                                                            ? protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ].room_deffect[i]
                                                              .controle_comment
                                                            : "KEIN KOMMENTAR"}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div className="col-md-15">
                                                        <TextField
                                                          onClick={() =>
                                                            handleClickTextfield(
                                                              "controle_comment",
                                                              protocolToShow
                                                                ?.protocol_rooms[
                                                                index
                                                              ]?.room_deffect[i]
                                                                ?.controle_comment,
                                                              0,
                                                              index,
                                                              "BEMERKUNG",
                                                              i
                                                            )
                                                          }
                                                          InputLabelProps={{
                                                            style: {
                                                              textTransform:
                                                                "uppercase",
                                                              padding:
                                                                "0 5px 0 0",
                                                              backgroundColor:
                                                                "white",
                                                            },
                                                          }}
                                                          name="controle_comment"
                                                          value={
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_deffect[i]
                                                              ?.controle_comment
                                                          }
                                                          InputProps={{
                                                            readOnly: true
                                                          }}
                                                          id="controle_comment"
                                                          type="text"
                                                          placeholder={
                                                            "BEMERKUNG"
                                                          }
                                                          variant="outlined"
                                                          rows="1"
                                                          multiline={true}
                                                          fullWidth
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className={
                                                  protocolToShow.protocol_rooms[
                                                    index
                                                  ]?.room_image?.filter(
                                                    (Rimage) =>
                                                      Rimage?.deffect_id !== null &&
                                                      Rimage?.deffect_id ==
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_deffect[i]?.id &&
                                                      Rimage?.room_image_remote_url !=
                                                      null
                                                  ).length > 3
                                                    ? " w-100 d-flex justify-content-center"
                                                    : "image-grid grid-counter w-100 pr-3"
                                                }
                                              >
                                                {protocolToShow.protocol_rooms[
                                                  index
                                                ].room_image?.find(
                                                  (roomImage) =>
                                                    roomImage.deffect_id ===
                                                    protocolToShow.protocol_rooms[index]
                                                      .room_deffect[i].id
                                                ) ? (
                                                  protocolToShow.protocol_rooms[
                                                    index
                                                  ]?.room_image?.filter(
                                                    (Rimage) =>
                                                      Rimage?.deffect_id !== null &&
                                                      Rimage?.deffect_id ==
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_deffect[i]?.id &&
                                                      Rimage?.room_image_remote_url !=
                                                      null
                                                  ).length > 3 ? (
                                                    <>
                                                      <Carousel
                                                        activeIndex={
                                                          activeIndexMangel
                                                        }
                                                        next={() =>
                                                          next(index, "mangel")
                                                        }
                                                        previous={() =>
                                                          previous(index, "mangel")
                                                        }
                                                        slide={false}
                                                        ride="carousel"
                                                        interval={false}
                                                        onMouseDown={() => {
                                                          setClickedOnBullet(false); // Update clickedOnBullet state
                                                        }}
                                                        onTouchStart={() => {
                                                          setClickedOnBullet(false); // Update clickedOnBullet state
                                                        }}
                                                        onAnimationStart={
                                                          handleExiting
                                                        }
                                                      >
                                                        {protocolToShow.protocol_rooms[
                                                          index
                                                        ]?.room_image &&
                                                          protocolToShow.protocol_rooms[index]
                                                            ?.room_image?.length > 0
                                                          ? protocolToShow.protocol_rooms[
                                                            index
                                                          ]?.room_image
                                                            ?.filter(
                                                              (Rimage) =>
                                                                Rimage?.deffect_id !==
                                                                null &&
                                                                Rimage?.deffect_id ==
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_deffect[i]
                                                                  ?.id &&
                                                                Rimage?.room_image_remote_url !=
                                                                null
                                                            )
                                                            .reduce(
                                                              (
                                                                chunks,
                                                                item,
                                                                i,
                                                                arr
                                                              ) => {
                                                                if (i % 3 === 0) {
                                                                  chunks.push(
                                                                    arr.slice(
                                                                      i,
                                                                      i + 3
                                                                    )
                                                                  );
                                                                }
                                                                return chunks;
                                                              },
                                                              []
                                                            )
                                                            .map(
                                                              (
                                                                imageSet,
                                                                setIndex
                                                              ) =>
                                                                imageSet.length >
                                                                0 && (
                                                                  <CarouselItem
                                                                    key={setIndex}
                                                                  >
                                                                    <div className="row">
                                                                      {imageSet.map(
                                                                        (
                                                                          Rimage,
                                                                          rimg
                                                                        ) => (
                                                                          <div
                                                                            className="col-md-4 "
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                            key={
                                                                              rimg
                                                                            }
                                                                          >
                                                                            <div className="image--container image-right">
                                                                              {showImage &&
                                                                                Rimage?.id ==
                                                                                idImageShow && (
                                                                                  <div
                                                                                    className="image-overlay"
                                                                                    onClick={() =>
                                                                                      toggleImage(
                                                                                        protocolToShow
                                                                                          ?.protocol_rooms[
                                                                                          index
                                                                                        ]
                                                                                          ?.room_image[
                                                                                          rimg
                                                                                        ]
                                                                                          ?.id
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      className={
                                                                                        classes.imageContainer
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          protocolToShow
                                                                                            ?.protocol_rooms[
                                                                                            index
                                                                                          ]
                                                                                            ?.room_image[
                                                                                            rimg
                                                                                          ]
                                                                                            ?.room_image_remote_url
                                                                                        }
                                                                                        alt={
                                                                                          protocolToShow
                                                                                            ?.protocol_rooms[
                                                                                            index
                                                                                          ]
                                                                                            ?.room_image[
                                                                                            rimg
                                                                                          ]
                                                                                            ?.room_image_name
                                                                                        }
                                                                                        className="image"
                                                                                      />
                                                                                    </div>
                                                                                  </div>
                                                                                )}
                                                                              {!showImage && (
                                                                                <div
                                                                                  className="icon overview"
                                                                                  onClick={() =>
                                                                                    toggleImage(
                                                                                      Rimage?.id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {/* Add your icon here */}
                                                                                  <i className="fas fa-search-plus"></i>
                                                                                </div>
                                                                              )}
                                                                              <div className="defect-contain">
                                                                                <img
                                                                                  src={
                                                                                    Rimage?.room_image_remote_url
                                                                                  }
                                                                                  alt={
                                                                                    Rimage?.room_image_name
                                                                                  }
                                                                                  className="w-full"
                                                                                  style={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    objectFit:
                                                                                      "cover",
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                              {protocolToShow
                                                                                ?.protocol_rooms[
                                                                                index
                                                                              ]
                                                                                ?.room_image[
                                                                                rimg
                                                                              ]
                                                                                ?.image_description && (
                                                                                  <div className="description-contain deffect">
                                                                                    <span
                                                                                      style={{
                                                                                        backgroundColor:
                                                                                          "#fff",
                                                                                        color:
                                                                                          "rgba(0, 0, 0, 1)",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        protocolToShow
                                                                                          ?.protocol_rooms[
                                                                                          index
                                                                                        ]
                                                                                          ?.room_image[
                                                                                          rimg
                                                                                        ]
                                                                                          ?.image_description
                                                                                      }
                                                                                    </span>
                                                                                  </div>
                                                                                )}
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </CarouselItem>
                                                                )
                                                            )
                                                          : null}
                                                        <CarouselIndicators
                                                          style={{ bottom: "-30px" }}
                                                          items={protocolToShow.protocol_rooms[
                                                            index
                                                          ]?.room_image
                                                            ?.filter(
                                                              (Rimage) =>
                                                                Rimage?.deffect_id !==
                                                                null &&
                                                                Rimage?.deffect_id ==
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_deffect[i]
                                                                  ?.id &&
                                                                Rimage?.room_image_remote_url !=
                                                                null
                                                            )
                                                            .reduce(
                                                              (
                                                                chunks,
                                                                item,
                                                                i,
                                                                arr
                                                              ) => {
                                                                if (i % 3 === 0) {
                                                                  chunks.push(
                                                                    arr.slice(
                                                                      i,
                                                                      i + 3
                                                                    )
                                                                  );
                                                                }
                                                                return chunks;
                                                              },
                                                              []
                                                            )}
                                                          activeIndex={
                                                            activeIndexMangel
                                                          }
                                                          onClickHandler={
                                                            goToIndexMangel
                                                          }
                                                        />
                                                      </Carousel>
                                                    </>
                                                  ) : (
                                                    protocolToShow.protocol_rooms[
                                                      index
                                                    ]?.room_image?.map(
                                                      (Rimage, rimg) => {
                                                        if (
                                                          protocolToShow?.protocol_rooms[
                                                            index
                                                          ]?.room_image[rimg]
                                                            ?.deffect_id != null &&
                                                          protocolToShow?.protocol_rooms[
                                                            index
                                                          ]?.room_image[rimg]
                                                            ?.deffect_id ==
                                                          protocolToShow?.protocol_rooms[
                                                            index
                                                          ]?.room_deffect[i]?.id &&
                                                          protocolToShow?.protocol_rooms[
                                                            index
                                                          ]?.room_image[rimg]
                                                            ?.room_image_remote_url !=
                                                          null
                                                        ) {
                                                          return (
                                                            <div
                                                              className="image--container image-right room-deffect-contain"
                                                              style={{
                                                                flexDirection:
                                                                  "column",
                                                              }}
                                                              key={rimg}
                                                            >
                                                              {showImage &&
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_image[rimg]
                                                                  ?.id ==
                                                                idImageShow && (
                                                                  <div
                                                                    className="image-overlay"
                                                                    onClick={() =>
                                                                      toggleImage(
                                                                        protocolToShow
                                                                          ?.protocol_rooms[
                                                                          index
                                                                        ]?.room_image[
                                                                          rimg
                                                                        ]?.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        protocolToShow
                                                                          ?.protocol_rooms[
                                                                          index
                                                                        ]?.room_image[
                                                                          rimg
                                                                        ]
                                                                          ?.room_image_remote_url
                                                                      }
                                                                      alt={
                                                                        protocolToShow
                                                                          ?.protocol_rooms[
                                                                          index
                                                                        ]?.room_image[
                                                                          rimg
                                                                        ]
                                                                          ?.room_image_name
                                                                      }
                                                                      className="image"
                                                                    />
                                                                  </div>
                                                                )}
                                                              {!showImage && (
                                                                <div
                                                                  className="icon overview"
                                                                  onClick={() =>
                                                                    toggleImage(
                                                                      protocolToShow
                                                                        ?.protocol_rooms[
                                                                        index
                                                                      ]?.room_image[
                                                                        rimg
                                                                      ]?.id
                                                                    )
                                                                  }
                                                                >
                                                                  <i className="fas fa-search-plus"></i>
                                                                </div>
                                                              )}
                                                              <div className="defect-contain">
                                                                <img
                                                                  src={
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[rimg]
                                                                      ?.room_image_remote_url
                                                                  }
                                                                  alt={
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[rimg]
                                                                      ?.room_image_name
                                                                  }
                                                                  style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit:
                                                                      "cover",
                                                                  }}
                                                                />
                                                              </div>
                                                              {protocolToShow
                                                                ?.protocol_rooms[
                                                                index
                                                              ]?.room_image[rimg]
                                                                ?.image_description && (
                                                                  <div className="description-contain deffect">
                                                                    <span
                                                                      style={{
                                                                        backgroundColor:
                                                                          "#fff",
                                                                        color:
                                                                          "rgba(0, 0, 0, 1)",
                                                                      }}
                                                                    >
                                                                      {
                                                                        protocolToShow
                                                                          ?.protocol_rooms[
                                                                          index
                                                                        ]?.room_image[
                                                                          rimg
                                                                        ]
                                                                          ?.image_description
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  )
                                                ) : (
                                                  <div className="second-part-formverteilerOvwerview">
                                                    <PhotoLibraryIcon
                                                      className="defaultImageIcon"
                                                      sx={{ width: 425, height: 220 }}
                                                    />
                                                  </div>
                                                )}
                                              </div>

                                              <div
                                                className="row mangelButtonsProtocolControl col-md-12"
                                              // style={{marginLeft:'0.5rem'}}
                                              >
                                                {window.location.pathname.startsWith(
                                                  "/app/show_protocolcontrol"
                                                ) ? (
                                                  <div
                                                    className={
                                                      protocolToShow
                                                        .protocol_rooms[index]
                                                        .room_deffect[i]
                                                        .controle_corrected ==
                                                        null
                                                        ? "div-emotion-button mb-3 mt-3"
                                                        : protocolToShow
                                                          .protocol_rooms[index]
                                                          .room_deffect[i]
                                                          .controle_corrected ==
                                                          true
                                                          ? "div-emotion-button OK mb-3 mt-3"
                                                          : "div-emotion-button BAD mb-3 mt-3"
                                                    }
                                                  >
                                                    <button
                                                      className="ThumbUp-buttonProtokolControl"
                                                      type="button"
                                                    >
                                                      <ThumbUpAltIcon
                                                        className="OK-emoji"
                                                        sx={{ fontSize: 33 }}
                                                      />
                                                      OK
                                                    </button>
                                                    <button
                                                      className="ThumbDown-buttonProtokolControl"
                                                      type="button"
                                                    >
                                                      <ThumbDownAltIcon

                                                        sx={{ fontSize: 33 }}
                                                      />
                                                      Mangel
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className={
                                                      protocolToShow
                                                        .protocol_rooms[index]
                                                        .room_deffect[i]
                                                        .controle_corrected ==
                                                        null
                                                        ? "div-emotion-button mb-3 mt-3"
                                                        : protocolToShow
                                                          .protocol_rooms[index]
                                                          .room_deffect[i]
                                                          .controle_corrected ==
                                                          true
                                                          ? "div-emotion-button OK mb-3 mt-3"
                                                          : "div-emotion-button BAD mb-3 mt-3"
                                                    }
                                                  >
                                                    <button
                                                      className="ThumbUp-buttonProtokolControl"
                                                      type="button"
                                                      onClick={(e) =>
                                                        handleOk(e, index, i)
                                                      }
                                                    >
                                                      <ThumbUpAltIcon
                                                        className="OK-emoji"
                                                        sx={{ fontSize: 33 }}
                                                      />
                                                      OK
                                                    </button>
                                                    <button
                                                      className="ThumbDown-buttonProtokolControl"
                                                      type="button"
                                                      onClick={(e) =>
                                                        handleMangel(e, index, i)
                                                      }
                                                    >
                                                      <ThumbDownAltIcon

                                                        sx={{ fontSize: 33 }}
                                                      />
                                                      Mangel
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}{" "}
                                    </>
                                  )
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          {window.location.pathname.startsWith("/app/protocolcontrol") ||
            window.location.pathname.startsWith("/app/update_protocolcontrol") ? (
            <>
              <div className="SonCard mt-3">
                <div className="neuer">
                  <img
                    className="NeuerIcon"
                    src={keyIcon}
                    alt="Schlüssel Icon"
                  />
                  <h3 style={{ textTransform: "uppercase" }}> Schlüssel </h3>
                </div>
                {protocolToShow?.protocol_keys &&
                  protocolToShow?.protocol_keys.length > 0 ? (
                  <>
                    {protocolToShow?.protocol_keys.map((key, s) =>
                      parseInt(protocolToShow?.protocol_keys[s]?.key_number) <=
                        protocolToShow?.protocol_keys[s]
                          ?.number_keys_returned_in_main_protocol ? (
                        <></>
                      ) : (
                        protocolToShow?.protocol_keys[s]?.key_number != 0 &&
                        protocolToShow?.protocol_keys[s]?.key_number != "" && (
                          <>
                            <div className="card-shadow" >

                              <div className="after-cards">
                                <div className="formAbschlussOvwerview">
                                  <div style={{ width: "100%" }}>
                                    <div className="row mr-0 ml-0">
                                      <div className="col-md-4"></div>
                                      <div
                                        className="col-md-3"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <p className="mr-0"
                                          style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                          }}
                                        >
                                          Übergabe
                                        </p>
                                      </div>
                                      <div className=" col-md-3">

                                        <p >
                                          Information
                                        </p>
                                      </div>
                                      <div
                                        className="col-md-2 "
                                        style={{

                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <p className="mr-0">Image</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="LigneSclusselDataOvwerview">
                                    <div className="blocks-SclusselOvwerview" style={{ alignItems: 'center' }}>
                                      <div className=" col-md-4">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            alignItems: "baseline",
                                          }}
                                        >
                                          {parseInt(
                                            protocolToShow?.protocol_keys[s]
                                              ?.key_number
                                          ) >
                                            protocolToShow?.protocol_keys[s]
                                              ?.number_keys_returned_in_main_protocol ? (
                                            <img
                                              className="notvalidateSclusselOvwerviewIcon"
                                              src={NotValidIcon}
                                              alt="notvalidateSclusselOvwerview Icon"
                                              style={{
                                                paddingTop: "0",
                                                height: "12.5px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="validateSclusselOvwerviewIcon"
                                              src={ValidIcon}
                                              alt="validateSclusselOvwerview Icon"
                                              style={{
                                                paddingTop: "0",
                                                height: "12.5px",
                                              }}
                                            />
                                          )}
                                          <p
                                            style={{
                                              marginTop: "0px",
                                              marginBottom: "3px",
                                              fontSize: "1.15rem",
                                            }}
                                          >
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_number &&
                                              protocolToShow?.protocol_keys[s]
                                                ?.key_number.length > 0 ? (
                                              <span>
                                                {
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.key_number
                                                }{" "}
                                                x{" "}
                                              </span>
                                            ) : (
                                              <span>0 x </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "mainEntrance" && (
                                                <span> Eingangstür, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "apartmentKey" && (
                                                <span> Wohnungsschlüssel, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "basement" && (
                                                <span> Keller, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "attic" && (
                                                <span> Dachboden, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "garage" && (
                                                <span> Garage, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "shed" && (
                                                <span> Schuppen, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "outerGate" && (
                                                <span> Aussentor, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "goal" && (
                                                <span> Tor, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "gazebo" && (
                                                <span> Gartenlaube, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "letterBox" && (
                                                <span> Briefkasten, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "frontDoor" && (
                                                <span> Haustür, </span>
                                              )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "other" && (
                                                <span>
                                                  {
                                                    protocolToShow?.protocol_keys[s]
                                                      ?.key_name
                                                  }{" "}
                                                  ,{" "}
                                                </span>
                                              )}
                                            <span
                                              style={
                                                parseInt(
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.key_number
                                                ) >
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.number_keys_returned_in_main_protocol
                                                  ? { color: "#D00000" }
                                                  : { color: "#62AB12" }
                                              }
                                            >
                                              erhalten :{" "}
                                              {protocolToShow?.protocol_keys[s]
                                                ?.number_keys_returned_in_main_protocol
                                                ? protocolToShow?.protocol_keys[s]
                                                  ?.number_keys_returned_in_main_protocol
                                                : 0}{" "}
                                            </span>
                                          </p>
                                        </div>
                                      </div>

                                      <div
                                        className="col-md-3"
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <img
                                          className="CalenderSclusselOvwerviewIcon"
                                          src={CalenderIcon}
                                          alt="validateSclusselOvwerview Icon"
                                          style={{ height: "14.75px" }}
                                        />
                                        <p
                                          style={{
                                            marginTop: "0",
                                            marginBottom: "0",
                                            fontSize: "1.2rem",
                                          }}
                                        >
                                          <span></span>
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_date_of_forwarding
                                            ? protocolToShow.protocol_keys[s]
                                              ?.key_date_of_forwarding
                                            : "K.A"}
                                        </p>
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={{ alignItems: "center" }}
                                      >

                                        <span
                                          style={{
                                            marginTop: "0",
                                            marginBottom: "0",
                                            fontSize: "1.2rem",
                                          }}
                                        >
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_notice
                                            ? protocolToShow?.protocol_keys[s]
                                              ?.key_notice
                                            : "Kein Kommentar"}
                                        </span>
                                      </div>
                                      <div
                                        className="col-md-2"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {protocolToShow?.protocol_keys[s]
                                          .image &&
                                          protocolToShow?.protocol_keys[s]?.image
                                            ?.key_image_remote_url != null ? (
                                          <div className="image--container ">
                                            {showImage &&
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.id == idImageShow && (
                                                <div
                                                  className="image-overlay"
                                                  onClick={() =>
                                                    toggleImage(
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image?.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image
                                                        ?.key_image_remote_url
                                                    }
                                                    alt={
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image?.key_image_name
                                                    }
                                                    className="image"
                                                  />
                                                </div>
                                              )}
                                            {!showImage && (
                                              <div
                                                className="icon overview"
                                                onClick={() =>
                                                  toggleImage(
                                                    protocolToShow
                                                      ?.protocol_keys[s]?.image
                                                      ?.id
                                                  )
                                                }
                                              >
                                                <i className="bx bx-zoom-in"></i>
                                              </div>
                                            )}
                                            <img
                                              src={
                                                protocolToShow?.protocol_keys[s]
                                                  ?.image?.key_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_keys[s]
                                                  ?.image?.key_image_name
                                              }
                                              style={{
                                                objectFit: "cover",
                                                width: "7.8rem",
                                                height: "9.4rem",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="image-container">
                                            <PhotoLibraryIcon
                                              className="defaultImageIcon"
                                              sx={{
                                                width: "7.8rem",
                                                height: "9.4rem",
                                                marginLeft: "-4rem",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row mr-0 ml-0 w-full"
                                    style={{ marginBottom: "0.25rem" }}
                                  >
                                    <div className=" col-md-4">
                                      {window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) && (
                                          <p style={{ textTransform: "uppercase" }}>
                                            <span
                                              style={{
                                                color: "#888888",
                                                marginRight: "0.5rem",
                                              }}
                                            >
                                              Kommentar:
                                            </span>
                                            <span>
                                              {protocolToShow?.protocol_keys[s]
                                                ?.controle_comment ||
                                                " Keine Kommentar"}
                                            </span>
                                          </p>
                                        )}
                                      {(window.location.pathname.startsWith(
                                        "/app/protocolcontrol"
                                      ) ||
                                        window.location.pathname.startsWith(
                                          "/app/update_protocolcontrol"
                                        )) && (
                                          <TextField
                                            InputLabelProps={{
                                              style: {
                                                textTransform: "uppercase",
                                                padding: "0 5px 0 0",
                                                backgroundColor: "white",
                                              },
                                            }}
                                            name="controle_comment"
                                            value={
                                              protocolToShow?.protocol_keys[s]
                                                ?.controle_comment
                                            }
                                            onChange={(e) =>
                                              handleChangeKey(
                                                e.target.name,
                                                e.target.value,
                                                s
                                              )
                                            }
                                            id="controle_comment"
                                            type="text"
                                            placeholder={"KOMMENTAR"}
                                            variant="outlined"
                                            rows="1"
                                            multiline={true}
                                            fullWidth
                                          />
                                        )}
                                    </div>
                                    {protocolToShow?.protocol_keys[s]
                                      ?.number_keys_returned_in_main_protocol !=
                                      null &&
                                      protocolToShow?.protocol_keys[s]
                                        ?.number_keys_returned_in_main_protocol !=
                                      "" ? (
                                      window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) ? (
                                        <div className="col-md-8">
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AddCircleIcon
                                              sx={{
                                                fontSize: 50,
                                                color: "#c9c5c5",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "21px",
                                                fontWeight: "bold",
                                                color: "#000000",
                                                marginRight: "0.5rem",
                                                minWidth: "1.8rem",
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                protocolToShow.protocol_keys[s]
                                                  .number_keys_returned
                                              }
                                            </span>
                                            <RemoveCircleIcon
                                              sx={{
                                                fontSize: 50,
                                                color: "#c9c5c5",
                                                marginRight: "0.5rem",
                                                marginLeft: "0.5rem",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className=" col-md-8">
                                          <AddCircleIcon
                                            sx={
                                              parseInt(
                                                protocolToShow?.protocol_keys[s]
                                                  ?.key_number
                                              ) ==
                                                parseInt(
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.number_keys_returned_in_main_protocol
                                                ) +
                                                protocolToShow.protocol_keys[s]
                                                  .number_keys_returned
                                                ? {
                                                  fontSize: 50,
                                                  color: "#c9c5c5",
                                                  marginRight: "0.8rem",
                                                }
                                                : {
                                                  fontSize: 50,
                                                  color: "rgb(98, 171, 18)",
                                                  marginRight: "0.8rem",
                                                  marginLeft: "0.5rem",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteuraddKey(e, s);
                                            }}
                                          />
                                          <span
                                            style={
                                              counters[s] >= 10
                                                ? {
                                                  fontSize: "21px",
                                                  fontWeight: "bold",
                                                  color: "#000000",
                                                  marginRight: "0.5rem",
                                                  marginLeft: "0.5rem",
                                                  minWidth: "1.8rem",
                                                  textAlign: "center",
                                                }
                                                : {
                                                  fontSize: "21px",
                                                  fontWeight: "bold",
                                                  color: "#000000",
                                                  marginRight: "1.15rem",
                                                  marginLeft: "0.5rem",
                                                  minWidth: "1.8rem",
                                                  textAlign: "center",
                                                }
                                            }
                                          >
                                            {
                                              protocolToShow.protocol_keys[s]
                                                .number_keys_returned
                                            }
                                          </span>
                                          <RemoveCircleIcon
                                            sx={
                                              protocol.protocol_keys[s]
                                                .number_keys_returned == 0
                                                ? {
                                                  fontSize: 50,
                                                  color: "#c9c5c5",
                                                  marginLeft: "0.5rem",
                                                }
                                                : {
                                                  fontSize: 50,
                                                  color: "#D00000",
                                                  marginLeft: "0.5rem",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteurRemoveKey(e, s);
                                            }}
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <div className=" col-md-8">
                                        <AddCircleIcon
                                          sx={{
                                            fontSize: 50,
                                            color: "#c9c5c5",
                                            marginRight: "0.5rem",
                                          }}
                                          onClick={(e) => {
                                            CompteuraddKey(e, s);
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                            color: "#000000",
                                            marginRight: "0.5rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {"0"}
                                        </span>
                                        <RemoveCircleIcon
                                          sx={{
                                            fontSize: 50,
                                            color: "#c9c5c5",
                                            marginLeft: "0.5rem",
                                          }}
                                          onClick={(e) => {
                                            CompteurRemoveKey(e, s);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr className="hrOvwerview" />
                              </div>
                            </div>
                          </>
                        )
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : null}

          {window.location.pathname.startsWith("/app/show_protocolcontrol") ? (
            <>
              <div className="SonCard mt-3">
                <div className="neuer">
                  <img
                    className="NeuerIcon"
                    src={keyIcon}
                    alt="Schlüssel Icon"
                  />
                  <h3 style={{ textTransform: "none" }}> Schlüssel </h3>
                </div>
                {protocolToShow?.protocol_keys &&
                  protocolToShow?.protocol_keys.length > 0 ? (
                  <>
                    {protocolToShow?.protocol_keys.map(
                      (key, s) =>
                        parseInt(protocolToShow?.protocol_keys[s]?.key_number) >
                        protocolToShow?.protocol_keys[s]
                          ?.number_keys_returned_in_main_protocol && (
                          <>
                            <div className="card-shadow" >

                              <div className="after-cards">
                                <div className="formAbschlussOvwerview">
                                  <div style={{ width: "100%" }}>
                                    <div className="row col-md-12">
                                      <div className="col-md-4"></div>
                                      <div
                                        className="col-md-4"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                          paddingLeft: "5rem",
                                        }}
                                      >
                                        <p
                                          style={{
                                            textTransform: "uppercase",
                                            textAlign: "left",
                                          }}
                                        >
                                          Übergabe
                                        </p>
                                      </div>
                                      <div className="row col-md-4">
                                        <div className="col-md-6">
                                          <p style={{ marginLeft: "-1.7rem" }}>
                                            Information
                                          </p>
                                        </div>
                                        <div
                                          className="col-md-6"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <p>Image</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="LigneSclusselDataOvwerview">
                                    <div className="blocks-SclusselOvwerview">
                                      <div
                                        className="block1-SclusselOvwerview"
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        {parseInt(
                                          protocolToShow?.protocol_keys[s]
                                            ?.key_number
                                        ) >
                                          protocolToShow?.protocol_keys[s]
                                            ?.number_keys_returned_in_main_protocol ? (
                                          <img
                                            className="notvalidateSclusselOvwerviewIcon"
                                            src={NotValidIcon}
                                            alt="notvalidateSclusselOvwerview Icon"
                                            style={{
                                              paddingTop: "0",
                                              height: "12.5px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className="validateSclusselOvwerviewIcon"
                                            src={ValidIcon}
                                            alt="validateSclusselOvwerview Icon"
                                            style={{
                                              paddingTop: "0",
                                              height: "12.5px",
                                            }}
                                          />
                                        )}
                                        <p
                                          style={{
                                            marginTop: "0px",
                                            marginBottom: "3px",
                                            fontSize: "1.15rem",
                                          }}
                                        >
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_number &&
                                            protocolToShow?.protocol_keys[s]
                                              ?.key_number.length > 0 ? (
                                            <span>
                                              {
                                                protocolToShow?.protocol_keys[s]
                                                  ?.key_number
                                              }{" "}
                                              x{" "}
                                            </span>
                                          ) : (
                                            <span>0 x </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "mainEntrance" && (
                                              <span> Eingangstür, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "apartmentKey" && (
                                              <span> Wohnungsschlüssel, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "basement" && (
                                              <span> Keller, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "attic" && (
                                              <span> Dachboden, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "garage" && (
                                              <span> Garage, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "shed" && (
                                              <span> Schuppen, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "outerGate" && (
                                              <span> Aussentor, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "goal" && (
                                              <span> Tor, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "gazebo" && (
                                              <span> Gartenlaube, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "letterBox" && (
                                              <span> Briefkasten, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "frontDoor" && (
                                              <span> Haustür, </span>
                                            )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type == "other" && (
                                              <span>
                                                {
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.key_name
                                                }{" "}
                                                ,{" "}
                                              </span>
                                            )}
                                          <span
                                            style={
                                              parseInt(
                                                protocolToShow?.protocol_keys[s]
                                                  ?.key_number
                                              ) >
                                                protocolToShow?.protocol_keys[s]
                                                  ?.number_keys_returned_in_main_protocol
                                                ? { color: "#D00000" }
                                                : { color: "#62AB12" }
                                            }
                                          >
                                            erhalten :{" "}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.number_keys_returned_in_main_protocol
                                              ? protocolToShow?.protocol_keys[s]
                                                ?.number_keys_returned_in_main_protocol
                                              : 0}{" "}
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        className="block2-SclusselOvwerview"
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <img
                                          className="CalenderSclusselOvwerviewIcon"
                                          src={CalenderIcon}
                                          alt="validateSclusselOvwerview Icon"
                                          style={{ height: "14.75px" }}
                                        />
                                        <p
                                          style={{
                                            marginTop: "0",
                                            marginBottom: "0",
                                            fontSize: "1.2rem",
                                          }}
                                        >
                                          <span></span>
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_date_of_forwarding
                                            ? protocolToShow.protocol_keys[s]
                                              ?.key_date_of_forwarding
                                            : "K.A"}
                                        </p>
                                      </div>
                                      <div
                                        className="block3-SclusselOvwerview"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className="col-md-6">
                                          <span
                                            style={{
                                              marginTop: "0",
                                              marginBottom: "0",
                                              fontSize: "1.2rem",
                                            }}
                                          >
                                            <span style={{ marginLeft: "-5rem" }}>
                                              {protocolToShow?.protocol_keys[s]
                                                ?.key_notice
                                                ? protocolToShow?.protocol_keys[s]
                                                  ?.key_notice
                                                : "Kein Kommentar"}
                                            </span>
                                          </span>
                                        </div>
                                        <div
                                          className="col-md-6"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {protocolToShow?.protocol_keys[s]
                                            .image &&
                                            protocolToShow?.protocol_keys[s]?.image
                                              ?.key_image_remote_url != null ? (
                                            <div className="image--container ">
                                              {showImage &&
                                                protocolToShow?.protocol_keys[s]
                                                  ?.image?.id == idImageShow && (
                                                  <div
                                                    className="image-overlay"
                                                    onClick={() =>
                                                      toggleImage(
                                                        protocolToShow
                                                          ?.protocol_keys[s]
                                                          ?.image?.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        protocolToShow
                                                          ?.protocol_keys[s]
                                                          ?.image
                                                          ?.key_image_remote_url
                                                      }
                                                      alt={
                                                        protocolToShow
                                                          ?.protocol_keys[s]
                                                          ?.image?.key_image_name
                                                      }
                                                      className="image"
                                                    />
                                                  </div>
                                                )}
                                              {!showImage && (
                                                <div
                                                  className="icon overview"
                                                  onClick={() =>
                                                    toggleImage(
                                                      protocolToShow
                                                        ?.protocol_keys[s]?.image
                                                        ?.id
                                                    )
                                                  }
                                                >
                                                  <i className="bx bx-zoom-in"></i>
                                                </div>
                                              )}
                                              <img
                                                src={
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.image?.key_image_remote_url
                                                }
                                                alt={
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.image?.key_image_name
                                                }
                                                style={{
                                                  objectFit: "cover",
                                                  width: "7.8rem",
                                                  height: "9.4rem",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="image-container">
                                              <PhotoLibraryIcon
                                                className="defaultImageIcon"
                                                sx={{
                                                  width: "7.8rem",
                                                  height: "9.4rem",
                                                  marginLeft: "-4rem",
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="rowkommentarkey col-md-12"
                                    style={{ marginBottom: "0.25rem" }}
                                  >
                                    <div className=" col-md-5">
                                      {window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) && (
                                          <p style={{ textTransform: "uppercase" }}>
                                            <span
                                              style={{
                                                color: "#888888",
                                                marginRight: "0.5rem",
                                              }}
                                            >
                                              Kommentar:
                                            </span>
                                            <span>
                                              {protocolToShow?.protocol_keys[s]
                                                ?.controle_comment ||
                                                " Keine Kommentar"}
                                            </span>
                                          </p>
                                        )}
                                      {(window.location.pathname.startsWith(
                                        "/app/protocolcontrol"
                                      ) ||
                                        window.location.pathname.startsWith(
                                          "/app/update_protocolcontrol"
                                        )) && (
                                          <TextField
                                            style={{ marginLeft: "-2rem" }}
                                            InputLabelProps={{
                                              style: {
                                                textTransform: "uppercase",
                                                padding: "0 5px 0 0",
                                                backgroundColor: "white",
                                              },
                                            }}
                                            name="controle_comment"
                                            value={
                                              protocolToShow?.protocol_keys[s]
                                                ?.controle_comment
                                            }
                                            onChange={(e) =>
                                              handleChangeKey(
                                                e.target.name,
                                                e.target.value,
                                                s
                                              )
                                            }
                                            id="controle_comment"
                                            type="text"
                                            placeholder={"KOMMENTAR"}
                                            variant="outlined"
                                            rows="1"
                                            multiline={true}
                                            fullWidth
                                          />
                                        )}
                                    </div>
                                    {protocolToShow?.protocol_keys[s]
                                      ?.number_keys_returned_in_main_protocol !=
                                      null &&
                                      protocolToShow?.protocol_keys[s]
                                        ?.number_keys_returned_in_main_protocol !=
                                      "" ? (
                                      window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) ? (
                                        <div className=" col-md-7">
                                          <AddCircleIcon
                                            sx={{
                                              fontSize: 50,
                                              color: "#c9c5c5",
                                              marginRight: "0.5rem",
                                              marginLeft: "0.5rem",
                                            }}
                                          />
                                          <span
                                            style={{
                                              fontSize: "21px",
                                              fontWeight: "bold",
                                              color: "#000000",
                                              marginRight: "0.5rem",
                                              marginLeft: "0.5rem",
                                            }}
                                          >
                                            {
                                              protocolToShow?.protocol_keys[s]
                                                ?.number_keys_returned
                                            }
                                          </span>
                                          <RemoveCircleIcon
                                            sx={{
                                              fontSize: 50,
                                              color: "#c9c5c5",
                                              marginLeft: "0.5rem",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className=" col-md-7">
                                          <AddCircleIcon
                                            sx={
                                              parseInt(
                                                protocolToShow?.protocol_keys[s]
                                                  ?.key_number
                                              ) ==
                                                parseInt(
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.number_keys_returned_in_main_protocol
                                                ) +
                                                protocolToShow.protocol_keys[s]
                                                  .number_keys_returned
                                                ? {
                                                  fontSize: 50,
                                                  color: "#c9c5c5",
                                                  marginRight: "0.5rem",
                                                  marginLeft: "0.5rem",
                                                }
                                                : {
                                                  fontSize: 50,
                                                  color: "rgb(98, 171, 18)",
                                                  marginRight: "0.5rem",
                                                  marginLeft: "0.5rem",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteuraddKey(e, s);
                                            }}
                                          />
                                          <span
                                            style={
                                              counters[s] >= 10
                                                ? {
                                                  fontSize: "21px",
                                                  fontWeight: "bold",
                                                  color: "#000000",
                                                  marginRight: "0.5rem",
                                                  marginLeft: "0.5rem",
                                                  minWidth: "1.8rem",
                                                  textAlign: "center",
                                                }
                                                : {
                                                  fontSize: "21px",
                                                  fontWeight: "bold",
                                                  color: "#000000",
                                                  marginRight: "1.15rem",
                                                  marginLeft: "0.5rem",
                                                  minWidth: "1.8rem",
                                                  textAlign: "center",
                                                }
                                            }
                                          >
                                            {counters[s]}
                                          </span>
                                          <RemoveCircleIcon
                                            sx={
                                              protocol.protocol_keys[s]
                                                .number_keys_returned == 0
                                                ? {
                                                  fontSize: 50,
                                                  color: "#c9c5c5",
                                                  marginLeft: "1rem",
                                                }
                                                : {
                                                  fontSize: 50,
                                                  color: "#D00000",
                                                  marginLeft: "1rem",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteurRemoveKey(e, s);
                                            }}
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <div className=" col-md-7">
                                        <AddCircleIcon
                                          sx={{
                                            fontSize: 50,
                                            color: "#c9c5c5",
                                            marginRight: "0.5rem",
                                            marginLeft: "0.5rem",
                                          }}
                                          onClick={(e) => {
                                            CompteuraddKey(e, s);
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                            color: "#000000",
                                            marginRight: "0.5rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {"0"}
                                        </span>
                                        <RemoveCircleIcon
                                          sx={{
                                            fontSize: 50,
                                            color: "#c9c5c5",
                                            marginLeft: "0.5rem",
                                          }}
                                          onClick={(e) => {
                                            CompteurRemoveKey(e, s);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr className="hrOvwerview" />
                              </div>
                            </div>
                          </>
                        )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : null}
          {(window.location.pathname.startsWith(
            "/app/update_protocolcontrol"
          ) ||
            window.location.pathname.startsWith("/app/protocolcontrol")) && (
              <>

                <div className="SonCard">
                  <div className="wohnungsdaten">
                    <div className="first_wohnungsdaten col-md-5 ">
                      <div className="row padding-center h-full">
                        <img
                          className="WohnungsdatenIcon"
                          src={WohnungsdatenUserIcon}
                          alt="eigentumer Icon"
                        />
                        <h3>Protokollant</h3>
                      </div>
                    </div>
                    <div
                      className="sub_wohnungsdaten col-md-7 w-full"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="row  d-flex align-items-center h- mr-0 ml-0  w-full">
                        <div className="col-md-12">
                          <div className="custom-select-owner">
                            {/*
            "Protokollant/in neu aufnehmen".  // new note taker
            "Protkollant/in aus Wohnungsgeber übernehmen",  //this is take note taker from landlord
             "Protokollant/in aus Benutzerkonto der App übernehmen"  //this is note taker from user
             */}
                            <CustomDropdown
                              options={[
                                {
                                  label: "Protokollant/in neu aufnehmen",
                                  value: "new_note_taker",
                                },
                                {
                                  label:
                                    "Protkollant/in aus Wohnungsgeber übernehmen",
                                  value: "note_taker_from_landlord",
                                },
                                {
                                  label:
                                    "Protokollant/in aus Benutzerkonto der App übernehmen",
                                  value: "note_taker_from_current_user",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-shadow" >

                    <div className="after-cards">
                      <div className="textfieldsdateplzObOw">
                        <div className="row mt-2 mb-2">
                          <div className="col-md-6">
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel
                                onClick={() => handleInputClick}
                                inputRef={(el) => (inputRefs.current[16] = el)}
                                id="note_taker_gender"
                                style={{
                                  backgroundColor: "white",
                                  padding: "0 5px 0 0",
                                }}
                                shrink={
                                  (shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_gender) ||
                                  (!shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_gender) ||
                                  (shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_gender ===
                                      null ||
                                      protocol.note_taker.note_taker_gender === ""))
                                }
                              >
                                ANREDE WÄHLEN *
                              </InputLabel>

                              <Select
                                labelId="note_taker_gender"
                                id="note_taker_gender"
                                value={protocol?.note_taker?.note_taker_gender}
                                label="ANREDE WÄHLEN"
                                name="note_taker_gender"
                                onChange={(e) => handleNoteTakerChange(e)}
                                fullWidth
                                required
                                inputProps={{ "aria-label": "My Select" }}
                                InputLabelProps={
                                  protocol &&
                                  protocol.note_taker &&
                                  protocol.note_taker.note_taker_gender && {
                                    shrink: true,
                                  }
                                }
                              >
                                <MenuItem value={"noChoice"}>Keine Angabe</MenuItem>
                                <MenuItem value={"male"}>Herr</MenuItem>
                                <MenuItem value={"female"}>Frau</MenuItem>
                                <MenuItem value={"divers"}>Divers</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-md-6">
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel
                                id="note_taker_title"
                                style={{
                                  backgroundColor: "white",
                                  padding: "0 5px 0 0",
                                }}
                                shrink={
                                  (shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_title) ||
                                  (!shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_title) ||
                                  (shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_title ===
                                      null ||
                                      protocol.note_taker.note_taker_title === ""))
                                }
                              >
                                TITEL WÄHLEN
                              </InputLabel>
                              <Select
                                labelId="note_taker_title"
                                id="note_taker_title"
                                name="note_taker_title"
                                value={protocol?.note_taker?.note_taker_title}
                                label="TITEL WÄHLEN"
                                onChange={(e) => handleNoteTakerChange(e)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem value={"noChoice"}>Keine Angabe</MenuItem>
                                <MenuItem value={"dr"}>Dr.</MenuItem>
                                <MenuItem value={"prof"}>Prof.</MenuItem>
                                <MenuItem value={"ing"}>Ing.</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-md-6">
                            <TextField
                              onClick={() => handleInputClick}
                              inputRef={(el) => (inputRefs.current[15] = el)}
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:
                                  (shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_first_name) ||
                                  (!shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_first_name) ||
                                  (shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_first_name ===
                                      null ||
                                      protocol.note_taker.note_taker_first_name ===
                                      "")),
                              }}
                              name="note_taker_first_name"
                              id="note_taker_first_name"
                              value={
                                protocol?.note_taker?.note_taker_first_name || ""
                              }
                              type="text"
                              label="VORNAME"
                              required
                              variant="outlined"
                              fullWidth
                              onChange={(e) => handleNoteTakerChange(e)}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              onClick={() => handleInputClick}
                              inputRef={(el) => (inputRefs.current[17] = el)}
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:
                                  (shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_last_name) ||
                                  (!shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_last_name) ||
                                  (shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_last_name ===
                                      null ||
                                      protocol.note_taker.note_taker_last_name ===
                                      "")),
                              }}
                              name="note_taker_last_name"
                              id="note_taker_last_name"
                              value={
                                protocol?.note_taker?.note_taker_last_name == null
                                  ? ""
                                  : protocol?.note_taker?.note_taker_last_name
                              }
                              type="text"
                              required
                              label="NACHNAME"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => handleNoteTakerChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {window.location.pathname.startsWith("/app/show_protocolcontrol") ? (
            <>
              <div className="SonCard mt-3 mb-3">
                <div
                  className="neuer"
                  style={{
                    background:
                      "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
                  }}
                >
                  <img
                    className="NeuerIcon"
                    src={ContractIcon}
                    alt="Abschlussbemerkung Icon"
                  />
                  <h3 style={{ textTransform: "none" }}> Unterschriften </h3>
                </div>
                <div className="card-shadow">
                  <div className="VerteilerContainer">
                    <div className="formAbschlussOvwerview">
                      <div className="sub-formverteilerOvwerview">
                        <div className="first-part-formverteilerOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Wohnungsgeber</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "100%" }}>
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "female" && "Frau "}
                              </span>
                              <br />
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.landlord?.landlord_first_name ==
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name ==
                                  null && <span>Name und Vorname fehlt</span>}
                                {(protocolToShow?.landlord?.landlord_first_name ==
                                  null ||
                                  protocolToShow?.landlord?.landlord_first_name ==
                                  "") &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && (
                                    <>
                                      {protocolToShow?.landlord
                                        ?.landlord_first_name
                                        ? protocolToShow?.landlord
                                          ?.landlord_first_name
                                        : " Vorname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  (protocolToShow?.landlord?.landlord_last_name ==
                                    null ||
                                    protocolToShow?.landlord
                                      ?.landlord_last_name == "") && (
                                    <>
                                      {protocolToShow?.landlord
                                        ?.landlord_last_name
                                        ? protocolToShow?.landlord
                                          ?.landlord_last_name
                                        : " Nachname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && (
                                    <>
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_first_name
                                      }{" "}
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_last_name
                                      }
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {protocolToShow?.landlord?.signature_landlord
                          ?.signature_landlord === true ? (
                          <>
                            <div className="second-part-formverteilerOvwerview">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.landlord?.signature_landlord
                                    ?.signature_landlord_time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.landlord
                                          ?.signature_landlord
                                          ?.signature_landlord_time_stamp
                                          ?.seconds
                                      )
                                      .add(
                                        protocolToShow?.landlord
                                          ?.signature_landlord
                                          ?.signature_landlord_time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="second-part-formverteilerOvwerview">
                            <PhotoLibraryIcon
                              className="defaultImageIcon"
                              sx={{ width: 425, height: 200 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  <div className="VerteilerContainer">
                    <div className="formAbschlussOvwerview">
                      <div className="sub-formverteilerOvwerview">
                        <div className="first-part-formverteilerOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Mieter</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "100%" }}>
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.renter?.renter_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "female" && "Frau "}
                              </span>
                              <br />
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.renter?.renter_first_name ==
                                  null &&
                                  protocolToShow?.renter?.renter_last_name ==
                                  null && <span>Name und Vorname fehlt</span>}
                                {(protocolToShow?.renter?.renter_first_name ==
                                  null ||
                                  protocolToShow?.renter?.renter_first_name ==
                                  "") &&
                                  protocolToShow?.renter?.renter_last_name !=
                                  null && (
                                    <>
                                      {protocolToShow?.renter?.renter_first_name
                                        ? protocolToShow?.renter
                                          ?.renter_first_name
                                        : " Vorname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.renter?.renter_first_name !=
                                  null &&
                                  (protocolToShow?.renter?.renter_last_name ==
                                    null ||
                                    protocolToShow?.renter?.renter_last_name ==
                                    "") && (
                                    <>
                                      {protocolToShow?.renter?.renter_last_name
                                        ? protocolToShow?.renter?.renter_last_name
                                        : " Nachname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.renter?.renter_first_name !=
                                  null &&
                                  protocolToShow?.renter?.renter_last_name !=
                                  null && (
                                    <>
                                      {protocolToShow?.renter?.renter_first_name}{" "}
                                      {protocolToShow?.renter?.renter_last_name}
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {protocolToShow?.renter?.signature_renter
                          ?.signature_renter === true ? (
                          <>
                            <div className="second-part-formverteilerOvwerview">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.renter?.signature_renter
                                    ?.signature_renter_time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.renter?.signature_renter
                                          ?.signature_renter_time_stamp?.seconds
                                      )
                                      .add(
                                        protocolToShow?.renter?.signature_renter
                                          ?.signature_renter_time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          protocolToShow?.renter?.signature_renter
                            ?.signature_renter === false && (
                            <div className="second-part-formverteilerOvwerview">
                              {protocolToShow?.renter?.signature_renter
                                ?.signature_renter_refuse_reason ? (
                                <span
                                  className="bad-status"
                                  style={{ marginLeft: "8rem" }}
                                >
                                  <img
                                    className="notvalidateSclusselOvwerviewIcon"
                                    src={NotValidIcon}
                                    alt="notvalidateSclusselOvwerview Icon"
                                    style={{
                                      width: "1rem",
                                      marginRight: "0.25rem",
                                    }}
                                  />
                                  {formatReason(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_refuse_reason
                                  )}
                                </span>
                              ) : (
                                <span
                                  className="bad-status"
                                  style={{ marginLeft: "8rem" }}
                                >
                                  <img
                                    className="notvalidateSclusselOvwerviewIcon"
                                    src={NotValidIcon}
                                    alt="notvalidateSclusselOvwerview Icon"
                                    style={{
                                      width: "1rem",
                                      marginRight: "0.25rem",
                                    }}
                                  />
                                  Keine Unterschrift
                                </span>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  {protocolToShow?.coRenter?.signature_coRenter?.signature ===
                    false ? null : (
                    <div className="VerteilerContainer">
                      <div className="formAbschlussOvwerview">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span>Bewohner</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.coRenter?.salutaion == null &&
                                    "Anrede fehlt "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "noChoice" && "Keine Angabe "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "male" && "Herr "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "female" && "Frau "}
                                </span>
                                <br />
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.coRenter?.first_name == null &&
                                    protocolToShow?.coRenter?.last_name ==
                                    null && <span>Name und Vorname fehlt</span>}
                                  {(protocolToShow?.coRenter?.first_name ==
                                    null ||
                                    protocolToShow?.coRenter?.first_name == "") &&
                                    protocolToShow?.coRenter?.last_name !=
                                    null && (
                                      <>
                                        {protocolToShow?.coRenter?.first_name
                                          ? protocolToShow?.coRenter?.first_name
                                          : " Vorname fehlt "}
                                      </>
                                    )}
                                  {protocolToShow?.coRenter?.first_name != null &&
                                    (protocolToShow?.coRenter?.last_name ==
                                      null ||
                                      protocolToShow?.coRenter?.last_name ==
                                      "") && (
                                      <>
                                        {protocolToShow?.coRenter?.last_name
                                          ? protocolToShow?.coRenter?.last_name
                                          : " Nachname fehlt "}
                                      </>
                                    )}
                                  {protocolToShow?.coRenter?.first_name != null &&
                                    protocolToShow?.coRenter?.last_name !=
                                    null && (
                                      <>
                                        {protocolToShow?.coRenter?.first_name}{" "}
                                        {protocolToShow?.coRenter?.last_name}
                                      </>
                                    )}
                                </span>
                              </div>
                            </div>
                          </div>
                          {protocolToShow?.coRenter?.signature_coRenter
                            ?.signature === true && (
                              <>
                                <div className="second-part-formverteilerOvwerview">
                                  {protocolToShow?.coRenter?.signature_coRenter
                                    ?.refuse_reason ? (
                                    <span
                                      className="bad-status"
                                      style={{ marginLeft: "8rem" }}
                                    >
                                      <img
                                        className="notvalidateSclusselOvwerviewIcon"
                                        src={NotValidIcon}
                                        alt="notvalidateSclusselOvwerview Icon"
                                        style={{
                                          width: "1rem",
                                          marginRight: "0.25rem",
                                        }}
                                      />
                                      {formatReason(
                                        protocolToShow?.coRenter?.signature_coRenter
                                          ?.refuse_reason
                                      )}
                                    </span>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          protocolToShow?.coRenter
                                            ?.signature_coRenter?.remote_url
                                        }
                                        alt={
                                          protocolToShow?.coRenter
                                            ?.signature_coRenter?.image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                      <span
                                        style={{
                                          fontSize: "700",
                                          color: "#000000",
                                        }}
                                      >
                                        {protocolToShow?.coRenter
                                          ?.signature_coRenter?.time_stamp !== null
                                          ? moment
                                            .unix(
                                              protocolToShow?.coRenter
                                                ?.signature_coRenter?.time_stamp
                                                ?.seconds
                                            )
                                            .add(
                                              protocolToShow?.coRenter
                                                ?.signature_coRenter?.time_stamp
                                                ?.nanoseconds / 1000000
                                            )
                                            .format("DD/MM/YYYY, HH:mm")
                                          : "Kein Angabe"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  )}

                  {protocolToShow?.protocol_type == "moveInAndExit" ? (
                    <div className="VerteilerContainer">
                      <div className="formAbschlussOvwerview">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span className="uppercase mb-2">Vormieter</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == null &&
                                    "Anrede fehlt "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == "noChoice" &&
                                    "Keine Angabe "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == "male" &&
                                    "Herr "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == "female" &&
                                    "Frau "}
                                </span>
                                <br />
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_first_name
                                    ? protocolToShow?.previous_renter
                                      ?.previous_renter_first_name + " "
                                    : "Vorname fehlt"}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_last_name
                                    ? protocolToShow?.previous_renter
                                      ?.previous_renter_last_name
                                    : "Nachname fehlt"}
                                </span>
                              </div>
                            </div>
                          </div>
                          {protocolToShow?.previous_renter
                            ?.signature_previous_renter
                            ?.signature_previous_renter === true ? (
                            <>
                              <div className="second-part-formverteilerOvwerview">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_image_remote_url
                                    }
                                    alt={
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_image_name
                                    }
                                    height="125px"
                                    width="125px"
                                  />
                                  <span
                                    style={{ fontSize: "700", color: "#000000" }}
                                  >
                                    {protocolToShow?.previous_renter
                                      ?.signature_previous_renter
                                      ?.signature_previous_renter_time_stamp !==
                                      null
                                      ? moment
                                        .unix(
                                          protocolToShow?.previous_renter
                                            ?.signature_previous_renter
                                            ?.signature_previous_renter_time_stamp
                                            ?.seconds
                                        )
                                        .add(
                                          protocolToShow?.previous_renter
                                            ?.signature_previous_renter
                                            ?.signature_previous_renter_time_stamp
                                            ?.nanoseconds / 1000000
                                        )
                                        .format("DD/MM/YYYY, HH:mm")
                                      : "Kein Angabe"}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            protocolToShow?.previous_renter
                              ?.signature_previous_renter
                              ?.signature_previous_renter === false && (
                              <div className="second-part-formverteilerOvwerview">
                                {protocolToShow?.previous_renter
                                  ?.signature_previous_renter
                                  ?.signature_previous_renter_refuse_reason ? (
                                  <span
                                    className="bad-status"
                                    style={{ marginLeft: "8rem" }}
                                  >
                                    <img
                                      className="notvalidateSclusselOvwerviewIcon"
                                      src={NotValidIcon}
                                      alt="notvalidateSclusselOvwerview Icon"
                                      style={{
                                        width: "1rem",
                                        marginRight: "0.25rem",
                                      }}
                                    />
                                    {formatReason(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_refuse_reason
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    className="bad-status"
                                    style={{ marginLeft: "8rem" }}
                                  >
                                    {/* <CloseIcon sx={{ fontSize: 33 }} /> */}
                                    <img
                                      className="notvalidateSclusselOvwerviewIcon"
                                      src={NotValidIcon}
                                      alt="notvalidateSclusselOvwerview Icon"
                                      style={{
                                        width: "1rem",
                                        marginRight: "0.25rem",
                                      }}
                                    />
                                    Verweigert Unterschrift
                                  </span>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  ) : null}

                  {protocolToShow?.witness_signature?.signature_witness ===
                    false ? null : (
                    <div className="VerteilerContainer">
                      <div className="formAbschlussOvwerview">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span>Zeuge</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.witness_signature
                                    ?.signature_witness_name !== null
                                    ? protocolToShow?.witness_signature
                                      ?.signature_witness_name
                                    : protocolToShow?.witness_signature
                                      ?.signature_witness_name === null && (
                                      <span>Name fehlt</span>
                                    )}
                                </span>
                              </div>
                            </div>
                          </div>
                          {protocolToShow?.witness_signature
                            ?.signature_witness === true &&
                            (protocolToShow?.witness_signature
                              ?.signature_witness_refuse_reason ? (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.witness_signature
                                    ?.signature_witness_refuse_reason
                                )}
                              </span>
                            ) : (
                              <>
                                <div className="second-part-formverteilerOvwerview">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_image_name
                                      }
                                      height="125px"
                                      width="125px"
                                    />
                                    <span
                                      style={{
                                        fontSize: "700",
                                        color: "#000000",
                                      }}
                                    >
                                      {protocolToShow?.witness_signature
                                        ?.signature_witness_time_stamp !== null
                                        ? moment
                                          .unix(
                                            protocolToShow?.witness_signature
                                              ?.signature_witness_time_stamp
                                              ?.seconds
                                          )
                                          .add(
                                            protocolToShow?.witness_signature
                                              ?.signature_witness_time_stamp
                                              ?.nanoseconds / 1000000
                                          )
                                          .format("DD/MM/YYYY, HH:mm")
                                        : "Keine Angabe"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}

          {(window.location.pathname.startsWith("/app/protocolcontrol") ||
            window.location.pathname.startsWith(
              "/app/update_protocolcontrol"
            )) && (
              <div class="SonCard">
                <div class="after-cards" style={{ backgroundColor: "transparent !important" }}>
                  <div className="OvwerviewGlobalButton">
                    <button type="button" onClick={onSubmit}>
                      Speichern
                    </button>
                  </div></div></div>
            )}
        </div>
      </div>
      {show && (
        <Dialog
          setShow={setShow}
          show={show}
          //selects={selects}
          // setSelects={setSelects}
          //setValue={setValue}
          placeholder={placeholder}
          index={index}
          type={type}
          id={type}
          value={value}
          level={level}
          handleInputChange={handleChangeComment}
          secondIndex={secondIndex}
        //multiline={true}
        />
      )}
      {alert}
    </>
  );
}

export default ProtokollOvwerView;
