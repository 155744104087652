/**
 *  App.js :: contains main layout of APP.
 */
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import HulkThemeProvider from "./HulkThemeProvider";
import { NotificationContainer } from "react-notifications";
import { CssBaseline } from "@material-ui/core";

// APP signin and signup imports
import AppForgotPassword from "./ForgotPassword/ForgotPassword";
import Login from "./Login/Login";
import Register from "./Register/Register";
import TicketReply from "routes/Support/ticket/ticket-reply";
import AppVerifyEmail from "./verfiyEmail/VerifyEmail";
import AppActions from "./ConfirmPassword/ConfirmPassword";

// deafult layout
import DefaultLayout from "./DefaultLayout";

/* import Auth from '../Auth/Auth'; */
import AuthProvider from "../context/AuthContext";
import RequireAuth from "../context/RequireAuth";
// callback component
import Callback from "components/Callback/Callback";
import DatenInfo from "./DataInfo/datenInfo";
/* 
//Auth0 Handle Authentication
const auth = new Auth();
const handleAuthentication = ({ location }) => {
   if (/access_token|id_token|error/.test(location.hash)) {
	  auth.handleAuthentication();
   }
} */
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    const { location, match } = this.props;
    if (location.pathname === "/") {
      if (localStorage.getItem("currentuser") === null) {
        return <Redirect to="/signin" />;
      } else {
        return <Redirect to="/app/dashboard" />;
      }
    }
    return (
		
      <AuthProvider>
		
        <HulkThemeProvider>
          <CssBaseline />
          <NotificationContainer />
          <InitialPath
            path={`${match.url}app`}
            authUser={localStorage.getItem("currentuser")}
            component={DefaultLayout}
          />

          <Route path="/forgot-password" component={AppForgotPassword} />
          <Route path="/signin" component={Login} />
          <Route path="/signup" component={Register} />
          <Route path="/ticket-reply" component={TicketReply} />
          <Route path="/datenschutzerklarung" component={DatenInfo} />
          <Route path="/action" component={AppActions} />

          {/*     <Route path="/callback" render={(props) => {
               handleAuthentication(props);
               return <Callback {...props} />
            }} /> */}
        </HulkThemeProvider>
      </AuthProvider>
    );
  }
}

/* //map state to props
const mapStateToProps = ({ authUser }) => {
	const { user } = authUser;
	return { user };
}; */

export default App;
