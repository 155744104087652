import React, { useState, useEffect } from "react";
import moment from "moment";
import keyIcon from "../../assets/Icons/key-icon.svg";
import ViewContractIcon from "../../assets/Icons/viewcontract-icon.svg";
import ValidIcon from "../../assets/Icons/valid-icon.svg";
import RaumeIcon from "../../assets/Icons/raume-icon.svg";
import VerteilerIcon from "../../assets/Icons/verteiler-icon.svg";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import CalenderIcon from "../../assets/Icons/calander-icon.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import _ from "lodash";
import { grey } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ZumIcon from "../../assets/Icons/zum-icon.svg";
import { Checkbox } from "@mui/material";
import { userService } from "../../_services/user.service";
import { companyService } from "../../_services/company.service";
import { protocolService } from "_services/protocol.service";
import Direction from "@mui/icons-material/SubdirectoryArrowRightSharp";
import damage from "../../assets/Icons/damage.svg";
import Config from "@mui/icons-material/Construction";
import DateIcon from "@mui/icons-material/CalendarMonth";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { makeStyles } from "@material-ui/core";
import HideComment from "@mui/icons-material/CommentsDisabled";
import Comment from "@mui/icons-material/Comment";
import Previous from "@mui/icons-material/ArrowBackIos";
import Next from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: "relative",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    padding: "10px",
    width: "100%",
    textAlign: "justify",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}));
function ProtokollOvwerView() {
  const history = useHistory();
  const classes = useStyles();
  const imagesPerPage = 6;

  const location = useLocation();
  //const protocolToShow = location.state;
  const [showImage, setShowImage] = useState(false);
  const [idImageShow, setIdImageShow] = useState(false);
  const [protocol_pattern, setProtocolPattern] = useState([]);
  const [protocolToShow, setProtocolToShow] = useState();
  const [activeIndexMangel, setActiveIndexMangel] = useState(0);
  const [clickedOnBullet, setClickedOnBullet] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [CurrentPageByRoom, setCurrentPageByRoom] = useState([]);
  const [comment, setComment] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesByRoom, setPagesByRoom] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const label = { inputProps: { "aria-label": "Checkbox" } };
  function cancel() {
    history.push("/app/overview");
  }
  const toggleImage = (index) => {
    setIdImageShow(index);
    setShowImage(!showImage);
  };
  const next = (index, type = null) => {
    if (animating || clickedOnBullet) return;
    const nextIndex =
      activeIndex === protocolToShow.protocol_rooms[index].room_image.length - 1
        ? 0
        : activeIndex + 1;
    if (type == null) setActiveIndex(nextIndex);
    else setActiveIndexMangel(nextIndex);
  };
  const previous = (index, type = null) => {
    if (animating) return;
    const prevIndex =
      activeIndex === 0
        ? protocolToShow.protocol_rooms[index].room_image.length - 1
        : activeIndex - 1;
    if (type == null) setActiveIndex(prevIndex);
    else setActiveIndexMangel(prevIndex);
  };
  const goToIndexMangel = (newIndex) => {
    if (animating) return;
    setClickedOnBullet(true);
    setActiveIndexMangel(newIndex);
  };
  const handleExiting = () => {
    setAnimating(true);
  };
  // Function to update selected index to true
  const handleCurrentPageByRoom = (page, i) => {
    setCurrentPage(page)
    // Updating the array based on condition index === i
    setCurrentPageByRoom(prev => {
      const updatedCurrentPageByRoom = CurrentPageByRoom.map((value, index) => {
        return index === i ? page : value;
      });
      return updatedCurrentPageByRoom;
    });
  }
  // Function to handle pagination for the selected room
  const paginate = (pageNumber, id) => {
    handleCurrentPageByRoom(pageNumber, id)


  };
  //calcul number of pages need to show images of one room 
  function calculatePages(roomImages) {
    console.log(roomImages)
    const numImages = roomImages.room_image != null ? roomImages.room_image.length : 0
    var pages
    if (numImages > 0) {
      pages = Math.ceil(numImages / 6);
    }
    return numImages == 0 ? 0 : pages;
  }
  // Example assuming protocol.rooms.length gives you the desired length
  const initializeIndices = (lengthOfProtocolRooms) => {
    // Initialize initializedArray
    const initializedArray = Array.from({ length: lengthOfProtocolRooms }, (_, i) => {
      const pages = calculatePages(protocolToShow.protocol_rooms[i]);
      return pages;
    });
    const arrayofzero = Array.from({ length: lengthOfProtocolRooms }, (_, i) => 0);
    setCurrentPageByRoom(arrayofzero)
    setPagesByRoom(initializedArray)

  };
  useEffect(() => {
    if (protocolToShow && protocolToShow.protocol_rooms) {
      // Call the initialization function when component mounts or protocol changes
      const lengthOfProtocolRooms = protocolToShow.protocol_rooms.length;
      if (lengthOfProtocolRooms) { initializeIndices(lengthOfProtocolRooms); }
    }

  }, [protocolToShow]);
  function formatReason(reason) {
    console.log(reason);
    if (reason == "notPresent") return "Nicht anwesend";
    else if (reason == "refuseToSign") return "Verweigert Unterschrift";
    else return "Keine Unterschrift";
  }

  // get company info of current user
  const getCompanyId = async () => {
    const userDoc = await userService.getUser(userService.getId());
    const userObject = userDoc.docs.map((doc) => doc.data());
    const companyId = userObject[0].company_id;
    getProtocolPattern(companyId);
  };
  // get protocol 
  const getProtocolToShow = async () => {
    await protocolService.getProtocol(location.state.state.id_doc).then(result => {
      if (result) {
        setProtocolToShow(result.data())
      }
    });
  };
  const getProtocolPattern = async (companyId) => {
    await companyService.getProtocolPattern(companyId).then((result) => {
      if (result?.length > 0) {
        setProtocolPattern(result);
      }
    });
  };
  useEffect(() => {
    getProtocolToShow();
    getCompanyId();
  }, [location.state]);

  const handleDisplayPatternSection = (section_name) => {
    // Find the index of the section_name
    const sectionIndex = protocol_pattern.findIndex(
      (section) => section.key_section_name === section_name
    );

    // Iterate through protocol_pattern starting from the next index after sectionIndex
    if (sectionIndex !== -1 && sectionIndex < protocol_pattern.length - 1) {
      for (let i = sectionIndex + 1; i < protocol_pattern.length; i++) {
        const section = protocol_pattern[i];
        const hasItemsWithB = section.ref_id && section.ref_id.includes("B");
        if (hasItemsWithB) {
          return (
            <div>
              <div
                key={section.id}
                className="formAbschlussOvwerview  mt-3"
                id={`section_${section.id}`}
              >
                <div className="LigneWohnungsdatenOvwerview">
                  <span>{section.title}</span>
                </div>
                <p>{section.content}</p>
              </div>
              <hr className="hrOvwerview" />
            </div>
          );
        } else return null;
      }
    }
  };
  return (
    <>

      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div className="row layoutcontent full-height mr-0 ml-0">
          <div className="einzug width">
            <img
              className="RaumDetailsIcon"
              src={ZumIcon}
              alt="Übergabeprotokoll Icon"
            />
            <h3>
              Übergabeprotokoll zum{" "}
              {protocolToShow?.protocol_type == "move"
                ? "Auszug"
                : protocolToShow?.protocol_type == "moveIn"
                  ? "Einzug"
                  : "Ein- und Auszug"}{" "}
            </h3>
          </div>
          <div className="Weiter align-end-button">
            <button
              onClick={() => cancel()}
              className="WeiterButton"
              type="submit"
            >
              Zurück
              <ArrowForwardIosIcon
                className="icon-registrieren"
                sx={{ fontSize: 16 }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">

            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "uppercase" }}> Protokolltyp </h3>
            </div>
            <div className="card-shadow" style={{ marginBottom: "2rem" }}>
              <div className="after-cards" >

                <div className="formAbschlussOvwerview">
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6">
                      <span className="uppercase">Art des Protokolls</span>
                      <div className=" text-style">
                        <span>
                          {protocolToShow?.protocol_type == null &&
                            "Kein Angabe "}
                          {protocolToShow?.protocol_type == "move" && "Einzug "}
                          {protocolToShow?.protocol_type == "moveIn" && "Auszug "}
                          {protocolToShow?.protocol_type == "moveInAndExit" &&
                            "Ein- und Auszug "}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <span className="uppercase">Datum der Übergabe</span>
                      <div className="text-style">
                        <span>
                          {protocolToShow?.date_of_hand_over
                            ? protocolToShow?.date_of_hand_over
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-shadow">
              <div className="neuer">
                <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
                <h3 style={{ textTransform: "uppercase" }}> Abschluss </h3>
              </div>
              <div className="after-cards pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase"> Wohnungsdaten</span>
                  </div>
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6 ">
                      <span className="uppercase">Adresse</span>
                      <div className=" text-style">
                        <span>
                          {protocolToShow?.object?.address?.object_street
                            ? protocolToShow?.object?.address?.object_street
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_house_nbr
                            ? protocolToShow?.object?.address?.object_house_nbr
                            : "Hausnummer  fehlt"}
                        </span>
                        <br />
                        <span>
                          {protocolToShow?.object?.address?.object_plz
                            ? protocolToShow?.object?.address?.object_plz
                            : "PLZ fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_city
                            ? protocolToShow?.object?.address?.object_city
                            : "Ort fehlt"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      {protocolToShow?.object?.object_date_of_renovation &&
                        <>
                          <span className="uppercase mt-1">Renovierungsdatum</span>
                          <div className=" text-style mb-2">
                            <span>
                              {protocolToShow?.object?.object_date_of_renovation
                                ? protocolToShow?.object?.object_date_of_renovation
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </>}
                      {(protocolToShow?.object?.address.object_address_note || protocolToShow?.object?.address?.object_position) &&
                        <>
                          <span className="uppercase mt-1">Adresszusatz/Lage oder Wohnungsnummer</span>

                          <div className="text-style">
                            <span>
                              {protocolToShow?.object?.address.object_address_note
                                ? protocolToShow?.object?.address.object_address_note
                                : "Angabe fehlt"}
                              ,{" "}
                              {protocolToShow?.object?.address?.object_position
                                ? protocolToShow?.object?.address?.object_position
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "owner"
                  ) &&
                  handleDisplayPatternSection("owner")}
              </div>

              <div className="aftercardOvwerview pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase">Eigentümer</span>
                  </div>
                  {protocolToShow?.owner_is_landlord == true ? (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold", color: "#000000" }}>
                          {protocolToShow?.landlord?.landlord_company_name
                            ? protocolToShow?.landlord?.landlord_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                        <span className="uppercase" style={{ fontSize: "16px" }}>Art des Wohnungsgebers</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }} className="mb-2">
                          <span>
                            {protocolToShow?.landlord?.landlord_salutation ==
                              null &&
                              protocolToShow?.landlord?.landlord_salutation
                              ? protocolToShow?.landlord?.landlord_salutation
                              : "Anrede fehlt "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.landlord?.landlord_title ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_title == "dr" &&
                              "Dr. "}
                            {protocolToShow?.landlord?.landlord_title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.landlord?.landlord_title == "ing" &&
                              "Ing. "}
                            {protocolToShow?.landlord?.landlord_first_name ==
                              null &&
                              protocolToShow?.landlord?.landlord_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.landlord?.landlord_first_name ==
                              null ||
                              protocolToShow?.landlord?.landlord_first_name ==
                              "") &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null &&
                              " Vorname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              (protocolToShow?.landlord?.landlord_last_name ==
                                null ||
                                protocolToShow?.landlord?.landlord_last_name ==
                                "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.landlord?.landlord_first_name}{" "}
                                  {protocolToShow?.landlord?.landlord_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_street
                              ? protocolToShow?.landlord?.address?.landlord_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address
                              ?.landlord_house_number
                              ? protocolToShow?.landlord?.address
                                ?.landlord_house_number
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_plz
                              ? protocolToShow?.landlord?.address?.landlord_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address?.landlord_city
                              ? protocolToShow?.landlord?.address?.landlord_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                        <div style={{ width: "50%" }} className="mb-2">
                          {(protocolToShow?.landlord?.landlord_type == null ||
                            protocolToShow?.landlord?.landlord_type == "") && (
                              <span>Angabe fehlt</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "noChoice" && (
                              <span style={{ color: "#000000" }}>Keine Angabe</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "company" && (
                              <span style={{ color: "#000000" }}>Gesellschaft</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "private" && (
                              <span style={{ color: "#000000" }}>Privat</span>
                            )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.landlord?.landlord_email &&
                          <span className="uppercase">E-mail:</span>
                        }
                        {protocolToShow?.landlord?.landlord_phone &&
                          <span className="uppercase">Telefonnummer:</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.landlord?.landlord_email &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_email
                                ? protocolToShow?.landlord?.landlord_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                        {protocolToShow?.landlord?.landlord_phone &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_phone
                                ? protocolToShow?.landlord?.landlord_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontWeight: "bold", color: "#000000" }}>
                          {protocolToShow?.owner?.owner_company_name
                            ? protocolToShow?.owner?.owner_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                        <span className="uppercase" style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }} className="mb-2">
                          <span>
                            {protocolToShow?.owner?.owner_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.owner?.owner_salutation == "male" &&
                              "Herr "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "association" && "WEG "}
                            {protocolToShow?.owner?.owner_title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.owner?.owner_title == "dr" && "Dr. "}
                            {protocolToShow?.owner?.owner_title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.owner?.owner_title == "ing" &&
                              "Ing. "}
                            {protocolToShow?.owner?.owner_first_name == null &&
                              protocolToShow?.owner?.owner_last_name == null && (
                                <span>Name und Vorname fehlt</span>
                              )}
                            {(protocolToShow?.owner?.owner_first_name == null ||
                              protocolToShow?.owner?.owner_first_name == "") &&
                              protocolToShow?.owner?.owner_last_name != null &&
                              " Vorname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              (protocolToShow?.owner?.owner_last_name == null ||
                                protocolToShow?.owner?.owner_last_name == "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              protocolToShow?.owner?.owner_last_name != null && (
                                <>
                                  {protocolToShow?.owner?.owner_first_name}{" "}
                                  {protocolToShow?.owner?.owner_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_street
                              ? protocolToShow?.owner?.address?.owner_street
                              : "Straße fehlt"}
                            ,
                            {protocolToShow?.owner?.address?.owner_house_number
                              ? protocolToShow?.owner?.address?.owner_house_number
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_plz
                              ? protocolToShow?.owner?.address?.owner_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.owner?.address?.owner_city
                              ? protocolToShow?.owner?.address?.owner_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                        <div style={{ width: "50%" }} className="mb-2">
                          {protocolToShow?.owner?.owner_Type == null && (
                            <span>Angabe fehlt</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "noChoice" && (
                            <span style={{ color: "#000000" }}>Keine Angabe</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "company" && (
                            <span style={{ color: "#000000" }}>Gesellschaft</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "private" && (
                            <span style={{ color: "#000000" }}>Privat</span>
                          )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.owner?.owner_email &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                        }
                        {protocolToShow?.owner?.owner_phone &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        {protocolToShow?.owner?.owner_email &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_email
                                ? protocolToShow?.owner?.owner_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }{protocolToShow?.owner?.owner_phone &&
                          <div style={{ width: "50%" }}>
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_phone
                                ? protocolToShow?.owner?.owner_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </>
                  )}
                </div>
                <hr className="hrOvwerview" />
              </div>

              <div className="aftercardOvwerview pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase">Wohnungsabnehmer</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      {protocolToShow?.landlord?.landlord_company_name
                        ? protocolToShow?.landlord?.landlord_company_name
                        : "Unternehmensname fehlt"}
                    </span>
                    <span className="uppercase" style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                  </div>
                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                    <div style={{ width: "50%" }} className="mb-2">
                      <span>
                        {protocolToShow?.landlord?.landlord_salutation == null &&
                          "Anrede fehlt "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "male" && "Herr "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "female" && "Frau "}
                        {protocolToShow?.landlord?.landlord_title == "noChoice" &&
                          "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_title == "dr" &&
                          "Dr. "}
                        {protocolToShow?.landlord?.landlord_title == "prof" &&
                          "Prof. "}
                        {protocolToShow?.landlord?.landlord_title == "ing" &&
                          "Ing. "}
                        {protocolToShow?.landlord?.landlord_first_name == null &&
                          protocolToShow?.landlord?.landlord_last_name ==
                          null && <span>Name und Vorname fehlt</span>}
                        {(protocolToShow?.landlord?.landlord_first_name == null ||
                          protocolToShow?.landlord?.landlord_first_name == "") &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name
                                ? protocolToShow?.landlord?.landlord_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          (protocolToShow?.landlord?.landlord_last_name == null ||
                            protocolToShow?.landlord?.landlord_last_name ==
                            "") && (
                            <>
                              {protocolToShow?.landlord?.landlord_last_name
                                ? protocolToShow?.landlord?.landlord_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name}{" "}
                              {protocolToShow?.landlord?.landlord_last_name}
                            </>
                          )}
                      </span>
                      <br />
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_street
                          ? protocolToShow?.landlord?.address?.landlord_street
                          : "Straße fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_house_number
                          ? protocolToShow?.landlord?.address
                            ?.landlord_house_number
                          : "Hausnummer fehlt"}
                      </span>
                      <br />
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_plz
                          ? protocolToShow?.landlord?.address?.landlord_plz
                          : "PLZ fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_city
                          ? protocolToShow?.landlord?.address?.landlord_city
                          : "Ort fehlt"}
                      </span>
                    </div>
                    <div style={{ width: "50%" }} className="mb-2">
                      {(protocolToShow?.landlord?.landlord_type == null ||
                        protocolToShow?.landlord?.landlord_type == "") && (
                          <span>Angabe fehlt</span>
                        )}
                      {protocolToShow?.landlord?.landlord_type == "noChoice" && (
                        <span style={{ color: "#000000" }}>Keine Angabe</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "company" && (
                        <span style={{ color: "#000000" }}>Gesellschaft</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "private" && (
                        <span style={{ color: "#000000" }}>Privat</span>
                      )}
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.landlord?.landlord_email &&
                      <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                    }
                    {protocolToShow?.landlord?.landlord_phone &&
                      <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                    }
                  </div>
                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                    {protocolToShow?.landlord?.landlord_email &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_email
                            ? protocolToShow?.landlord?.landlord_email
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    }
                    {protocolToShow?.landlord?.landlord_phone &&
                      <div style={{ width: "50%" }}>
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_phone
                            ? protocolToShow?.landlord?.landlord_phone
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <hr className="hrOvwerview" />
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "landlord"
                  ) &&
                  handleDisplayPatternSection("landlord")}
              </div>

              {protocolToShow && protocolToShow.note_taker &&
                <div className="aftercardOvwerview pt-0 pb-0">
                  <div className="formAbschlussOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span className="uppercase">Protokollant</span>
                    </div>

                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "50%" }}>
                        <span>
                          {protocolToShow?.note_taker?.note_taker_gender == null &&
                            "Anrede fehlt "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "male" && "Herr "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "female" && "Frau "}

                          {protocolToShow?.note_taker?.note_taker_first_name == null &&
                            protocolToShow?.note_taker?.note_taker_last_name ==
                            null && <span>Name und Vorname fehlt</span>}
                          {(protocolToShow?.note_taker?.note_taker_first_name == null ||
                            protocolToShow?.note_taker?.note_taker_first_name == "") &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_first_name
                                  ? protocolToShow?.note_taker?.note_taker_first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name != null &&
                            (protocolToShow?.note_taker?.note_taker_last_name == null ||
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              "") && (
                              <>
                                {protocolToShow?.note_taker?.landlord_last_name
                                  ? protocolToShow?.note_taker?.landlord_last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name != null &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_first_name}{" "}
                                {protocolToShow?.note_taker?.note_taker_last_name}
                              </>
                            )}
                        </span>

                      </div>

                    </div>

                  </div>
                  <hr className="hrOvwerview" />
                </div>
              }
              <div>
                <div className="aftercardOvwerview pt-0 pb-0">
                  <div className="formAbschlussOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span className="uppercase">Mieter</span>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ fontWeight: "bold", color: "#000000" }}>
                        {protocolToShow?.renter?.renter_salutation == null &&
                          "Anrede fehlt "}
                        {protocolToShow?.renter?.renter_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.renter?.renter_salutation == "male" &&
                          "Herr "}
                        {protocolToShow?.renter?.renter_salutation == "female" &&
                          "Frau "}
                        {protocolToShow?.renter?.renter_title == "noChoice" &&
                          "Keine Angabe "}
                        {protocolToShow?.renter?.renter_title == "dr" && "Dr. "}
                        {protocolToShow?.renter?.renter_title == "prof" &&
                          "Prof. "}
                        {protocolToShow?.renter?.renter_title == "ing" && "Ing. "}
                        {protocolToShow?.renter?.renter_first_name == null &&
                          protocolToShow?.renter?.renter_last_name == null && (
                            <span>Name und Vorname fehlt</span>
                          )}
                        {(protocolToShow?.renter?.renter_first_name == null ||
                          protocolToShow?.renter?.renter_first_name == "") &&
                          protocolToShow?.renter?.renter_last_name != null && (
                            <>
                              {protocolToShow?.renter?.renter_first_name
                                ? protocolToShow?.renter?.renter_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.renter?.renter_first_name != null &&
                          (protocolToShow?.renter?.renter_last_name == null ||
                            protocolToShow?.renter?.renter_last_name == "") && (
                            <>
                              {protocolToShow?.renter?.renter_last_name
                                ? protocolToShow?.renter?.renter_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.renter?.renter_first_name != null &&
                          protocolToShow?.renter?.renter_last_name != null && (
                            <>
                              {protocolToShow?.renter?.renter_first_name}{" "}
                              {protocolToShow?.renter?.renter_last_name}
                            </>
                          )}
                      </span>
                      {protocolToShow?.renter?.renter_date_of_birth &&
                        <span className="uppercase" style={{ fontSize: "16px" }}>Geburtsdatum</span>
                      }
                    </div>
                    <div class="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "50%" }} className="mb-2"></div>
                      {protocolToShow?.renter?.renter_date_of_birth &&
                        <div style={{ width: "50%" }} className="mb-2">
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span style={{ color: "#000000" }}>
                              {protocolToShow?.renter?.renter_date_of_birth
                                ? protocolToShow?.renter?.renter_date_of_birth
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      {protocolToShow?.renter?.renter_email &&
                        <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                      }
                      {protocolToShow?.renter?.renter_phone &&
                        <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                      }
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      {protocolToShow?.renter?.renter_email &&
                        <div style={{ width: "50%" }}>
                          <span>
                            {" "}
                            {protocolToShow?.renter?.renter_email
                              ? protocolToShow?.renter?.renter_email
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      }
                      {protocolToShow?.renter?.renter_phone &&
                        <div style={{ width: "50%" }}>
                          <span>
                            {" "}
                            {protocolToShow?.renter?.renter_phone
                              ? protocolToShow?.renter?.renter_phone
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>

                {protocolToShow?.renter?.address?.renter_previous_new_adress && (
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Vorherige Adresse</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ color: "#000000" }}>
                          {protocolToShow?.renter?.address?.renter_srteet
                            ? protocolToShow?.renter?.address?.renter_srteet
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.renter?.address?.renter_house_number
                            ? protocolToShow?.renter?.address?.renter_house_number
                            : "Hausnummer  fehlt"}
                        </span>
                        {protocolToShow?.renter?.address?.renter_address_note &&
                          <span className="uppercase" style={{ fontSize: "16px" }}>Adresszusatz</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }} className="mb-2">
                          <span>
                            {protocolToShow?.renter?.address?.renter_plz
                              ? protocolToShow?.renter?.address?.renter_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.renter?.address?.renter_city
                              ? protocolToShow?.renter?.address?.renter_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                        {protocolToShow?.renter?.address?.renter_address_note &&
                          <div style={{ width: "50%" }} className="mb-2">
                            <span style={{ fontWeight: "bold" }}>
                              {protocolToShow?.renter?.address?.renter_address_note
                                ? protocolToShow?.renter?.address
                                  ?.renter_address_note
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                )}
                {protocolToShow?.has_corenter && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Weitere Mieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontWeight: "bold", color: "#000000" }}>
                            {protocolToShow?.coRenter?.salutaion == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.coRenter?.salutaion == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.coRenter?.salutaion == "male" &&
                              "Herr "}
                            {protocolToShow?.coRenter?.salutaion == "female" &&
                              "Frau "}
                            {protocolToShow?.coRenter?.title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.coRenter?.title == "dr" && "Dr. "}
                            {protocolToShow?.coRenter?.title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.coRenter?.title == "ing" && "Ing. "}
                            {(protocolToShow?.coRenter?.first_name == null ||
                              protocolToShow?.coRenter?.first_name == "") &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name
                                    ? protocolToShow?.coRenter?.first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              (protocolToShow?.coRenter?.last_name == null ||
                                protocolToShow?.coRenter?.last_name == "") && (
                                <>
                                  {protocolToShow?.coRenter?.last_name
                                    ? protocolToShow?.coRenter?.last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name}{" "}
                                  {protocolToShow?.coRenter?.last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  </>
                )}
                {protocolToShow?.roommates &&
                  protocolToShow?.roommates.length > 0 && (
                    <>
                      {protocolToShow?.roommates.map((element, index) => {
                        return (
                          <div className="aftercardOvwerview pt-0 pb-0">
                            <div className="formAbschlussOvwerview">
                              <div className="LigneWohnungsdatenOvwerview">
                                <span className="uppercase">{index + 1}. Bewohner</span>
                              </div>
                              <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                <span
                                  style={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  {element.first_name} {element.last_name}
                                </span>
                              </div>
                            </div>
                            <hr className="hrOvwerview" />
                          </div>
                        );
                      })}
                    </>
                  )}
                {protocolToShow?.protocol_type == "moveInAndExit" && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Vormieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontWeight: "bold", color: "#000000" }} className="mb-2">
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "male" && "Herr "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "female" && "Frau "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "dr" && "Dr. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "prof" && "Prof. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "ing" && "Ing. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_first_name
                              ? protocolToShow?.previous_renter
                                ?.previous_renter_first_name + " "
                              : "Vorname fehlt"}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_last_name
                              ? protocolToShow?.previous_renter
                                ?.previous_renter_last_name
                              : "Nachname fehlt"}
                          </span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          {protocolToShow?.previous_renter
                            ?.previous_renter_email &&
                            <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                          }
                          {protocolToShow?.previous_renter
                            ?.previous_renter_phone_number &&
                            <span className="uppercase" style={{ fontSize: "16px" }}>Telefonnummer:</span>
                          }
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          {protocolToShow?.previous_renter
                            ?.previous_renter_email &&
                            <div style={{ width: "50%" }} >
                              <span>
                                {protocolToShow?.previous_renter
                                  ?.previous_renter_email
                                  ? protocolToShow?.previous_renter
                                    ?.previous_renter_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          }
                          {protocolToShow?.previous_renter
                            ?.previous_renter_phone_number &&
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.previous_renter
                                  ?.previous_renter_phone_number
                                  ? protocolToShow?.previous_renter
                                    ?.previous_renter_phone_number
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          }
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                    {protocolToShow?.previous_renter?.address
                      ?.previous_renter_new_adress && (
                        <div className="aftercardOvwerview pt-0 pb-0">
                          <div className="formAbschlussOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span className="uppercase">Verzugs-Adresse</span>
                            </div>
                            <div className="LigneAdresse-RenviernungsdatumOvwerview">
                              <span style={{ color: "#000000" }}>
                                {protocolToShow?.previous_renter.address
                                  .previous_renter_srteet
                                  ? protocolToShow?.previous_renter.address
                                    ?.previous_renter_srteet
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_housnumber
                                  ? protocolToShow?.previous_renter?.address
                                    ?.previous_renter_housnumber
                                  : "Hausnummer  fehlt"}
                              </span>
                              {protocolToShow?.previous_renter?.address
                                ?.previous_renter_address_note &&
                                <span className="uppercase" style={{ fontSize: "16px" }}>Adresszusatz</span>
                              }
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "50%" }}>
                                <span>
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_plz
                                    ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_plz
                                    : "PLZ fehlt"}
                                  ,{" "}
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_city
                                    ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_city
                                    : "Ort fehlt"}
                                </span>
                              </div>
                              {protocolToShow?.previous_renter?.address
                                ?.previous_renter_address_note &&
                                <div style={{ width: "50%" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {protocolToShow?.previous_renter?.address
                                      ?.previous_renter_address_note
                                      ? protocolToShow?.previous_renter?.address
                                        ?.previous_renter_address_note
                                      : "Angabe fehlt"}
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                          <hr className="hrOvwerview" />
                        </div>
                      )}
                  </>
                )}
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "renter"
                  ) &&
                  handleDisplayPatternSection("renter")}
              </div>
            </div>
          </div>
          <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="neuer">
                <img className="NeuerIcon" src={RaumeIcon} alt="Räume Icon" />
                <h3 style={{ textTransform: "uppercase" }}> Räume und Zimmer </h3>
              </div>

              {protocolToShow?.protocol_rooms &&
                protocolToShow?.protocol_rooms.length > 0 ? (
                <>
                  {protocolToShow?.protocol_rooms.map((room, index) => (
                    <>
                      <div
                        className="VerteilerContainer"
                        style={{ backgroundColor: "rgba(0, 159, 227,0.10)", paddingLeft: "20px" }}
                      >
                        <div className={
                          (protocolToShow.protocol_rooms[index].room_image &&
                            protocolToShow.protocol_rooms[index].room_image.length >
                            0) ||
                            protocolToShow.protocol_rooms[index].room_status == 1
                            ? "formAbschlussOvwerview"
                            : "formAbschlussOvwerview-withImages"
                        }
                          style={{ backgroundColor: "transparent" }}>
                          <div
                            className={
                              protocolToShow.protocol_rooms[index].room_image &&
                                protocolToShow.protocol_rooms[index].room_image.length > 3
                                ? "row w-full"
                                : "room-deffect-contain "
                            }
                          >
                            <div
                              className={
                                protocolToShow.protocol_rooms[index].room_image &&
                                  protocolToShow.protocol_rooms[index].room_image.length >
                                  3
                                  ? "col-md-6"
                                  : "first-part-formverteilerOvwerview row "
                              }
                            >
                              <div className="LigneWohnungsdatenOvwerview">
                                <span>
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "livingRoom" && (
                                      <>Wohnzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bedroom" && <>Schlafzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "kitchen" && <>Küche</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bathroom" && <>Badezimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "basement" && <>Keller</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "corridor" && <>Flur</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbedroom" && (
                                      <>Zweites Schlafzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbathroom" && (
                                      <>Zweites Badezimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "attic" && <>Dachboden</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garage" && <>Garage</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "balcony" && <>Balkon</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "shed" && <>Schuppen</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garden" && <>Garten</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "nursery" && <>Kinderzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "other" && (
                                      <>
                                        {
                                          protocolToShow?.protocol_rooms[index]
                                            ?.room_name
                                        }
                                      </>
                                    )}
                                </span>
                              </div>
                              <div className="LigneZustandPROTOKOLLTYPE">
                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == null && (
                                    <>
                                      <div className="LigneRaumeundZimmerAddIcon">
                                        <MinimizeOutlinedIcon
                                          sx={{
                                            fontSize: "2rem",
                                            paddingBottom: "12px",
                                            color: "rgb(141 133 133)",
                                          }}
                                        />

                                        <span
                                          style={{
                                            color: "rgb(141 133 133)",
                                            fontSize: "1.3rem",
                                          }}
                                        >
                                          Zustand: Angabe fehlt
                                        </span>
                                      </div>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 0 && (
                                    <>
                                      <CheckIcon
                                        className="icon-AngabeButton"
                                        sx={{ fontSize: 40, color: "#62AB12" }}
                                      />
                                      <span
                                        style={{
                                          color: "#62AB12",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: IN ORDNUNG
                                      </span>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 1 && (
                                    <>
                                      <CloseIcon
                                        className="icon"
                                        sx={{ fontSize: 25 }}
                                      />

                                      <span
                                        style={{
                                          color: "#D00000",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: Mangel vorhanden
                                      </span>
                                    </>
                                  )}
                              </div>
                              <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                <div style={{ width: "100%" }}>
                                  <span>
                                    {protocolToShow?.protocol_rooms[index]
                                      ?.room_generale_description != null
                                      ? protocolToShow.protocol_rooms[index]
                                        ?.room_generale_description
                                      : "KEIN KOMMENTAR"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                protocolToShow.protocol_rooms[index].room_image &&
                                  protocolToShow.protocol_rooms[index].room_image.length >
                                  2
                                  ? "col-md-6 mt-3 w-100"
                                  : "image-grid grid-counter "
                              }
                            >
                              {protocolToShow.protocol_rooms[index].room_image &&
                                protocolToShow.protocol_rooms[index].room_image.length >
                                0 ? (
                                protocolToShow.protocol_rooms[index].room_image.length >
                                  2 ? (
                                  // CurrentPageByRoom[index]*imagesPerPage ,
                                  // CurrentPageByRoom[index]*imagesPerPage+imagesPerPage
                                  <>
                                    {protocolToShow.protocol_rooms[index].room_image &&
                                      protocolToShow.protocol_rooms[index].room_image
                                        .length > 0
                                      ? protocolToShow.protocol_rooms[index].room_image
                                        .slice(CurrentPageByRoom[index] * imagesPerPage, CurrentPageByRoom[index] * imagesPerPage + imagesPerPage)
                                        ?.filter(
                                          (Rimage) => Rimage?.deffect_id == null
                                        )
                                        .reduce((chunks, item, i, arr) => {
                                          if (i % 3 === 0) {
                                            chunks.push(arr.slice(i, i + 3));
                                          }
                                          return chunks;
                                        }, [])
                                        .map((imageSet, setIndex) => (
                                          <div className="row">
                                            {imageSet.map((Rimage, rimg) => (
                                              <div
                                                className="col-md-4"
                                                style={{
                                                  //display: "flex",
                                                  //justifyContent: "center",
                                                  marginBottom: "30px",
                                                }}
                                              >
                                                <div
                                                  className="image--container image-right"
                                                  key={rimg}
                                                >
                                                  {showImage &&
                                                    Rimage?.id ==
                                                    idImageShow && (
                                                      <div
                                                        className="image-overlay"
                                                        onClick={() =>
                                                          toggleImage(
                                                            Rimage?.id
                                                          )
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            classes.imageContainer
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              Rimage?.room_image_remote_url
                                                            }
                                                            alt={
                                                              Rimage?.room_image_name
                                                            }
                                                            className="image"
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  {!showImage && !comment && (
                                                    <div
                                                      className="icon overview"
                                                      onClick={() =>
                                                        toggleImage(Rimage?.id)
                                                      }
                                                    >
                                                      <i className="fas fa-search-plus"></i>
                                                    </div>
                                                  )}
                                                  {(!comment ||
                                                    selectedId !=
                                                    Rimage.id) && (
                                                      <div
                                                        className="icon-comment overview"
                                                        onClick={() => {
                                                          setComment(!comment);
                                                          setSelectedId(
                                                            Rimage?.id
                                                          );
                                                        }}
                                                      >
                                                        <Comment
                                                          sx={{
                                                            fontSize: "1rem",
                                                            //color: "rgb(141 133 133)",
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  {comment &&
                                                    selectedId == Rimage.id && (
                                                      <div
                                                        className="icon-comment overview"
                                                        onClick={() => {
                                                          setComment(!comment);
                                                          setSelectedId(
                                                            Rimage?.id
                                                          );
                                                        }}
                                                      >
                                                        <HideComment
                                                          sx={{
                                                            fontSize: "1rem",
                                                            //color: "rgb(141 133 133)",
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  <img
                                                    src={
                                                      Rimage?.room_image_remote_url
                                                    }
                                                    alt={
                                                      Rimage?.room_image_name
                                                    }
                                                    className={`w-full ${comment &&
                                                      selectedId == Rimage.id
                                                      ? "d-none"
                                                      : ""
                                                      } `}
                                                    style={{
                                                      height: "200px",
                                                    }}
                                                  />
                                                  <div
                                                    className={`description-contain w-100 ${comment &&
                                                      selectedId == Rimage.id
                                                      ? ""
                                                      : "d-none"
                                                      }`}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 1)",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {
                                                        Rimage?.image_description
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        ))
                                      : null}

                                    {/* Pagination controls */}
                                    {protocolToShow.protocol_rooms[index].room_image && protocolToShow.protocol_rooms[index].room_image.length > 3 && (
                                      <div className="pagination pt-2 pb-2 justify-content-center" style={{ marginTop: "-30px" }}>
                                        {(CurrentPageByRoom[index] > 0) && (
                                          <div className="icon overview" onClick={() => {
                                            paginate(currentPage - 1, index)
                                          }}>
                                            <Previous sx={{ fontSize: "2rem", color: "#009fe3", cursor: "pointer" }} />
                                          </div>
                                        )}
                                        {CurrentPageByRoom[index] < pagesByRoom[index] - 1 && (
                                          <div className="icon overview" onClick={() => {
                                            paginate(currentPage + 1, index)
                                          }}>
                                            <Next sx={{ fontSize: "2rem", color: "#009fe3", cursor: "pointer" }} />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {protocolToShow.protocol_rooms[
                                      index
                                    ]?.room_image?.map(
                                      (Rimage, rimg) =>
                                        protocolToShow?.protocol_rooms[index]
                                          ?.room_image[rimg]?.deffect_id ==
                                        null && (
                                          <div
                                            className="image--container image-right"
                                            style={{ flexDirection: "column" }}
                                            key={rimg}
                                          >
                                            {showImage &&
                                              protocolToShow?.protocol_rooms[index]
                                                ?.room_image[rimg]?.id ==
                                              idImageShow && (
                                                <div
                                                  className="image-overlay"
                                                  onClick={() =>
                                                    toggleImage(
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]?.id
                                                    )
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      classes.imageContainer
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        protocolToShow?.protocol_rooms[
                                                          index
                                                        ]?.room_image[rimg]
                                                          ?.room_image_remote_url
                                                      }
                                                      alt={
                                                        protocolToShow?.protocol_rooms[
                                                          index
                                                        ]?.room_image[rimg]
                                                          ?.room_image_name
                                                      }
                                                      className="image"
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            {!showImage && (
                                              <div
                                                className="icon overview"
                                                onClick={() =>
                                                  toggleImage(
                                                    protocolToShow?.protocol_rooms[
                                                      index
                                                    ]?.room_image[rimg]?.id
                                                  )
                                                }
                                              >
                                                {/* Add your icon here */}
                                                <i className="fas fa-search-plus"></i>
                                              </div>
                                            )}
                                            <img
                                              src={
                                                protocolToShow?.protocol_rooms[index]
                                                  ?.room_image[rimg]
                                                  ?.room_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_rooms[index]
                                                  ?.room_image[rimg]
                                                  ?.room_image_name
                                              }
                                            />
                                            {protocolToShow?.protocol_rooms[index]
                                              ?.room_image[rimg]
                                              ?.image_description && (
                                                <div className="description-contain">
                                                  <span
                                                    style={{
                                                      //backgroundColor: "#fff",
                                                      color: "rgba(0, 0, 0, 1)",
                                                    }}
                                                  >
                                                    {
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]
                                                        ?.image_description
                                                    }
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        )
                                    )}
                                  </>
                                )
                              ) : (
                                <div className="second-part-formverteilerOvwerview">
                                  {/* <PhotoLibraryIcon
                                  className="defaultImageIcon"
                                  sx={{ width: 425, height: 200 }}
                                /> */}
                                </div>
                              )}
                            </div>
                          </div>
                          {protocolToShow.protocol_rooms[index].room_deffect &&
                            protocolToShow.protocol_rooms[index].room_deffect
                              .length > 0 ? (
                            <>
                              {protocolToShow.protocol_rooms[
                                index
                              ].room_deffect.map((deffect, i) => (
                                <>
                                  {protocolToShow.protocol_rooms[index]
                                    .room_status == 1 && (
                                      <div
                                        className="sub-formAbschlussOvwerview"
                                        style={{ backgroundColor: "#fff", width: "100%", marginLeft: "-10px", paddingBottom: "40px" }}
                                      >
                                        <div className="sub-formverteilerOvwerview">
                                          <div className="first-part-formverteilerOvwerview w-100 pt-4 pb-4">
                                            <div className="LigneZustandPROTOKOLLTYPE">
                                              <img
                                                className="notvalidateSclusselOvwerviewIcon"
                                                src={NotValidIcon}
                                                alt="notvalidateSclusselOvwerview Icon"
                                              />
                                              <h2
                                                style={{
                                                  fontSize: "1.3rem",
                                                  textTransform: "uppercase",
                                                  marginBottom: "0px",
                                                }}
                                              >
                                                Mangel {i + 1}
                                              </h2>
                                            </div>
                                            <div className="row w-100">
                                              <div className="col-md-6">
                                                <div className="row" hidden={protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .deffect_type == null || protocolToShow.protocol_rooms[
                                                    index
                                                  ].room_deffect[i]
                                                    .deffect_type == ""}>
                                                  <div className="col-md-2">
                                                    <MeetingRoomIcon
                                                      className="BeschreibungIcon"
                                                      sx={{
                                                        fontSize: 33,
                                                        color: "#009fe3",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-10 mangel-deffect">
                                                    <span className="deffect-Label">
                                                      Objekt
                                                    </span>
                                                    <span className="deffect-value">
                                                      {protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_type != null
                                                        ? protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .deffect_type
                                                        : "keine angabe"}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="row" hidden={protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .deffect_name == null || protocolToShow.protocol_rooms[
                                                    index
                                                  ].room_deffect[i]
                                                    .deffect_name == ""}>
                                                  <div className="col-md-1 ">
                                                    <Direction
                                                      className="BeschreibungIcon pl-2"
                                                      sx={{
                                                        fontSize: 33,
                                                        color: "rgba(0,0,0,0.3)",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-2 d-flex align-items-center">
                                                    <img
                                                      className="PenIcon"
                                                      src={damage}
                                                      alt="Baskets Icon"
                                                    />
                                                  </div>
                                                  <div className="col-md-9 mangel-deffect">
                                                    <span className="deffect-Label">
                                                      Art des Mangels
                                                    </span>
                                                    <span className="deffect-value">
                                                      {protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_name != null
                                                        ? protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .deffect_name
                                                        : "keine angabe"}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="col-md-6 pt-4 pb-2"
                                                style={{
                                                  backgroundColor: "#f3f3f3",
                                                }}
                                                hidden={protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .room_deffect_who_fix ==
                                                  null || protocolToShow.protocol_rooms[
                                                    index
                                                  ].room_deffect[i]
                                                    .room_deffect_who_fix ==
                                                  ""}
                                              >
                                                <div className="row" >
                                                  <div className="col-md-6 d-flex">
                                                    <Config
                                                      className="BeschreibungIcon mr-3"
                                                      sx={{
                                                        fontSize: 33,
                                                        color: "#009fe3",
                                                      }}
                                                    />
                                                    <div className="mangel-deffect">
                                                      <span className="deffect-Label">
                                                        Maßnahme
                                                      </span>
                                                      <span className="deffect-value">
                                                        {protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .room_deffect_who_fix !=
                                                          null
                                                          ? protocolToShow
                                                            .protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .room_deffect_who_fix
                                                          : "keine angabe"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 d-flex">
                                                    <DateIcon
                                                      className="BeschreibungIcon mr-3"
                                                      sx={{
                                                        fontSize: 33,
                                                        color: "#009fe3",
                                                      }}
                                                    />
                                                    <div className="mangel-deffect">
                                                      <span className="deffect-Label">
                                                        Zu erledigen bis
                                                      </span>
                                                      <span className="deffect-value">
                                                        {protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .room_deffect_date_of_fix
                                                          ? protocolToShow.protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .room_deffect_date_of_fix
                                                          : "Angabe fehlt"}

                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                          <div
                                            className={
                                              protocolToShow.protocol_rooms[
                                                index
                                              ]?.room_image?.filter(
                                                (Rimage) =>
                                                  Rimage?.deffect_id !== null &&
                                                  Rimage?.deffect_id ==
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_deffect[i]?.id &&
                                                  Rimage?.room_image_remote_url !=
                                                  null
                                              ).length > 3
                                                ? " w-100 d-flex justify-content-center"
                                                : "image-grid grid-counter w-100 pr-3"
                                            }
                                          >
                                            {protocolToShow.protocol_rooms[
                                              index
                                            ].room_image?.find(
                                              (roomImage) =>
                                                roomImage.deffect_id ===
                                                protocolToShow.protocol_rooms[index]
                                                  .room_deffect[i].id
                                            ) ? (
                                              protocolToShow.protocol_rooms[
                                                index
                                              ]?.room_image?.filter(
                                                (Rimage) =>
                                                  Rimage?.deffect_id !== null &&
                                                  Rimage?.deffect_id ==
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_deffect[i]?.id &&
                                                  Rimage?.room_image_remote_url !=
                                                  null
                                              ).length > 3 ? (
                                                <>
                                                  <Carousel
                                                    activeIndex={
                                                      activeIndexMangel
                                                    }
                                                    next={() =>
                                                      next(index, "mangel")
                                                    }
                                                    previous={() =>
                                                      previous(index, "mangel")
                                                    }
                                                    slide={false}
                                                    ride="carousel"
                                                    interval={false}
                                                    onMouseDown={() => {
                                                      setClickedOnBullet(false); // Update clickedOnBullet state
                                                    }}
                                                    onTouchStart={() => {
                                                      setClickedOnBullet(false); // Update clickedOnBullet state
                                                    }}
                                                    onAnimationStart={
                                                      handleExiting
                                                    }
                                                  >
                                                    {protocolToShow.protocol_rooms[
                                                      index
                                                    ]?.room_image &&
                                                      protocolToShow.protocol_rooms[index]
                                                        ?.room_image?.length > 0
                                                      ? protocolToShow.protocol_rooms[
                                                        index
                                                      ]?.room_image
                                                        ?.filter(
                                                          (Rimage) =>
                                                            Rimage?.deffect_id !==
                                                            null &&
                                                            Rimage?.deffect_id ==
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_deffect[i]
                                                              ?.id &&
                                                            Rimage?.room_image_remote_url !=
                                                            null
                                                        )
                                                        .reduce(
                                                          (
                                                            chunks,
                                                            item,
                                                            i,
                                                            arr
                                                          ) => {
                                                            if (i % 3 === 0) {
                                                              chunks.push(
                                                                arr.slice(
                                                                  i,
                                                                  i + 3
                                                                )
                                                              );
                                                            }
                                                            return chunks;
                                                          },
                                                          []
                                                        )
                                                        .map(
                                                          (
                                                            imageSet,
                                                            setIndex
                                                          ) =>
                                                            imageSet.length >
                                                            0 && (
                                                              <CarouselItem
                                                                key={setIndex}
                                                              >
                                                                <div className="row">
                                                                  {imageSet.map(
                                                                    (
                                                                      Rimage,
                                                                      rimg
                                                                    ) => (
                                                                      <div
                                                                        className="col-md-4 "
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          justifyContent:
                                                                            "center",
                                                                        }}
                                                                        key={
                                                                          rimg
                                                                        }
                                                                      >
                                                                        <div className="image--container image-right">
                                                                          {showImage &&
                                                                            Rimage?.id ==
                                                                            idImageShow && (
                                                                              <div
                                                                                className="image-overlay"
                                                                                onClick={() =>
                                                                                  toggleImage(
                                                                                    protocolToShow
                                                                                      ?.protocol_rooms[
                                                                                      index
                                                                                    ]
                                                                                      ?.room_image[
                                                                                      rimg
                                                                                    ]
                                                                                      ?.id
                                                                                  )
                                                                                }
                                                                              >
                                                                                <div
                                                                                  className={
                                                                                    classes.imageContainer
                                                                                  }
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      protocolToShow
                                                                                        ?.protocol_rooms[
                                                                                        index
                                                                                      ]
                                                                                        ?.room_image[
                                                                                        rimg
                                                                                      ]
                                                                                        ?.room_image_remote_url
                                                                                    }
                                                                                    alt={
                                                                                      protocolToShow
                                                                                        ?.protocol_rooms[
                                                                                        index
                                                                                      ]
                                                                                        ?.room_image[
                                                                                        rimg
                                                                                      ]
                                                                                        ?.room_image_name
                                                                                    }
                                                                                    className="image"
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                            )}
                                                                          {!showImage && (
                                                                            <div
                                                                              className="icon overview"
                                                                              onClick={() =>
                                                                                toggleImage(
                                                                                  Rimage?.id
                                                                                )
                                                                              }
                                                                            >
                                                                              {/* Add your icon here */}
                                                                              <i className="fas fa-search-plus"></i>
                                                                            </div>
                                                                          )}
                                                                          <div className="defect-contain">
                                                                            <img
                                                                              src={
                                                                                Rimage?.room_image_remote_url
                                                                              }
                                                                              alt={
                                                                                Rimage?.room_image_name
                                                                              }
                                                                              className="w-full"
                                                                              style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                objectFit:
                                                                                  "cover",
                                                                              }}
                                                                            />
                                                                          </div>
                                                                          {protocolToShow
                                                                            ?.protocol_rooms[
                                                                            index
                                                                          ]
                                                                            ?.room_image[
                                                                            rimg
                                                                          ]
                                                                            ?.image_description && (
                                                                              <div className="description-contain deffect">
                                                                                <span
                                                                                  style={{
                                                                                    backgroundColor:
                                                                                      "#fff",
                                                                                    color:
                                                                                      "rgba(0, 0, 0, 1)",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    protocolToShow
                                                                                      ?.protocol_rooms[
                                                                                      index
                                                                                    ]
                                                                                      ?.room_image[
                                                                                      rimg
                                                                                    ]
                                                                                      ?.image_description
                                                                                  }
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </CarouselItem>
                                                            )
                                                        )
                                                      : null}
                                                    <CarouselIndicators
                                                      style={{ bottom: "-30px" }}
                                                      items={protocolToShow.protocol_rooms[
                                                        index
                                                      ]?.room_image
                                                        ?.filter(
                                                          (Rimage) =>
                                                            Rimage?.deffect_id !==
                                                            null &&
                                                            Rimage?.deffect_id ==
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_deffect[i]
                                                              ?.id &&
                                                            Rimage?.room_image_remote_url !=
                                                            null
                                                        )
                                                        .reduce(
                                                          (
                                                            chunks,
                                                            item,
                                                            i,
                                                            arr
                                                          ) => {
                                                            if (i % 3 === 0) {
                                                              chunks.push(
                                                                arr.slice(
                                                                  i,
                                                                  i + 3
                                                                )
                                                              );
                                                            }
                                                            return chunks;
                                                          },
                                                          []
                                                        )}
                                                      activeIndex={
                                                        activeIndexMangel
                                                      }
                                                      onClickHandler={
                                                        goToIndexMangel
                                                      }
                                                    />
                                                  </Carousel>
                                                </>
                                              ) : (
                                                protocolToShow.protocol_rooms[
                                                  index
                                                ]?.room_image?.map(
                                                  (Rimage, rimg) => {
                                                    if (
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]
                                                        ?.deffect_id != null &&
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]
                                                        ?.deffect_id ==
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_deffect[i]?.id &&
                                                      protocolToShow?.protocol_rooms[
                                                        index
                                                      ]?.room_image[rimg]
                                                        ?.room_image_remote_url !=
                                                      null
                                                    ) {
                                                      return (
                                                        <div
                                                          className="image--container image-right room-deffect-contain"
                                                          style={{
                                                            flexDirection:
                                                              "column",
                                                          }}
                                                          key={rimg}
                                                        >
                                                          {showImage &&
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.id ==
                                                            idImageShow && (
                                                              <div
                                                                className="image-overlay"
                                                                onClick={() =>
                                                                  toggleImage(
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[
                                                                      rimg
                                                                    ]?.id
                                                                  )
                                                                }
                                                              >
                                                                <img
                                                                  src={
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[
                                                                      rimg
                                                                    ]
                                                                      ?.room_image_remote_url
                                                                  }
                                                                  alt={
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[
                                                                      rimg
                                                                    ]
                                                                      ?.room_image_name
                                                                  }
                                                                  className="image"
                                                                />
                                                              </div>
                                                            )}
                                                          {!showImage && (
                                                            <div
                                                              className="icon overview"
                                                              onClick={() =>
                                                                toggleImage(
                                                                  protocolToShow
                                                                    ?.protocol_rooms[
                                                                    index
                                                                  ]?.room_image[
                                                                    rimg
                                                                  ]?.id
                                                                )
                                                              }
                                                            >
                                                              <i className="fas fa-search-plus"></i>
                                                            </div>
                                                          )}
                                                          <div className="defect-contain">
                                                            <img
                                                              src={
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_image[rimg]
                                                                  ?.room_image_remote_url
                                                              }
                                                              alt={
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_image[rimg]
                                                                  ?.room_image_name
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          {protocolToShow
                                                            ?.protocol_rooms[
                                                            index
                                                          ]?.room_image[rimg]
                                                            ?.image_description && (
                                                              <div className="description-contain deffect">
                                                                <span
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#fff",
                                                                    color:
                                                                      "rgba(0, 0, 0, 1)",
                                                                  }}
                                                                >
                                                                  {
                                                                    protocolToShow
                                                                      ?.protocol_rooms[
                                                                      index
                                                                    ]?.room_image[
                                                                      rimg
                                                                    ]
                                                                      ?.image_description
                                                                  }
                                                                </span>
                                                              </div>
                                                            )}
                                                        </div>
                                                      );
                                                    }
                                                  }
                                                )
                                              )
                                            ) : (
                                              <div className="second-part-formverteilerOvwerview">
                                                <PhotoLibraryIcon
                                                  className="defaultImageIcon"
                                                  sx={{ width: 425, height: 220 }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}{" "}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find(
                  (key) => key.key_section_name === "rooms"
                ) &&
                handleDisplayPatternSection("rooms")}
            </div>
          </div>

          <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="neuer">
                <img
                  className="NeuerIcon"
                  src={VerteilerIcon}
                  alt="Zähler Icon"
                />
                <h3 style={{ textTransform: "none" }}> Zähler / Verteiler </h3>
              </div>
              {protocolToShow?.protocol_counter &&
                protocolToShow?.protocol_counter.length > 0 ? (
                <>
                  {protocolToShow?.protocol_counter.map((counter, j) => (
                    <>
                      <div className="VerteilerContainer">
                        <div className="formAbschlussOvwerview">
                          <div className="sub-formverteilerOvwerview">
                            <div className="first-part-formverteilerOvwerview">
                              <div className="LigneWohnungsdatenOvwerview">
                                <span style={{ textTransform: "uppercase", fontSize: "16px" }}>
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "electricity" &&
                                    (protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double == "doubleCounter" ? (
                                      <>Zweitarif Stromzähler</>
                                    ) : (
                                      <>Eintarif Stromzähler</>
                                    ))}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "gas" && <>GASZÄHLER</>}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "watter" && (
                                      <>Wasserzähler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "oil" && <>Heizöl</>}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "hotness" && (
                                      <>Heizkostenverteiler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "electronicHot" && (
                                      <>Elektronischer Heizkostenverteiler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "smokeDetector" && (
                                      <>Rauchmelder</>
                                    )}
                                </span>
                              </div>

                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type !== "smokeDetector" ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählernummer
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{ width: "50%", fontWeight: "bold" }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.counter_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : null}

                              {protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 !== null ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          HT (Hochtarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double == "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          NT (Niedertarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double != "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          Zählerstand
                                        </span>
                                      )}
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{
                                            width: "50%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ) : protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählerstand
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{
                                            width: "50%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_1 === null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null &&
                                null
                              )}

                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type === "smokeDetector" ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span className="titles">funktionstest</span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div style={{ width: "100%" }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == null && (
                                            <>Angabe fehlt</>
                                          )}
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == "inOrder" && (
                                            <>In ordnung</>
                                          )}
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == "defective" && (
                                            <>Mangelhaft</>
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : null}

                              {protocolToShow?.protocol_counter[j]
                                ?.market_location_number ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span>Marktlokationsnummer</span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.market_location_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.market_location_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                <span className="uppercase" style={{ fontSize: "16px" }}>Installationsort</span>
                              </div>
                              <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                <div
                                  style={{ width: "100%", fontWeight: "bold" }}
                                >
                                  <span>
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_location
                                      ? protocolToShow?.protocol_counter[j]
                                        ?.counter_location
                                      : "Angabe fehlt"}
                                  </span>
                                </div>
                              </div>
                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type === "electronicHot" ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{ width: "70%", marginTop: "15px" }}
                                    >
                                      <div className="LigneDataAngabefhltOvwerview">
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 1
                                        </span>
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 2
                                        </span>
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 3
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <div style={{ width: "70%" }}>
                                      <div className="LigneDataAngabefhltOvwerview">
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_current_consumption
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_current_consumption
                                            : "Angabe fehlt"}
                                        </span>
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_value_1
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                            : "Angabe fehlt"}
                                        </span>
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_value_2
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                            : "Angabe fehlt"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="image-grid">
                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type == "electronicHot" ? (
                                <>
                                  {protocolToShow?.protocol_counter[j]?.image
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container ">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (
                                    <div className="image-container">
                                      <PhotoLibraryIcon
                                        className="defaultImageIcon"
                                        sx={{ width: 125, height: 125 }}
                                      />
                                    </div>
                                  )}
                                  {protocolToShow?.protocol_counter[j]?.image1
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.image1?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1
                                                  ?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image1?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image1?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image1?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (
                                    <div className="image-container">
                                      <PhotoLibraryIcon
                                        className="defaultImageIcon"
                                        sx={{ width: 125, height: 125 }}
                                      />
                                    </div>
                                  )}

                                  {protocolToShow?.protocol_counter[j]?.image2
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.image2?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2
                                                  ?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image2?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image2?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image2?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (
                                    <div className="image-container">
                                      <PhotoLibraryIcon
                                        className="defaultImageIcon"
                                        sx={{ width: 125, height: 125 }}
                                      />
                                    </div>
                                  )}
                                </>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_type == "electronicHot" &&
                                protocolToShow?.protocol_counter[j]?.image
                                  ?.counter_image_remote_url == null &&
                                protocolToShow?.protocol_counter[j]?.image1
                                  ?.counter_image_remote_url == null &&
                                protocolToShow?.protocol_counter[j]?.image2
                                  ?.counter_image_remote_url == null && <></>
                              )}

                              {protocolToShow.protocol_counter[j].counter_type !==
                                "electronicHot" &&
                                protocolToShow.protocol_counter[j]?.image
                                  ?.counter_image_remote_url != null ? (
                                <div className="image--container">
                                  {showImage &&
                                    protocolToShow?.protocol_counter[j]?.image
                                      ?.id == idImageShow && (
                                      <div
                                        className="image-overlay"
                                        onClick={() =>
                                          toggleImage(
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.id
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.counter_image_remote_url
                                          }
                                          alt={
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.counter_image_name
                                          }
                                          className="image"
                                        />
                                      </div>
                                    )}
                                  {!showImage && (
                                    <div
                                      className="icon overview"
                                      onClick={() =>
                                        toggleImage(
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.id
                                        )
                                      }
                                    >
                                      {/* Add your icon here */}
                                      <i className="fas fa-search-plus"></i>
                                    </div>
                                  )}
                                  <div class="image-container">
                                    <img
                                      src={
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.counter_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.counter_image_name
                                      }
                                      className="image-object"
                                    />
                                  </div>
                                </div>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_type !== "electronicHot" &&
                                protocolToShow?.protocol_counter[j]?.image
                                  ?.counter_image_remote_url == null && (
                                  <div className="second-part-formverteilerOvwerview">
                                    <PhotoLibraryIcon
                                      className="defaultImageIcon"
                                      sx={{ width: 425, height: 200 }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find(
                  (key) => key.key_section_name === "counters"
                ) &&
                handleDisplayPatternSection("counters")}
            </div>
          </div>
          <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="neuer">
                <img className="NeuerIcon" src={keyIcon} alt="Schlüssel Icon" />
                <h3 style={{ textTransform: "uppercase" }}> Schlüssel </h3>
              </div>
              {protocolToShow?.protocol_keys &&
                protocolToShow?.protocol_keys.length > 0 ? (
                <>
                  {protocolToShow?.protocol_keys.map((key, s) => (
                    <>
                      <div className="after-cards protocol-step_border">
                        <div
                          className="col-md-12  mt-2 formAbschlussOvwerview"
                          style={{ padding: "0" }}
                        >
                          <div
                            className="row col-md-12 mt-1"
                            style={{
                              marginTop: "-0.5rem",
                              paddingRight: "0",
                              paddingLeft: "0",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <div className="col-md-4"></div>
                            <div className="row col-md-8">
                              <div className="col-md-4">
                                <p
                                  style={{
                                    textTransform: "none",
                                    textAlign: "left",
                                  }}
                                >
                                  Übergabe
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>Information</p>
                              </div>
                              <div
                                className="col-md-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ marginLeft: "2rem" }}>Image</p>
                              </div>

                            </div>
                          </div>
                          <div
                            className="row col-md-12"
                            style={{
                              marginTop: "-0.5rem",
                              paddingTop: "0",
                              paddingRight: "0",
                              paddingLeft: "0",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <div
                              className="col-md-4 mb-3"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {parseInt(
                                protocolToShow?.protocol_keys[s]?.key_number
                              ) >
                                protocolToShow?.protocol_keys[s]
                                  ?.number_keys_returned_in_main_protocol ? (
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{ marginbottom: "0rem", marginRight: "1rem" }}
                                />
                              ) : (
                                <img
                                  className="validateSclusselOvwerviewIcon"
                                  src={ValidIcon}
                                  alt="validateSclusselOvwerview Icon"
                                  style={{ marginbottom: "0rem", marginRight: "1rem" }}
                                />
                              )}
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "3px",
                                  fontSize: "1.15rem",
                                }}
                              >
                                {protocolToShow?.protocol_keys[s]?.key_number &&
                                  protocolToShow?.protocol_keys[s]?.key_number
                                    .length > 0 ? (
                                  <span>
                                    {
                                      protocolToShow?.protocol_keys[s]
                                        ?.key_number
                                    }{" "}
                                    x{" "}
                                  </span>
                                ) : (
                                  <span>K.A x </span>
                                )}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "mainEntrance" && <span> Eingangstür, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "apartmentKey" && (
                                    <span> Wohnungsschlüssel, </span>
                                  )}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "basement" && <span> Keller, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "attic" && <span> Dachboden, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "garage" && <span> Garage, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "shed" && <span> Schuppen, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "outerGate" && <span> Aussentor, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "goal" && <span> Tor, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "gazebo" && <span> Gartenlaube, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "letterBox" && <span> Briefkasten, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "frontDoor" && <span> Haustür, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "other" && (
                                    <span>
                                      {protocolToShow?.protocol_keys[s]?.key_name}{" "}
                                      ,{" "}
                                    </span>
                                  )}
                                <span
                                  style={
                                    parseInt(
                                      protocolToShow?.protocol_keys[s]
                                        ?.key_number
                                    ) >
                                      protocolToShow?.protocol_keys[s]
                                        ?.number_keys_returned_in_main_protocol
                                      ? { color: "#D00000" }
                                      : { color: "#62AB12" }
                                  }
                                >
                                  erhalten :{" "}
                                  {protocolToShow?.protocol_keys[s]
                                    ?.number_keys_returned_in_main_protocol
                                    ? protocolToShow?.protocol_keys[s]
                                      ?.number_keys_returned_in_main_protocol
                                    : "K.A"}{" "}
                                </span>
                              </p>
                            </div>
                            <div
                              className="row col-md-8"
                              style={{ paddingRight: "0.5rem" }}
                            >
                              <div
                                className="col-md-4 mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  className="CalenderSclusselOvwerviewIcon"
                                  src={CalenderIcon}
                                  alt="validateSclusselOvwerview Icon"
                                />
                                <p className="mb-0">
                                  <span></span>
                                  {protocolToShow?.protocol_keys[s]
                                    ?.key_date_of_forwarding
                                    ? protocolToShow.protocol_keys[s]
                                      ?.key_date_of_forwarding
                                    : "K.A"}
                                </p>
                              </div>
                              <div
                                className="col-md-4 mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginTop: "0",
                                    marginBottom: "0",
                                    fontSize: "1.2rem",
                                  }}
                                >
                                  <span>
                                    {protocolToShow?.protocol_keys[s]
                                      ?.key_notice
                                      ? protocolToShow?.protocol_keys[s]
                                        ?.key_notice
                                      : "Kein Kommentar"}
                                  </span>
                                </span>
                              </div>
                              <div
                                className="col-md-4 mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {protocolToShow?.protocol_keys[s].image &&
                                  protocolToShow?.protocol_keys[s]?.image
                                    ?.key_image_remote_url != null ? (
                                  <div className="image--container ">
                                    {showImage &&
                                      protocolToShow?.protocol_keys[s]?.image
                                        ?.id == idImageShow && (
                                        <div
                                          className="image-overlay"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.id
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_remote_url
                                            }
                                            alt={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_name
                                            }
                                            className="image"
                                          />
                                        </div>
                                      )}
                                    {!showImage && (
                                      <div
                                        className="icon overview"
                                        onClick={() =>
                                          toggleImage(
                                            protocolToShow?.protocol_keys[s]
                                              ?.image?.id
                                          )
                                        }
                                      >
                                        <i className="fas fa-search-plus"></i>
                                      </div>
                                    )}
                                    <img
                                      src={
                                        protocolToShow?.protocol_keys[s]?.image
                                          ?.key_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.protocol_keys[s]?.image
                                          ?.key_image_name
                                      }
                                      style={{
                                        objectFit: "cover",
                                        width: "7.8rem",
                                        height: "9.4rem",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="image-container">
                                    <PhotoLibraryIcon
                                      className="defaultImageIcon"
                                      sx={{
                                        width: "7.8rem",
                                        height: "9.4rem",
                                        marginLeft: "-4rem",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find((key) => key.key_section_name === "keys") &&
                handleDisplayPatternSection("keys")}
            </div>
          </div>

          <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="wohnungsdaten">
                <div className="first_wohnungsdaten col-md-5">
                  <div className="row padding-center">
                    <img
                      className="WohnungsdatenIcon"
                      src={ViewContractIcon}
                      alt="eigentumer Icon"
                    />
                    <h3>Wohnungsgeberbescheinigung</h3>
                  </div>
                </div>
                <div className="sub_wohnungsdaten col-md-7">
                  <div className="row  d-flex align-items-center h-100">
                    <div className="col-md-8">
                      <h3>Bescheinigung erstellen</h3>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                      <Checkbox
                        className="checkbox"
                        checked={
                          protocolToShow?.protocol_transfer_certificate == true
                        }
                        {...label}
                        sx={{
                          color: grey[800],
                          "&.Mui-checked": {
                            color: grey[600],
                          },
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                        }}
                      // onChange={() => handleCertificate()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {protocolToShow?.protocol_transfer_certificate == true && (
                <>
                  <div className="after-cards">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Adresse der Wohnung</span>
                      </div>
                      <div
                        className="LigneDataAdresse-RenviernungsdatumOvwerview"
                        style={{ marginBottom: "20px", width: "100%" }}
                      >
                        <div style={{ width: "50%" }}>
                          <span>
                            {protocolToShow?.object?.address?.object_street
                              ? protocolToShow?.object?.address?.object_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_house_nbr
                              ? protocolToShow?.object?.address?.object_house_nbr
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.object?.address?.object_plz
                              ? protocolToShow?.object?.address?.object_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_city
                              ? protocolToShow?.object?.address?.object_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                  <div className="aftercardOvwerview">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Eigentümer</span>
                      </div>
                      {protocolToShow.owner_is_landlord == true ? (
                        <>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span></span>
                            <span className="uppercase" style={{ fontSize: "16px" }}>Art des Wohnungsgebers</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }} className="mb-2">
                              <span>
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "female" && "Frau "}
                                {protocolToShow?.landlord?.landlord_title ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.landlord?.landlord_title ==
                                  "dr" && "Dr. "}
                                {protocolToShow?.landlord?.landlord_title ==
                                  "prof" && "Prof. "}
                                {protocolToShow?.landlord?.landlord_title ==
                                  "ing" && "Ing. "}
                                {protocolToShow?.landlord?.landlord_first_name ==
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name ==
                                  null && <span>Name und Vorname fehlt</span>}
                                {(protocolToShow?.landlord?.landlord_first_name ==
                                  null ||
                                  protocolToShow?.landlord?.landlord_first_name ==
                                  "") &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && <>{" Vorname fehlt "}</>}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  (protocolToShow?.landlord?.landlord_last_name ==
                                    null ||
                                    protocolToShow?.landlord
                                      ?.landlord_last_name == "") && (
                                    <>{" Nachname fehlt "}</>
                                  )}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && (
                                    <>
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_first_name
                                      }{" "}
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_last_name
                                      }
                                    </>
                                  )}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.landlord?.address
                                  ?.landlord_street
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_street
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.landlord?.address
                                  ?.landlord_house_number
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_house_number
                                  : "Hausnummer  fehlt"}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.landlord?.address?.landlord_plz
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.landlord?.address?.landlord_city
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            <div style={{ width: "50%" }} className="mb-2">
                              {(protocolToShow?.landlord?.landlord_type == null ||
                                protocolToShow?.landlord?.landlord_type ==
                                "") && <span>Angabe fehlt</span>}
                              {protocolToShow?.landlord?.landlord_type ==
                                "noChoice" && (
                                  <span style={{ color: "#000000" }}>
                                    Keine Angabe
                                  </span>
                                )}
                              {protocolToShow?.landlord?.landlord_type ==
                                "company" && (
                                  <span style={{ color: "#000000" }}>
                                    Gesellschaft
                                  </span>
                                )}
                              {protocolToShow?.landlord?.landlord_type ==
                                "private" && (
                                  <span style={{ color: "#000000" }}>Privat</span>
                                )}
                            </div>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.landlord?.landlord_email
                                  ? protocolToShow?.landlord?.landlord_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span
                              style={{ fontWeight: "bold", color: "#000000" }}
                            >
                              {protocolToShow?.owner?.owner_company_name
                                ? protocolToShow?.owner?.owner_company_name
                                : "Unternehmensname fehlt"}
                            </span>
                            <span className="uppercase" style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "female" && "Frau "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "association" && "Weg "}
                                {protocolToShow?.owner?.owner_title ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.owner?.owner_title == "dr" &&
                                  "Dr. "}
                                {protocolToShow?.owner?.owner_title == "prof" &&
                                  "Prof. "}
                                {protocolToShow?.owner?.owner_title == "ing" &&
                                  "Ing. "}
                                {protocolToShow?.owner?.owner_first_name == "" &&
                                  protocolToShow?.owner?.owner_last_name ==
                                  "" && (
                                    <span style={{ color: "#D00000" }}>
                                      Name und Vorname fehlt
                                    </span>
                                  )}
                                {(protocolToShow?.owner?.owner_first_name == "" ||
                                  protocolToShow?.owner?.owner_first_name ==
                                  "") &&
                                  protocolToShow?.owner?.owner_last_name !=
                                  "" && <>{" Vorname fehlt "}</>}
                                {protocolToShow?.owner?.owner_first_name != "" &&
                                  (protocolToShow?.owner?.owner_last_name == "" ||
                                    protocolToShow?.owner?.owner_last_name ==
                                    "") && <>{" Nachname fehlt "}</>}
                                {protocolToShow?.owner?.owner_first_name != "" &&
                                  protocolToShow?.owner?.owner_last_name !=
                                  "" && (
                                    <>
                                      {protocolToShow?.owner?.owner_first_name}{" "}
                                      {protocolToShow?.owner?.owner_last_name}
                                    </>
                                  )}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.owner?.address?.owner_street
                                  ? protocolToShow?.owner?.address?.owner_street
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.owner?.address
                                  ?.owner_house_number
                                  ? protocolToShow?.owner?.address
                                    ?.owner_house_number
                                  : "Hausnummer fehlt"}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.owner?.address?.owner_plz
                                  ? protocolToShow?.owner?.address?.owner_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.owner?.address?.owner_city
                                  ? protocolToShow?.owner?.address?.owner_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            <div style={{ width: "50%" }}>
                              {protocolToShow?.owner?.owner_Type == null && (
                                <span>Angabe fehlt</span>
                              )}
                              {protocolToShow?.owner?.owner_Type ==
                                "noChoice" && (
                                  <span style={{ color: "#000000" }}>
                                    Keine Angabe
                                  </span>
                                )}
                              {protocolToShow?.owner?.owner_Type == "company" && (
                                <span style={{ color: "#000000" }}>
                                  Gesellschaft
                                </span>
                              )}
                              {protocolToShow?.owner?.owner_Type == "private" && (
                                <span style={{ color: "#000000" }}>Privat</span>
                              )}
                            </div>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.owner?.owner_email
                                  ? protocolToShow?.owner?.owner_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  <div className="aftercardOvwerview">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Mieter</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                        <span style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}>

                          {protocolToShow?.renter?.renter_salutation == null &&
                            "Anrede fehlt "}
                          {protocolToShow?.renter?.renter_salutation ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.renter?.renter_salutation == "male" &&
                            "Herr "}
                          {protocolToShow?.renter?.renter_salutation ==
                            "female" && "Frau "}
                          {protocolToShow?.renter?.renter_title == "noChoice" &&
                            "Keine Angabe "}
                          {protocolToShow?.renter?.renter_title == "dr" && "Dr. "}
                          {protocolToShow?.renter?.renter_title == "prof" &&
                            "Prof. "}
                          {protocolToShow?.renter?.renter_title == "ing" &&
                            "Ing. "}

                          {protocolToShow?.renter?.renter_first_name == null &&
                            protocolToShow?.renter?.renter_last_name == null && (
                              <span>Name und Vorname fehlt</span>
                            )}
                          {(protocolToShow?.renter?.renter_first_name == null ||
                            protocolToShow?.renter?.renter_first_name == "") &&
                            protocolToShow?.renter?.renter_last_name != null && (
                              <>{" Vorname fehlt "}</>
                            )}
                          {protocolToShow?.renter?.renter_first_name != null &&
                            (protocolToShow?.renter?.renter_last_name == null ||
                              protocolToShow?.renter?.renter_last_name == "") && (
                              <>{" Nachname fehlt "}</>
                            )}
                          {protocolToShow?.renter?.renter_first_name != null &&
                            protocolToShow?.renter?.renter_last_name != null && (
                              <>
                                {protocolToShow?.renter?.renter_first_name}{" "}
                                {protocolToShow?.renter?.renter_last_name}
                              </>
                            )}
                        </span>
                      </div>

                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }}>
                          <span>
                            {protocolToShow?.renter?.renter_email
                              ? protocolToShow?.renter?.renter_email
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                  {protocolToShow.has_corenter && (
                    <>
                      <div className="aftercardOvwerview">
                        <div className="formAbschlussOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span className="uppercase">Weitere Mieter</span>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                            <span style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}>
                              {protocolToShow?.coRenter?.salutaion == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.coRenter?.salutaion ==
                                "noChoice" && "Keine Angabe "}
                              {protocolToShow?.coRenter?.salutaion == "male" &&
                                "Herr "}
                              {protocolToShow?.coRenter?.salutaion == "female" &&
                                "Frau "}
                              {protocolToShow?.coRenter?.title == "noChoice" &&
                                "Keine Angabe "}
                              {protocolToShow?.coRenter?.title == "dr" && "Dr. "}
                              {protocolToShow?.coRenter?.title == "prof" &&
                                "Prof. "}
                              {protocolToShow?.coRenter?.title == "ing" &&
                                "Ing. "}

                              {(protocolToShow?.coRenter?.first_name == null ||
                                protocolToShow?.coRenter?.first_name == "") &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>{" Vorname fehlt "}</>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                (protocolToShow?.coRenter?.last_name == null ||
                                  protocolToShow?.coRenter?.last_name == "") && (
                                  <>{" Nachname fehlt "}</>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name}{" "}
                                    {protocolToShow?.coRenter?.last_name}
                                  </>
                                )}
                            </span>
                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="wohnungsdaten">
                <div className="first_wohnungsdaten col-md-12 borderRadius">
                  <div className="row padding-center">
                    <img
                      className="WohnungsdatenIcon"
                      src={ViewContractIcon}
                      alt="eigentumer Icon"
                    />
                    <h3 className="uppercase">Abschlussbemerkung</h3>
                  </div>
                </div>
              </div>

              <div className="after-cards pt-0 pb-0">
                <div className="formAbschlussOvwerview">
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6 mt-1">
                      <span style={{ fontWeight: "bold", color: "#009fe3" }} className="uppercase">
                        Bemerkung :
                      </span>
                      <div className="text-style">
                        <span>
                          {protocolToShow?.genereal_notice
                            ? protocolToShow?.genereal_notice
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <span style={{ fontWeight: "bold", color: "#009fe3" }} className="uppercase">
                        Interne bemerkungen :
                      </span>
                      <div className="text-style">
                        <span>
                          {protocolToShow?.internal_notice
                            ? protocolToShow?.internal_notice
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SonCard mt-3 mb-3">
            <div className="card-shadow">
              <div
                className="neuer"
                style={{
                  background:
                    "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
                }}
              >
                <img
                  className="NeuerIcon"
                  src={ContractIcon}
                  alt="Abschlussbemerkung Icon"
                />
                <h3 className="uppercase"> Unterschriften </h3>
              </div>
              <div className="VerteilerContainer">
                <div className="formAbschlussOvwerview">
                  <div className="sub-formverteilerOvwerview">
                    <div className="first-part-formverteilerOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">

                        <span className="uppercase">Protokollant</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "100%" }}>
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              null && "Anrede fehlt "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "male" && "Herr "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "female" && "Frau "}
                          </span>
                          <br />
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.note_taker?.note_taker_first_name ==
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.note_taker?.note_taker_first_name ==
                              null ||
                              protocolToShow?.note_taker?.note_taker_first_name ==
                              "") &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name
                                    ? protocolToShow?.note_taker?.note_taker_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              (protocolToShow?.note_taker?.note_taker_last_name ==
                                null ||
                                protocolToShow?.note_taker?.note_taker_last_name ==
                                "") && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_last_name
                                    ? protocolToShow?.note_taker?.note_taker_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name}{" "}
                                  {protocolToShow?.note_taker?.note_taker_last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.landlord?.signature_landlord
                      ?.signature_landlord === true ? (
                      <>
                        <div className="second-part-formverteilerOvwerview">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                protocolToShow?.landlord?.signature_landlord
                                  ?.signature_landlord_image_remote_url
                              }
                              alt={
                                protocolToShow?.landlord?.signature_landlord
                                  ?.signature_landlord_image_name
                              }
                              height="125px"
                              width="125px"
                            />
                            <span style={{ fontSize: "700", color: "#000000" }}>
                              {protocolToShow?.landlord?.signature_landlord
                                ?.signature_landlord_time_stamp !== null
                                ? moment
                                  .unix(
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_time_stamp?.seconds
                                  )
                                  .add(
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                                : "Kein Angabe"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="second-part-formverteilerOvwerview">
                        <PhotoLibraryIcon
                          className="defaultImageIcon"
                          sx={{ width: 425, height: 200 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr className="hrOvwerview" />
              </div>

              <div className="VerteilerContainer">
                <div className="formAbschlussOvwerview">
                  <div className="sub-formverteilerOvwerview">
                    <div className="first-part-formverteilerOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        {protocolToShow?.coRenter?.signature_coRenter?.signature ?
                          <span className="uppercase">1.Mieter</span>
                          :
                          <span className="uppercase">Mieter</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "100%" }}>
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.renter?.renter_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "female" && "Frau "}
                          </span>
                          <br />
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.renter?.renter_first_name == null &&
                              protocolToShow?.renter?.renter_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.renter?.renter_first_name == null ||
                              protocolToShow?.renter?.renter_first_name == "") &&
                              protocolToShow?.renter?.renter_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.renter?.renter_first_name
                                    ? protocolToShow?.renter?.renter_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.renter?.renter_first_name != null &&
                              (protocolToShow?.renter?.renter_last_name == null ||
                                protocolToShow?.renter?.renter_last_name ==
                                "") && (
                                <>
                                  {protocolToShow?.renter?.renter_last_name
                                    ? protocolToShow?.renter?.renter_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.renter?.renter_first_name != null &&
                              protocolToShow?.renter?.renter_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.renter?.renter_first_name}{" "}
                                  {protocolToShow?.renter?.renter_last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.renter?.signature_renter
                      ?.signature_renter === true ? (
                      <>
                        <div className="second-part-formverteilerOvwerview">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_image_remote_url
                              }
                              alt={
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_image_name
                              }
                              height="125px"
                              width="125px"
                            />
                            <span style={{ fontSize: "700", color: "#000000" }}>
                              {protocolToShow?.renter?.signature_renter
                                ?.signature_renter_time_stamp !== null
                                ? moment
                                  .unix(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_time_stamp?.seconds
                                  )
                                  .add(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                                : "Kein Angabe"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      protocolToShow?.renter?.signature_renter
                        ?.signature_renter === false && (
                        <div className="second-part-formverteilerOvwerview">
                          {protocolToShow?.renter?.signature_renter
                            ?.signature_renter_refuse_reason ? (
                            <span
                              className="bad-status"
                              style={{ marginLeft: "8rem" }}
                            >
                              <img
                                className="notvalidateSclusselOvwerviewIcon"
                                src={NotValidIcon}
                                alt="notvalidateSclusselOvwerview Icon"
                                style={{
                                  width: "1rem",
                                  marginRight: "0.25rem",
                                }}
                              />
                              {formatReason(
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_refuse_reason
                              )}
                            </span>
                          ) : (
                            <span
                              className="bad-status"
                              style={{ marginLeft: "8rem" }}
                            >
                              <img
                                className="notvalidateSclusselOvwerviewIcon"
                                src={NotValidIcon}
                                alt="notvalidateSclusselOvwerview Icon"
                                style={{
                                  width: "1rem",
                                  marginRight: "0.25rem",
                                }}
                              />
                              Keine Unterschrift
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <hr className="hrOvwerview" />
              </div>

              {protocolToShow?.coRenter?.signature_coRenter?.signature ===
                false &&
                protocolToShow?.coRenter?.signature_coRenter?.refuse_signature ==
                false ? null : (
                <div className="VerteilerContainer">
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">2.Mieter</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.coRenter?.salutaion == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.coRenter?.salutaion ==
                                "noChoice" && "Keine Angabe "}
                              {protocolToShow?.coRenter?.salutaion == "male" &&
                                "Herr "}
                              {protocolToShow?.coRenter?.salutaion == "female" &&
                                "Frau "}
                            </span>
                            <br />
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.coRenter?.first_name == null &&
                                protocolToShow?.coRenter?.last_name == null && (
                                  <span>Name und Vorname fehlt</span>
                                )}
                              {(protocolToShow?.coRenter?.first_name == null ||
                                protocolToShow?.coRenter?.first_name == "") &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name
                                      ? protocolToShow?.coRenter?.first_name
                                      : " Vorname fehlt "}
                                  </>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                (protocolToShow?.coRenter?.last_name == null ||
                                  protocolToShow?.coRenter?.last_name == "") && (
                                  <>
                                    {protocolToShow?.coRenter?.last_name
                                      ? protocolToShow?.coRenter?.last_name
                                      : " Nachname fehlt "}
                                  </>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name}{" "}
                                    {protocolToShow?.coRenter?.last_name}
                                  </>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.coRenter?.signature_coRenter && (
                        <>
                          <div className="second-part-formverteilerOvwerview">
                            {protocolToShow?.coRenter?.signature_coRenter
                              ?.refuse_reason ? (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.coRenter?.signature_coRenter
                                    ?.refuse_reason
                                )}
                              </span>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.coRenter?.signature_coRenter
                                      ?.remote_url
                                  }
                                  alt={
                                    protocolToShow?.coRenter?.signature_coRenter
                                      ?.image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.coRenter?.signature_coRenter
                                    ?.time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.time_stamp
                                          ?.seconds
                                      )
                                      .add(
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>
              )}

              {protocolToShow?.protocol_type == "moveInAndExit" ? (
                <div className="VerteilerContainer">
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Vormieter</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "noChoice" &&
                                "Keine Angabe "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "male" && "Herr "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "female" &&
                                "Frau "}
                            </span>
                            <br />
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_first_name
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_first_name + " "
                                : "Vorname fehlt"}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_last_name
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_last_name
                                : "Nachname fehlt"}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.previous_renter?.signature_previous_renter
                        ?.signature_previous_renter === true ? (
                        <>
                          <div className="second-part-formverteilerOvwerview">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_image_remote_url
                                }
                                alt={
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_image_name
                                }
                                height="125px"
                                width="125px"
                              />
                              <span style={{ fontSize: "700", color: "#000000" }}>
                                {protocolToShow?.previous_renter
                                  ?.signature_previous_renter
                                  ?.signature_previous_renter_time_stamp !== null
                                  ? moment
                                    .unix(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_time_stamp
                                        ?.seconds
                                    )
                                    .add(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_time_stamp
                                        ?.nanoseconds / 1000000
                                    )
                                    .format("DD/MM/YYYY, HH:mm")
                                  : "Kein Angabe"}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        protocolToShow?.previous_renter?.signature_previous_renter
                          ?.signature_previous_renter === false && (
                          <div className="second-part-formverteilerOvwerview">
                            {protocolToShow?.previous_renter
                              ?.signature_previous_renter
                              ?.signature_previous_renter_refuse_reason ? (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_refuse_reason
                                )}
                              </span>
                            ) : (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                {/* <CloseIcon sx={{ fontSize: 33 }} /> */}
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                Keine Unterschrift
                              </span>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>
              ) : null}

              {protocolToShow?.witness_signature?.signature_witness === false &&
                protocolToShow?.witness_signature?.witness_refuse_signature ==
                false ? null : (
                <div className="VerteilerContainer">
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Zeuge</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.witness_signature
                                ?.signature_witness_name !== null
                                ? protocolToShow?.witness_signature
                                  ?.signature_witness_name
                                : protocolToShow?.witness_signature
                                  ?.signature_witness_name === null && (
                                  <span>Name fehlt</span>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.witness_signature?.signature_witness &&
                        (protocolToShow?.witness_signature
                          ?.signature_witness_refuse_reason ? (
                          <span
                            className="bad-status"
                            style={{ marginLeft: "8rem" }}
                          >
                            <img
                              className="notvalidateSclusselOvwerviewIcon"
                              src={NotValidIcon}
                              alt="notvalidateSclusselOvwerview Icon"
                              style={{
                                width: "1rem",
                                marginRight: "0.25rem",
                              }}
                            />
                            {formatReason(
                              protocolToShow?.witness_signature
                                ?.signature_witness_refuse_reason
                            )}
                          </span>
                        ) : (
                          <>
                            <div className="second-part-formverteilerOvwerview">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.witness_signature
                                      ?.signature_witness_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.witness_signature
                                      ?.signature_witness_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.witness_signature
                                    ?.signature_witness_time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_time_stamp
                                          ?.seconds
                                      )
                                      .add(
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Keine Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>
              )}
            </div>
            {protocol_pattern &&
              protocol_pattern.length > 0 &&
              protocol_pattern.find(
                (key) => key.key_section_name === "signatures"
              ) &&
              handleDisplayPatternSection("signatures")}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProtokollOvwerView;
