export const DateFormatter = {
    formatTimeStamp, formatDate, initDate, formatProtocolDates, formatKautionDates
};
function formatTimeStamp(timestamp) {
    if (timestamp) {
        const timeObject = timestamp;
        const milliseconds = timeObject.seconds * 1000 + timeObject.nanoseconds / 1000000;
        const date = new Date(milliseconds);
        return (new Date(date.getTime() + date.getTimezoneOffset() * 60000))
    } else return null
}
function initDate() {
    const now_date = new Date();

    const utc_time = new Date(
        now_date.getTime() + now_date.getTimezoneOffset() * 60000
    );
    return (utc_time)
}
function formatDate(date) {
    if (date) {
        const dateParts = date.split('.');
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];

        return (new Date(`${month}/${day}/${year}`));
    } else return null
}

function formatProtocolDates(row) {
    row.created_at = formatTimeStamp(row.created_at);
    row.updated_at = formatTimeStamp(row.updated_at);

    if (row.protocol_rooms) {
        row.protocol_rooms.map((element) => {
            element.room_updated_at = formatTimeStamp(
                element.room_updated_at
            );
            element.room_date_creation = formatTimeStamp(
                element.room_date_creation
            );
            if (element.room_deffect) {
                element.room_deffect.map((def) => {
                    def.created_at = formatTimeStamp(def.created_at);
                    def.updated_at = formatTimeStamp(def.updated_at);
                    def.room_deffect_date_of_fix = def.room_deffect_date_of_fix
                        ? formatDate(def.room_deffect_date_of_fix)
                        : "";
                });
            }

            if (element.room_image) {
                element.room_image.map((image) => {
                    image.updated_at = formatTimeStamp(
                        image.updated_at
                    );
                    image.created_at = formatTimeStamp(
                        image.created_at
                    );
                });
            }
        });
    }
    if (row.protocol_counter) {
        row.protocol_counter.map((element) => {
            element.counter_created_at = formatTimeStamp(
                element.counter_created_at
            );
            element.counter_updated_at = formatTimeStamp(
                element.counter_updated_at
            );
        });
    }
    if (row.roommates != null) {
        row.roommates.map((element) => {
            element.created_at = formatTimeStamp(
                element.created_at
            );
            element.updated_at = formatTimeStamp(
                element.updated_at
            );
        });
    }
    if (row.renter) {
        row.renter.renter_date_of_birth = row.renter.renter_date_of_birth
            ? formatDate(row.renter.renter_date_of_birth)
            : "";
    }
    row.date_of_hand_over = formatDate(row.date_of_hand_over);
    row.object.object_date_of_renovation = formatDate(
        row.object.object_date_of_renovation
    );
    if (row.protocol_keys) {
        row.protocol_keys.map((element) => {
            element.created_at = formatTimeStamp(
                element.created_at
            );
            element.updated_at = formatTimeStamp(
                element.updated_at
            );
            if (element.key_date_of_forwarding) {
                element.key_date_of_forwarding = element.key_date_of_forwarding
                    ? formatDate(element.key_date_of_forwarding)
                    : null;
            }
        });
    }
    return (row)
}

function formatKautionDates(row) {
    row.created_at = formatTimeStamp(row.created_at);
    row.updated_at = formatTimeStamp(row.updated_at);

    if (row.renter) {
        row.renter.date_of_birth = row.renter.date_of_birth
            ? formatDate(row.renter.date_of_birth)
            : "";
    }

    if (row.coRenter) {
        row.coRenter.date_of_birth = row.coRenter.date_of_birth
            ? formatDate(row.coRenter.date_of_birth)
            : "";
    }

    row.object.date_of_renovation = formatDate(
        row.object.date_of_renovation
    );
    if (row.deposit_amount_information) {
        row.deposit_amount_information.rental_agreement_signed_on = row.deposit_amount_information.rental_agreement_signed_on
            ? formatDate(row.deposit_amount_information.rental_agreement_signed_on)
            : "";
        row.deposit_amount_information.rental_starts_on = row.deposit_amount_information.rental_starts_on
            ? formatDate(row.deposit_amount_information.rental_starts_on)
            : "";
        row.deposit_amount_information.deposit_other_options.rental_agreement_limited_date = row.deposit_amount_information.deposit_other_options.rental_agreement_limited_date
            ? formatDate(row.deposit_amount_information.deposit_other_options.rental_agreement_limited_date)
            : "";
    }

    return (row)
}
