/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
    AsyncDumpComponent,
} from 'routes';

const Dump = ({ match }) => (
	<Switch>
		<Store>
        <ProtectedRoute path={`${match.url}`} component={AsyncDumpComponent} route={"product_import"} ></ProtectedRoute>	
		</Store>

	</Switch>
)
export default Dump;