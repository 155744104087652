/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncSMTPComponent,
} from 'routes';

const Smtp = ({ match }) => (
	<Switch>
		<Store>
			 <ProtectedRoute path={`${match.url}`} component={AsyncSMTPComponent} route={"Emaileinstellungen"} ></ProtectedRoute>
			
		</Store>

	</Switch>
)
export default Smtp;