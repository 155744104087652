import React , { useState, useEffect }from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import ValidIcon from "../../assets/Icons/valid-icon.svg";
import PaymentIcon from "@mui/icons-material/Payment";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import temperature from "../../assets/Icons/temperature.svg";
import EuroIcon from "@mui/icons-material/Euro";
import SpeedIcon from "@mui/icons-material/Speed";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import gasIcon from "../../assets/Icons/fire.svg";
import stromIcon from "../../assets/Icons/strom-icon.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";
import { contractService } from "_services/contract.service";
import { useHistory  } from "react-router-dom";

function GasOverview() {
  const history  = useHistory();
  const location = useLocation();
  const [globalState, setGlobalState] = useState();
  const [showImage, setShowImage] = useState(false);

  const toggleImage = () => {
    setShowImage(!showImage);
  };
  function cancel() {
    history.push("/app/overview");

  }
  const getContractToShow = async () => {
    await contractService.getContract(location.state.state.id_doc).then( result => {
      if (result){
        setGlobalState(result.data())
      }
    });
  };
  useEffect(() => {
    getContractToShow();
  }, []);

  const renderField = (field, color, msgError) => {
    let msg = "";
    let error = false;
    switch (
      field === "" ||
      field == null ||
      globalState?.validationErrors?.field === true
    ) {
      case true:
        error = true;
        break;
      case false:
        if (field === "male") {
          msg = "Herr";
        } else if (field === "female") {
          msg = "Frau";
        } else if (field === "noChoice") {
          msg = "Keine Angabe";
        } else {
          msg = field;
        }
        error = false;

        break;
      default:
        msg = field;
        break;
    }
    return error ? (
      <span style={{ color: color }}>{msgError}</span>
    ) : (
      <span>{msg}</span>
    );
  };

  return (
    <>
      
            <div className="layoutstepsbar" style={{ height: "70px" }}>
              <div className="row layoutcontent full-height mr-0 ml-0">
                <div className="einzug width">
                  <img
                    className="RaumDetailsIcon"
                    src={
                      globalState?.product_type === "gas" ? gasIcon : stromIcon
                    }
                    alt="Übergabeprotokoll Icon"
                  />
                  <h3 style={{ textTransform: "none" }}>
                    {globalState?.product_name}
                  </h3>
                </div>
                <div className="Weiter align-end-button" >
                  <button
                    onClick={() => cancel()}
                    className="WeiterButton"
                    type="submit"
                  >
                    Zurück
                    <ArrowForwardIosIcon
                      className="icon-registrieren"
                      sx={{ fontSize: 16 }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">
            <div className="card-shadow">
            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "none" }}>Abschluss</h3>
            </div>
            <div className="after-cards">
              <div className="textfieldsdateplzObOw position-relative">
                <div className="align-items-center padding-product ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="titleField">
                        <img
                          className="imgTitle"
                          src={
                            globalState?.product_type === "gas"
                              ? gasIcon
                              : stromIcon
                          }
                          alt="img1"
                        ></img>
                        <span>PRODUKTNAME</span>
                      </div>
                      <div
                        className="resultField"
                        id="titleBold"
                        style={{ color: "#000000" }}
                      >
                        <span>{globalState?.product_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <EuroIcon />
                        </div>
                        <span>Verbrauchspreis</span>
                      </div>
                      <div className="resultField">
                        <span>
                          {globalState?.product_consumtion_price} {"ct/kwh"}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <LocalOfferIcon />
                        </div>
                        <span>Grundpreis</span>
                      </div>
                      <div className="resultField">
                        <span>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(globalState?.product_base_price)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <CalendarMonthIcon />
                        </div>
                        <span>Vertragslaufzeit</span>
                      </div>
                      <div className="resultField">
                        <span>{globalState?.product_duration}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <AccessTimeFilledIcon />
                        </div>
                        <span>Verlängerung</span>
                      </div>
                      <div className="resultField">
                        <span>{globalState?.product_renewal}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <SpeedIcon />
                        </div>
                        <span>Jahresverbrauch</span>
                      </div>
                      <div className="resultField">
                        <span style={{ fontweight: "bold" }}>
                          {globalState?.consumption} Kwh
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="titleField">
                        <div>
                          <EuroIcon />
                        </div>
                        <span>Gesamtkosten (1.Monat)</span>
                      </div>
                      <div className="resultField">
                        <span style={{ fontweight: "bold" }}>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(globalState?.product_total_cost)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
              <div className="textfieldsdateplzObOw position-relative">
                <div className="row align-items-center padding-product ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="titleField " id="kundeninformationen">
                          <h3>Kundeninformationen</h3>
                        </div>
                        <div
                          className="resultField"
                          id="titleBold"
                          style={{ color: "#000000" }}
                        >
                          {globalState?.customer_gender === null &&
                            "Anrede fehlt "}
                          {globalState?.customer_gender == "noChoice" &&
                            "Keine Angabe "}
                          {globalState?.customer_gender == "male" && "Herr "}
                          {globalState?.customer_gender == "female" && "Frau "}
                          {(globalState?.customer_title == "noChoice" ||
                            globalState?.customer_title == null) &&
                            "Keine Angabe "}
                          {globalState?.customer_title == "dr" && "Dr. "}
                          {globalState?.customer_title == "prof" && "Prof. "}
                          {globalState?.customer_title == "ing" && "Ing. "}
                          {renderField(
                            globalState?.customer_first_name + " ",
                            "black",
                            "Name und "
                          )}{" "}
                          {renderField(
                            globalState?.customer_last_name + " ",
                            "black",
                            "Vorname fehlt"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-md-4 w-full">
                        <div className="titleField">
                          <span>Unternehmensname</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {renderField(
                              globalState?.company_name,
                              "black",
                              "Angabe fehlt"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="titleField">
                          <span>Adresse</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {renderField(
                              globalState?.customer_street + ", ",
                              "black",
                              "Straße fehlt, "
                            )}
                            {renderField(
                              globalState?.customer_house_number + ", ",
                              "black",
                              "Hausnummer fehlt"
                            )}
                          </span>
                          <br />
                          <span>
                            {globalState?.postal_code + ", "}
                            {renderField(
                              globalState?.customer_location,
                              "black",
                              " Ort fehlt"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="titleField">
                          <span>Geburtsdatum</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {renderField(
                              globalState?.customer_birth_date
                                ? globalState?.customer_birth_date
                                : "Angabe fehlt",

                              "black",
                              "Angabe fehlt"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-md-4">
                        <div className="titleField">
                          <span>E-Mail</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {renderField(
                              globalState?.customer_email,
                              "black",
                              "E-Mail fehlt"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="titleField">
                          <span>Festnetz</span>
                        </div>
                        <div
                          className="resultField"
                          style={{ color: "#000000" }}
                        >
                          {globalState?.customer_landline_phone
                            ? globalState?.customer_landline_phone
                            : "Angabe fehlt"}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="titleField">
                          <span>Mobile Rufnummer</span>
                        </div>
                        <div
                          className="resultField"
                          style={{ color: "#000000" }}
                        >
                          {globalState?.customer_mobile_phone
                            ? globalState?.customer_mobile_phone
                            : "Angabe fehlt"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
              {globalState?.alternative_billing && (
                <div className="textfieldsdateplzObOw position-relative">
                  <div className="row align-items-center padding-product ">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="titleField " id="kundeninformationen">
                            <h3>Alternative Rechnungsadresse</h3>
                          </div>
                          <div
                            className="resultField"
                            id="titleBold"
                            style={{ color: "#000000" }}
                          >
                            <span>
                              {globalState?.alternative_billing_gender ===
                                null && "Anrede fehlt "}
                              {globalState?.alternative_billing_gender ==
                                "noChoice" && "Keine Angabe "}
                              {globalState?.alternative_billing_gender ==
                                "male" && "Herr "}
                              {globalState?.alternative_billing_gender ==
                                "female" && "Frau "}
                              {(globalState?.alternative_billing_title ==
                                "noChoice" ||
                                globalState?.alternative_billing_title ==
                                  null) &&
                                "Keine Angabe "}
                              {globalState?.alternative_billing_title == "dr" &&
                                "Dr. "}
                              {globalState?.alternative_billing_title ==
                                "prof" && "Prof. "}
                              {globalState?.alternative_billing_title ==
                                "ing" && "Ing. "}
                              {renderField(
                                globalState?.alternative_billing_first_name +
                                  " ",
                                "black",
                                "Name und "
                              )}{" "}
                              {renderField(
                                globalState?.alternative_billing_last_name +
                                  " ",
                                "black",
                                "Vorname fehlt"
                              )}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>Adresse</span>
                          </div>
                          <div className="resultField">
                            <span>
                              {renderField(
                                globalState?.alternative_billing_street + ", ",
                                "black",
                                "Straße fehlt,"
                              )}{" "}
                              {renderField(
                                globalState?.alternative_billing_house_number,
                                "black",
                                "Hausnummer fehlt"
                              )}
                              {", "}
                              <br />
                              {renderField(
                                globalState?.alternative_billing_postal_code +
                                  ", ",
                                "black",
                                "PLZ fehlt "
                              )}{" "}
                              {renderField(
                                globalState?.alternative_billing_location,
                                "black",
                                "Ort fehlt"
                              )}
                              {""}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>Unternehmen</span>
                          </div>
                          <div className="resultField">
                            <span>
                              {renderField(
                                globalState?.alternative_billing_company_name,
                                "black",
                                "Angabe fehlt"
                              )}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {globalState?.alternative_billing ? (
                <hr className="hrOvwerview" />
              ) : null}
            </div>
          </div>
          </div>

          {globalState?.sepa_check_box ? (
            <div className="SonCard mt-2 mb-2">
              <div className="card-shadow">
              <div className="neuer">
                <PaymentIcon
                  style={{ width: 40, fontSize: "large", height: 40 }}
                  className="NeuerIcon"
                />
                <h3>SEPA Lastschriftmandat</h3>
              </div>

              <div className="after-cards">
                <div className="textfieldsdateplzObOw position-relative">
                  <div className="row align-items-center padding-product ">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>Kontoinhaber</span>
                          </div>
                          <div className="resultField">
                            <span>
                              {renderField(
                                globalState?.sepa_account_holder,
                                "black",
                                "Angabe fehlt"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>Bank</span>
                          </div>
                          <div className="resultField">
                            <span>
                              {renderField(
                                globalState?.sepa_bank,
                                "black",
                                "Angabe fehlt"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 mb-2   ">
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>IBAN</span>
                          </div>
                          <div className="resultField">
                            <span>
                              {renderField(
                                globalState?.sepa_iban,
                                "black",
                                "Angabe fehlt"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="titleField">
                            <span>BIC</span>
                          </div>
                          <div className="resultField">
                            {renderField(
                              globalState?.sepa_bic,
                              "black",
                              "Angabe fehlt"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row  mt-2 mb-2   ">
                        <div className="col-md-12">
                          <div className="titleField">
                            <span>BESTÄTIGUNG/EINVERSTÄNDINS*: </span>
                          </div>
                          <div className="col-md-12" id="finalLine">
                            <div
                              className="row"
                              style={{
                                textAlign: "justify",
                                marginLeft: "-2rem",
                              }}
                            >
                              <div className="col-md-1">
                                {globalState?.sepa_check_box ? (
                                  <CheckIcon sx={{ fontSize: 30 }} />
                                ) : null}
                              </div>
                              <div className="col-md-11">
                                <span style={{ fontSize: "1rem" }}>
                                  {globalState?.sepa_legal}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          ) : null}

          <div className="SonCard mt-2 mb-2">
          <div className="card-shadow">
            <div className="neuer">
              <img className="NeuerIcon" src={temperature} alt="Zähler Icon" />
              <h3 style={{ textTransform: "none" }}> Zähler / Verteiler </h3>
            </div>
            <div className="after-cards">
              <div className="textfieldsdateplzObOw position-relative">
                <div className="row align-items-center padding-product ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="titleField " id="kundeninformationen">
                          {globalState?.product_type == "strom" ? (
                            globalState?.product_counter_type ===
                            "singleCounter" ? (
                              <span>Eintarif Stromzähler</span>
                            ) : globalState?.product_counter_type ===
                              "doubleCounter" ? (
                              <span>Zweitarif Stromzäler</span>
                            ) : (
                              <span>Stromzähler</span>
                            )
                          ) : (
                            <span>Gaszähler</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row mt-2 mb-2 w-full">
                          <div className="col-md-12">
                            {globalState?.product_type === "strom" &&
                              globalState?.product_counter_type ===
                                "doubleCounter" && (
                                <div className="fieldInformationen">
                                  <div className="titleField">
                                    <span>Zählernummer</span>
                                  </div>
                                  <div className="resultField">
                                    <span>
                                      {renderField(
                                        globalState?.product_counter_number,
                                        "#000000",
                                        "Angabe fehlt"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}

                            <div
                              className="fieldInformationen"
                              style={{ marginBottom: "1rem" }}
                            >
                              {globalState?.product_type === "strom" &&
                              globalState?.product_counter_type ===
                                "doubleCounter" ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="titleField">
                                      <span> HT Zählernummer</span>
                                    </div>
                                    <div className="resultField">
                                      <span>
                                        {renderField(
                                          globalState?.product_counter_reading,
                                          "#000000",
                                          "Angabe fehlt"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  {globalState?.product_counter_type ===
                                    "doubleCounter" &&
                                    globalState?.product_type === "strom" && (
                                      <div className="col-md-6">
                                        <div className="titleField">
                                          <span>NT Zählerstand </span>
                                        </div>
                                        <div className="resultField">
                                          <span>
                                            {renderField(
                                              globalState?.product_counter_reading_2,
                                              "#000000",
                                              "Angabe fehlt"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="titleField">
                                        <span>Zählernummer</span>
                                      </div>
                                      <div className="resultField">
                                        <span>
                                          {renderField(
                                            globalState?.product_counter_number,
                                            "#000000",
                                            "Angabe fehlt"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="titleField">
                                        <span>Zählerstand</span>
                                      </div>
                                      <div className="resultField">
                                        <span>
                                          {renderField(
                                            globalState?.product_counter_reading,
                                            "black",
                                            "Angabe fehlt"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="fieldInformationen">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="titleField">
                                    <span>Marktlokationsnummer</span>
                                  </div>
                                  <div className="resultField">
                                    <span>
                                      {renderField(
                                        globalState?.product_market_Location_number,
                                        "black",
                                        "Angabe fehlt"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  {" "}
                                  <div className="titleField">
                                    <span>Installationsort</span>
                                  </div>
                                  <div className="resultField">
                                    <span>
                                      {renderField(
                                        globalState?.product_installation_position,
                                        "black",
                                        "Angabe fehlt"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 ">
                            {globalState?.product_new_move ? (
                              <>
                                <div className="row mt-2 mb-2 w-full">
                                  <div className="col-md-4">
                                    <div className="titleField">
                                      <span>Datum Des Neueinzuges</span>
                                    </div>
                                    <div className="resultField">
                                      <span>
                                        {renderField(
                                          globalState?.product_move_date
                                            ? globalState?.product_move_date
                                            : "Angabe fehlt",
                                          "black",
                                          "Angabe fehlt"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row mt-2 mb-2 w-full">
                                  <div className="col-md-4">
                                    <div className="titleField">
                                      <span>
                                        Bisheriger{" "}
                                        {globalState?.product_type === "gas"
                                          ? "Gaslieferant"
                                          : "Stromlieferant"}
                                      </span>
                                    </div>
                                    <div className="resultField">
                                      <span>
                                        {renderField(
                                          globalState?.product_previous_supplier,
                                          "black",
                                          "Angabe fehlt"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="titleField">
                                      <span> Bisherige Kundennummer</span>
                                    </div>
                                    <div className="resultField">
                                      <span>
                                        {renderField(
                                          globalState?.product_previous_customer_number,
                                          "black",
                                          "Angabe fehlt"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="titleField">
                                      <span>Verbrauch des Vorjahres</span>
                                    </div>
                                    <div className="resultField">
                                      {renderField(
                                        globalState?.product_last_year_consumption
                                          ? globalState?.product_last_year_consumption
                                          : "Angabe fehlt",
                                        "black",
                                        "Angabe fehlt"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="fieldInformationen w-full">
                          {globalState?.product_counter_image_url ? (
                            <div className="image--container w-full">
                              {showImage && (
                                <div
                                  className="image-overlay"
                                  onClick={toggleImage}
                                >
                                  <img
                                    src={globalState?.product_counter_image_url}
                                    className="image"
                                  />
                                </div>
                              )}
                              {!showImage && (
                                <div
                                  className="icon overview"
                                  onClick={toggleImage}
                                >
                                  {/* Add your icon here */}
                                  <i className="fas fa-search-plus"></i>
                                </div>
                              )}
                              <img
                                src={globalState?.product_counter_image_url}
                                style={
                                  globalState?.product_type === "strom" &&
                                  globalState?.product_counter_type ===
                                    "doubleCounter"
                                    ? {
                                        width: "100%",
                                        height: "250px",
                                        objectFit: "cover",
                                      }
                                    : {
                                        width: "250px",
                                        objectFit: "cover",
                                        width: "100%",
                                      }
                                }
                              />
                            </div>
                          ) : (
                            <PhotoLibraryIcon
                              className="defaultImageIcon w-full"
                              sx={
                                globalState?.product_type === "strom" &&
                                globalState?.product_counter_type ===
                                  "doubleCounter"
                                  ? {
                                      fontSize: 100,
                                      width: "100%",
                                      height: 200,
                                    }
                                  : {
                                      fontSize: 100,
                                      width: "100%",
                                      height: 155,
                                    }
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="SonCard mt-2 mb-2">
          <div className="card-shadow">
            <div
              className="neuer"
              style={{
                background:
                  "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
              }}
            >
              <img
                className="NeuerIcon"
                src={ContractIcon}
                alt="Abschlussbemerkung Icon"
              />
              <h3> Unterschriften </h3>
            </div>
            <div className="VerteilerContainer">
              <div className="formAbschlussOvwerview">
                <div className="sub-formverteilerOvwerview">
                  <div className="first-part-formverteilerOvwerview">
                    <div
                      className="LigneWohnungsdatenOvwerview"
                      style={{ marginBottom: "20px" }}
                    >
                      <span>Vertragspartner</span>
                    </div>
                    <div
                      className="LigneDataAdresse-RenviernungsdatumOvwerview"
                      style={{ marginBottom: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <span style={{ fontSize: "700" }}>
                          {(globalState?.customer_gender == null ||
                            globalState?.customer_gender == "") &&
                            "Anrede fehlt "}
                          {globalState?.customer_gender == "noChoice" &&
                            "Keine Angabe "}
                          {globalState?.customer_gender == "male" && "Herr "}
                          {globalState?.customer_gender == "female" && "Frau "}
                        </span>
                        <br />
                        <span style={{ fontSize: "700" }}>
                          {globalState?.customer_first_name == null &&
                            globalState?.customer_last_name == null && (
                              <span>Name und Vorname fehlt</span>
                            )}
                          {(globalState?.customer_first_name == null ||
                            globalState?.customer_first_name == "") &&
                            globalState?.customer_last_name != null && (
                              <>
                                {globalState?.customer_first_name
                                  ? globalState?.customer_first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {globalState?.customer_first_name != null &&
                            (globalState?.customer_last_name == null ||
                              globalState?.customer_last_name == "") && (
                              <>
                                {globalState?.customer_last_name
                                  ? globalState?.customer_last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {globalState?.customer_first_name != null &&
                            globalState?.customer_last_name != null && (
                              <>
                                {globalState?.customer_first_name}{" "}
                                {globalState?.customer_last_name}
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {globalState?.signature_url !== null ? (
                    <>
                      <div className="second-part-formverteilerOvwerview">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={globalState?.signature_url}
                            alt={"signature image"}
                            height="125px"
                            width="125px"
                          />
                          <span style={{ fontSize: "700", color: "#000000" }}>
                            {globalState?.signature_time_stamp !== null
                              ? moment
                                  .unix(
                                    globalState?.signature_time_stamp?.seconds
                                  )
                                  .add(
                                    globalState?.signature_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                              : "Kein Angabe"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="second-part-formverteilerOvwerview">
                      <PhotoLibraryIcon
                        className="defaultImageIcon"
                        sx={{ width: 425, height: 275 }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="hrOvwerview" />
            </div>

            {globalState?.sepa_check_box ? (
              <div className="VerteilerContainer">
                <div className="formAbschlussOvwerview">
                  <div className="sub-formverteilerOvwerview">
                    <div className="first-part-formverteilerOvwerview">
                      <div
                        className="LigneWohnungsdatenOvwerview"
                        style={{ marginBottom: "20px" }}
                      >
                        <span>SEPA Mandat</span>
                      </div>
                      <div
                        className="LigneDataAdresse-RenviernungsdatumOvwerview"
                        style={{ marginBottom: "20px" }}
                      >
                        <div style={{ width: "100%" }}>
                          <span style={{ fontSize: "700" }}>
                            {(globalState?.customer_gender == null ||
                              globalState?.customer_gender == "") &&
                              "Anrede fehlt "}
                            {globalState?.customer_gender == "noChoice" &&
                              "Keine Angabe "}
                            {globalState?.customer_gender == "male" && "Herr "}
                            {globalState?.customer_gender == "female" &&
                              "Frau "}
                          </span>
                          <br />
                          <span style={{ fontSize: "700" }}>
                            {globalState?.customer_first_name == null &&
                              globalState?.customer_last_name == null && (
                                <span>Name und Vorname fehlt</span>
                              )}
                            {(globalState?.customer_first_name == null ||
                              globalState?.customer_first_name == "") &&
                              globalState?.customer_last_name != null && (
                                <>
                                  {globalState?.customer_first_name
                                    ? globalState?.customer_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {globalState?.customer_first_name != null &&
                              (globalState?.customer_last_name == null ||
                                globalState?.customer_last_name == "") && (
                                <>
                                  {globalState?.customer_last_name
                                    ? globalState?.customer_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {globalState?.customer_first_name != null &&
                              globalState?.customer_last_name != null && (
                                <>
                                  {globalState?.customer_first_name}{" "}
                                  {globalState?.customer_last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {globalState?.sepa_signature_url !== null ? (
                      <>
                        <div className="second-part-formverteilerOvwerview">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={globalState?.sepa_signature_url}
                              alt={"signature image"}
                              height="125px"
                              width="125px"
                            />
                            <span style={{ fontSize: "700", color: "#000000" }}>
                              {globalState?.sepa_signature_time_stamp !== null
                                ? moment
                                    .unix(
                                      globalState?.sepa_signature_time_stamp
                                        ?.seconds
                                    )
                                    .add(
                                      globalState?.sepa_signature_time_stamp
                                        ?.nanoseconds / 1000000
                                    )
                                    .format("DD/MM/YYYY, HH:mm")
                                : "Kein Angabe"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="second-part-formverteilerOvwerview">
                        <PhotoLibraryIcon
                          className="defaultImageIcon"
                          sx={{ width: 425, height: 275 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr className="hrOvwerview" />
              </div>
            ) : null}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GasOverview;
