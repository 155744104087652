import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import userIcon from '../../assets/Icons/user-icon.svg'
import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import logoXcite from "../../assets/Icons/black-logo.svg"
import Alert from '@mui/material/Alert';


export default function ForgotPassword() {

  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setFormErrors(validate(formValues));
      setError("");
      setLoading(true);
      await resetPassword(formValues.email);
      setMessage("Eine E-Mail zur Passwortänderung wurde an Sie versendet. Bitte überprüfen Sie Ihr E-Mail-Postfach.");
      setIsSubmit(true);
    } catch {
      setError("Ungültige E-Mailadresse");

    }

    setLoading(false);

  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "E-Mailadresse wird benötigt";
    } else if (!regex.test(values.email)) {
      errors.email = "Ungültige E-Mailadresse";
    }
    return errors;
  };


  return (
    <>
      <div className="bg-color-home">
        <Container className="padding-fpw">

          <div className="card pt-2 pb-2">
            <div className="card-contain padding">
              <span className="logo">
                <img src={logoXcite} alt="logo" />
              </span>
              {Object.keys(formErrors).length === 0 && isSubmit ? (
                <span className="success">{message && <Alert severity="success">{message}</Alert>}</span>
              ) : (
                <span className="error"> {error && <Alert severity="error">{error}</Alert>}</span>
              )}

              <Row className="mt-4 mb-4">
                <Col md={12}>
                  <form >
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                    >

                      <div className="input-field">
                        <img className="userIcon" src={userIcon} alt="user Icon" />
                        <TextField
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          id="E-Mailadresse"
                          type="email"
                          ref={emailRef}
                          label="E-Mail"
                          fullWidth
                          error={error}
                          required
                          variant="outlined" />
                      </div>
                      <span className="error">{formErrors.email}</span>
                    </Box>

                  </form>
                </Col>
                <Col md={12} className="mt-4 mb-2">
                  <div className="row  ">
                    <div className="col-md-12">

                      <Link className="forgetPass" to="/signin"> Ich möchte mein Passwort nicht zurücksetzen und zurück zum Login.</Link>
                    </div>

                  </div>
                </Col>
                <Col md={12} className="mt-2 mb-1">
                  <div className="Einloggen">
                    <button disabled={loading} className="LoginButton" onClick={(e) => handleSubmit(e)}>
                      ZURÜCKSETZEN
                    </button>
                  </div>
                </Col>
              </Row>

            </div>
          </div>
        </Container >
      </div>
    </>
  );
}
