import React,{ useRef, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Col, Row } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FaPlusCircle } from "react-icons/fa";

import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { userService } from "../../_services/user.service";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { productService } from "../../_services/product.service";
import { companyService } from "../../_services/company.service";
import { DateFormatter } from "../../helpers/DateFormatter";
import { useHistory  } from "react-router-dom";
import { promocodeService } from "_services/promo-code.service";
export default function Register() {
  const history  = useHistory();
  const [state, setState] = useState({
    firebase_auth_id: "",
    role: "makler",
    address: {
      street: "",
      plz: "",
      city: "",
      house_number: "",
      address_note: "",
    },
    company_id: "",
    app_device: "",
    app_plattform: "",
    app_version: "",
    is_tester: false,
    access: 1,
    firebase_devices_tokens: null,
    image_url: null,
    account_creator: true,
    company_name: "",
    first_name: "",
    last_name: "",
    gender: null,
    phone_number: "",
    email: "",
    product_group: [
      {
        id_product: "",
        id_user: "",
        isActive: 1,
      },
    ],
    software_version: "",
    agb_date: null,
    last_login: null,
    created_at: null,
    updated_at: null,
    provision_level: 0,
    promo_code: "",
  });
  const [errors, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [agree, setAgree] = useState(true);
  const [productgroup, setProductgroup] = useState([]);
  const [addPromocode, setAddPromocode] = useState(false);
  const [promocode, setpromocode] = useState();
  const [promocodeError, setpromocodeError] = useState(false);
  //function to show password
  const handleClickShowPassword = (name) => {
    if (name == "password") setShowPassword((show) => !show);
    else setShowPasswordConfirm((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // change all field value
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes("address.")) {
      // Handle nested property within the 'address' object
      const addressProperty = name.split("address.")[1];
      setState({
        ...state,
        address: {
          ...state.address,
          [addressProperty]: value,
        },
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };
    // update Promo Code
    const handleUpdatePromoCode = async (id,promocode) => {
      await promocodeService.updatePromoCode(id,promocode.id);
    };
  //validate fields form
  async function validate() {
    const errors = {};
    let isValid = true;
    let promocode = {};
    if (!state.gender) {
      isValid = false;
      errors["gender"] = "Erforderlich";
    }
    if (addPromocode) {
      if (!state.promo_code) {
        isValid = false;
        errors["promo_code"] = "leeres Feld Promo-Code";
        setpromocodeError(true)
      }
      else{
        await  promocodeService.ValidePromoCode(state.promo_code).then((result) => {
          
          if (result.msg) {
             setpromocode(result.data);
             promocode = result.data ;
          } else {
            isValid = false;
            errors["promo_code"] = "Aktionscode nicht gefunden";
            setpromocodeError(true)
            setState(prevState => ({
              ...prevState,
              promo_code: "", // Replace with an empty string 
            }));
          }
        });
      }
    }
    if (!state.first_name) {
      isValid = false;
      errors["first_name"] = "Erforderlich";
    } else {
      var pattern = new RegExp(/^([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+$/i);
      if (!pattern.test(state.first_name)) {
        isValid = false;
        errors["first_name"] = "Erforderlich";
      }
    }
    if (!state.last_name) {
      isValid = false;
      errors["last_name"] = "Erforderlich";
    } else {
      var pattern = new RegExp(/^([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+$/i);
      if (!pattern.test(state.last_name)) {
        isValid = false;
        errors["last_name"] = "Erforderlich";
      }
    }

    if (!state.phone_number) {
      isValid = false;
      errors["phone_number"] = "Ungültige telefonnummer";
    } else {
      var pattern = new RegExp(/^(?:\+49|0)?[\d-() ]{9,}$/);
      if (!pattern.test(state.phone_number)) {
        isValid = false;
        errors["phone_number"] = "Ungültige telefonnummer";
      }
    }
    if (!state.address.street) {
      isValid = false;
      errors["street"] = "Erforderlich";
    }
    if (!state.address.house_number) {
      isValid = false;
      errors["house_number"] = "Erforderlich";
    } else {
      var pattern = new RegExp(
        /^[1-9]\d{0,3} ?[a-zA-Z]?(?: ?[/-] ?[1-9]\d{0,3} ?[a-zA-Z]?)?$/i
      );
      if (!pattern.test(state.address.house_number)) {
        isValid = false;
        errors["house_number"] = "Erforderlich";
      }
    }
    if (!state.address.plz) {
      isValid = false;
      errors["plz"] = "Erforderlich";
    } else {
      var pattern = new RegExp(/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/i);
      if (!pattern.test(state.address.plz)) {
        isValid = false;
        errors["plz"] = "Erforderlich";
      }
    }
    if (!state.address.city) {
      isValid = false;
      errors["city"] = "Erforderlich";
    }

    if (typeof state.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(state.email)) {
        isValid = false;
        errors["email"] = "Erforderlich";
      }
    }
    if (!state.password) {
      isValid = false;
      errors["password"] = "Erforderlich";
    }

    if (!state.confirm_password) {
      isValid = false;
      errors["confirm_password"] = "Erforderlich";
    }

    if (
      typeof state.password !== "undefined" &&
      typeof state.confirm_password !== "undefined"
    ) {
      var strongRegex = new RegExp("(?=.{6,})");
      if (!strongRegex.test(state.password)) {
        isValid = false;
        errors["password"] = "Erforderlich";
      } else if (state.password !== state.confirm_password) {
        isValid = false;
        errors["confirm_password"] = "Erforderlich";
      }
    }

    setError(errors);
    return {isValid : isValid , promocode: promocode };
  }
  // handle checkbox agb
  const checkboxHandler = (event) => {
    setAgree(!agree);
  };
  const getProductGroup = async () => {
    try {
      const productGroup = await productService.getProductGroupListbyApp();

      if (productGroup.length > 0) {
        const updatedProductGroup = productGroup.map((element) => {
          return {
            id_product: element.id,
            isActive: element.active,
            id_user: "",
          };
        });

        setProductgroup(updatedProductGroup);
      } else {
        // Handle the case when the productGroup array is empty
        setProductgroup([]);
      }
    } catch (error) {
      console.error("Error fetching product groups:", error);
      // Handle the error as needed, e.g., show an error message to the user
    }
  };

  useEffect(() => {
    getProductGroup();
  }, []);

  //Create a User
  const createUser = async (promocode) => {
    try {
      // Create the user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        state.email,
        state.password
      );
      const userFirebase = userCredential.user;
      // Update the product group (if needed)
      if (userFirebase.uid && productgroup) {
        productgroup.forEach((element) => {
          element.id_user = userFirebase.uid;
        });
      }

      // Update the user state
      setState((prevState) => ({
        ...prevState,
        firebase_auth_id: userFirebase.uid,
        id: userFirebase.uid,
        last_login: userFirebase.metadata.lastSignInTime,
        created_at: userFirebase.metadata.creationTime,
        is_verified: userFirebase.emailVerified,
        product_group: productgroup,
        company_id: uuidv4(),
        agb_date: DateFormatter.initDate(),
      }));
      // Create a filtered user state object without password fields
      const filteredUserState = (({ password, confirm_password, ...rest }) =>
        rest)(state);
      // Create the user in your application's database
      const userData = {
        ...filteredUserState,
        firebase_auth_id: userFirebase.uid,
        id: userFirebase.uid,
        last_login: userFirebase.metadata.lastSignInTime,
        created_at: userFirebase.metadata.creationTime,
        is_verified: userFirebase.emailVerified,
        product_group: productgroup,
        company_id: uuidv4(),
        email: state.email.toLowerCase(),
        agb_date: DateFormatter.initDate(),
      };

      await userService.createUser(userData, "signup");
      // Send email verification
      await sendEmailVerification(userFirebase);
      await createCompany(userData, promocode);
      if (addPromocode){
        await handleUpdatePromoCode(userFirebase.uid, promocode);
      }
      
      // Redirect to the login page with a success state
      history.push({
        pathname: "/signin",
        state: { success: "signupSuccess" }
      });
    } catch (error) {
      // Handle errors and show user-friendly error messages
      if (error.code === "auth/email-already-in-use") {
        setError({ email: true });
        swal({
          title: "HINWEIS",
          icon: "error",
          text: "Diese E-Mailadresse ist bereits registriert. Bitte verwenden Sie eine andere E-Mail.",
          button: "OK",
        });
      }
      else if (error.code === "auth/invalid-email") {
        setError({ email: true });
        swal({
          title: "HINWEIS",
          icon: "error",
          text: "Ungültige E-Mail-Adresse!",
          button: "OK",
        });
      } else if (error.code === "auth/weak-password") {
        setError({ password: true, confirm_password: true });
        swal({
          title: "HINWEIS",
          icon: "error",
          text: "Das Passwort muss mindestens 6 Zeichen lang sein!",
          button: "OK",
        });
      } else {
        console.error("Firebase Authentication Error:", error);
        swal({
          title: "HINWEIS",
          icon: "error",
          text: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
          button: "OK",
        });
      }
    }
  };

  //Create a company
  const createCompany = async (userData, promocode) => {
    try {
      const companyData = {
        address: {
          company_street: userData.address.street,
          company_plz: userData.address.plz,
          company_city: userData.address.city,
          company_hnb: userData.address.house_number,
          company_adress_notice: userData.address.address_note,
        },
        careOf: {
          company_careof_email: userData.email,
          company_careof_firstname: userData.first_name,
          company_careof_name: userData.last_name,
          company_careof_phone: userData.phone_number,
          company_careof_salutation: userData.gender,
          company_careof_title: null,
        },
        company_access: 1,
        company_account_type: promocode.id ? "premium" : "basic",
        company_agb: "",
        company_alt_phone: "",
        company_design_mode: "light",
        company_fax: "",
        company_logo: null,
        company_mobile: "",
        company_name: "",
        company_name_app: "",
        company_phone: userData.phone_number,
        company_email: userData.email,
        company_product_group: productgroup.map((obj) => obj.id_product),
        company_stempel_url: "",
        company_type: "private",
        company_web: "",
        id: userData.company_id,
        provision_level: 0,
      };

      await companyService.createCompany(companyData);
    } catch (error) {
      console.error("Error creating the company:", error);
    }
  };

  //function to submit register form and add user to xc_user and xc_company
  async function handleSubmit(e) {
    e.preventDefault();
    let validation = await validate() ;
    if (validation.isValid) {
      try {
        await createUser(validation.promocode);
      } catch (error) {
        // Handle errors and show user-friendly error messages
        if (error.code === "auth/email-already-in-use") {
          setError({ email: true });
          swal({
            title: "HINWEIS",
            icon: "error",
            text: "Diese E-Mailadresse ist bereits registriert. Bitte verwenden Sie eine andere E-Mail.",
            button: "OK",
          });
        } else if (error.code === "auth/invalid-email") {
          setError({ email: true });
          swal({
            title: "HINWEIS",
            icon: "error",
            text: "Ungültige E-Mail-Adresse!",
            button: "OK",
          });
        } else if (error.code === "auth/weak-password") {
          setError({ password: true, confirm_password: true });
          swal({
            title: "HINWEIS",
            icon: "error",
            text: "Das Passwort muss mindestens 6 Zeichen lang sein!",
            button: "OK",
          });
        } else {
          console.error("Error during registration:", error);
          swal({
            title: "HINWEIS",
            icon: "error",
            text: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
            button: "OK",
          });
        }
      }
    }
    else{
      if(promocodeError){
        if(!state.promo_code){
          swal({
            title: "leerer Promo-Code !",
            text: "Sie möchten sich ohne Promo-Code anmelden ?",
            icon: "info", // Add an icon for clarity (optional)
            buttons: {
              cancel: {
                text: "Abbrechen",
                value: false,
                visible: true,
                className: "red-btn",
              },
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "green-btn",
              },
            },
          })
          .then((value) => {
            if (value) {
              //sign in without promocode
              setpromocode({});
              setAddPromocode(false);
              setState(prevState => ({
                ...prevState,
                promo_code: "", // Replace with an empty string 
              }));
              
            } else {
              setError({ promo_code: true });
              swal({
                title: "HINWEIS",
                icon: "error",
                text: "leeres Feld Promo-Code!",
                button: "OK",
              });
              setpromocode({});
            }
          });
        }
        else{
          swal({
            title: "ungültiger Promocode !",
            text: "Sie möchten sich ohne Promo-Code anmelden ?",
            icon: "info", // Add an icon for clarity (optional)
            buttons: {
              cancel: {
                text: "Abbrechen",
                value: false,
                visible: true,
                className: "red-btn",
              },
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "green-btn",
              },
            },
          })
          .then((value) => {
            if (value) {
              //sign in without promocode
              setpromocode({});
              setAddPromocode(false);
              setState(prevState => ({
                ...prevState,
                promo_code: "", // Replace with an empty string 
              }));
              
            } else {
              setError({ promo_code: true });
              swal({
                title: "HINWEIS",
                icon: "error",
                text: "Aktionscode nicht gefunden!",
                button: "OK",
              });
              setpromocode({});
            }
          });  
        }

      } 

    }
  }


  return (
    <div className="bg-color-home">
    <div className="margin-login container">
      <div className="row">
        <div className="col-md-12">
          <div className="centred-title pb-3 pt-3 ">
            <h1 className="title-login">Registrieren</h1>
          </div>
          <div className="card pt-3 pb-3 card-register">
            <div className="card-contain padding">
              <Row className="mt-3 mb-3">
                <Col md={12}>
                  <div className="input-field">
                    <TextField
                      name="company_name"
                      value={state.company_name}
                      onChange={handleChange}
                      id="company_name"
                      type="text"
                      label="Unternehmen"
                      placeholder="Unternehmen "
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error"></span>
                </Col>
                <Col md={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="gender"
                      style={{
                        backgroundColor: "white",
                        padding: "0 5px 0 0",
                      }}
                    >
                      ANREDE WÄHLEN
                    </InputLabel>
                    <Select
                      name="gender"
                      labelId="gender"
                      id="gender"
                      value={state.gender}
                      label="ANREDE WÄHLEN"
                      required
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value={"noChoice"}>Keine Angabe</MenuItem>
                      <MenuItem value={"male"}>Herr</MenuItem>
                      <MenuItem value={"female"}>Frau</MenuItem>
                      <MenuItem value={"divers"}>Divers</MenuItem>
                    </Select>
                  </FormControl>
                  <span className="error">{errors.gender}</span>
                </Col>
                <Col md={4}>
                  <div className="input-field">
                    <TextField
                      name="first_name"
                      value={state.first_name}
                      onChange={handleChange}
                      id="first_name"
                      type="text"
                      label="Vorname *"
                      placeholder="Vorname *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.first_name}</span>
                </Col>
                <Col md={4}>
                  <div className="input-field">
                    <TextField
                      name="last_name"
                      value={state.last_name}
                      onChange={handleChange}
                      id="last_name"
                      type="text"
                      label="Nachname *"
                      placeholder="Nachname *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.last_name}</span>
                </Col>
                <Col md={8}>
                  <div className="input-field">
                    <TextField
                      name="address.street"
                      value={state.address.street}
                      onChange={handleChange}
                      id="street"
                      type="text"
                      label="Strasse *"
                      placeholder="Strasse *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.street}</span>
                </Col>
                <Col md={4}>
                  <div className="input-field">
                    <TextField
                      name="address.house_number"
                      value={state.address.house_number}
                      onChange={handleChange}
                      id="house_number"
                      type="text"
                      label="Hausnummer *"
                      placeholder="Hausnummer *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.house_number}</span>
                </Col>
                <Col md={4}>
                  <div className="input-field">
                    <TextField
                      name="address.plz"
                      value={state.address.plz}
                      onChange={handleChange}
                      id="plz"
                      type="text"
                      label="PLZ *"
                      placeholder="PLZ *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.plz}</span>
                </Col>
                <Col md={8}>
                  <div className="input-field">
                    <TextField
                      name="address.city"
                      value={state.address.city}
                      onChange={handleChange}
                      id="city"
                      type="text"
                      label="WOHNORT *"
                      placeholder="WOHNORT *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.city}</span>
                </Col>
                <Col md={6}>
                  <div className="input-field">
                    <TextField
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      id="email"
                      type="email"
                      label="E-MAIL-ADRESSE *"
                      placeholder="E-MAIL-ADRESSE *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      InputProps={{
                        style: { paddingLeft: "10px" },
                      }}
                      variant="outlined"
                    />
                  </div>
                  <span className="error">{errors.email}</span>
                </Col>
                <Col md={6}>
                  <div className="input-field">
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        style: {
                          textTransform: "uppercase",
                          padding: "0 5px 0 0",
                          backgroundColor: "white",
                        },
                        shrink: true,
                      }}
                      InputProps={{
                        shrink: true,
                        startAdornment: (
                          <InputAdornment position="start">+49</InputAdornment>
                        ),
                      }}
                      type="phone"
                      name="phone_number"
                      id="phone_number"
                      value={state?.phone_number}
                      label="RUFNUMMER *"
                      placeholder="RUFNUMMER *"
                      variant="outlined"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <span className="error">{errors.phone_number}</span>
                </Col>
                <Col md={12}>
                  <div className="input-field">
                    <TextField
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      label="PASSWORT *"
                      placeholder="PASSWORT *"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={state.password}
                      onChange={handleChange}
                      InputProps={{
                        style: { paddingLeft: "10px" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      className="input-field-PP"
                    />
                  </div>
                  <span className="error">{errors.password}</span>
                </Col>
                <Col md={12}>
                  <div className="input-field">
                    <TextField
                      name="confirm_password"
                      id="confirm_password"
                      type={showPasswordConfirm ? "text" : "password"}
                      label="PASSWORT WIEDERHOLEN *"
                      placeholder="PASSWORT WIEDERHOLEN*"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={state.confirm_password}
                      onChange={handleChange}
                      InputProps={{
                        style: { paddingLeft: "10px" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("confirmpassword")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordConfirm ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      className="input-field-PP"
                    />
                  </div>
                  <span className="error">{errors.confirm_password}</span>
                </Col>
                <Col md={6} className=" mb-3">
                  {!addPromocode && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setAddPromocode(true);
                      }}
                    >
                      <FaPlusCircle
                        className=" mr-1"
                        sx={{ fontSize: 26, color: "#000" }}
                      />
                      <label
                        htmlFor="agree"
                        className="margleft "
                        style={{ display: "initial" }}
                      >
                        Promo-Code hinzufügen
                      </label>
                    </div>
                  )}
                  {addPromocode && (
                    <>
                      <div className="input-field">
                        <TextField
                          name="promo_code"
                          id="promo_code"
                          type="text"
                          label="Promotionscode "
                          placeholder="Promotionscode*"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={state.promo_code}
                          onChange={handleChange}
                          InputProps={{
                            style: { paddingLeft: "10px" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setAddPromocode(false);
                                    setError((prev) => ({
                                      ...prev,
                                      promo_code: "",
                                    }));
                                    setState((prev) => ({
                                      ...prev,
                                      promo_code: "",
                                    }));
                                  }}
                                  edge="end"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          className="input-field-PP"
                        />
                      </div>
                      <span className="error">{errors.promo_code}</span>
                    </>
                  )}
                </Col>

                <Col md={12} className="mt-1 mb-1 agb-container">
                  <input
                    type="checkbox"
                    id="agree"
                    onChange={checkboxHandler}
                  />
                  <label
                    htmlFor="agree"
                    className="margleft "
                    style={{ display: "initial" }}
                  >
                    Ja,ich habe die{" "}
                    <Link to="/datenschutzerklarung" className="link_privacy">
                      Datenschutzerklärung
                    </Link>{" "}
                    zur Kenntnis genommen.
                  </label>
                </Col>
                <Col md={12} className="mt-2 mb-1">
                  <div className="Einloggen">
                    <button
                      disabled={agree}
                      className="LoginButton"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Registrierung abschließen
                      <ArrowForwardIosIcon
                        className="icon-registrieren"
                        sx={{ fontSize: 20 }}
                      />
                    </button>
                  </div>
                </Col>
                <Col
                  md={12}
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  Sie haben bereits ein Konto?{" "}
                  <Link
                    className="forgetPass ml-1"
                    to="/signin"
                    style={{ color: "#0056b3" }}
                  >
                    Loggen Sie sich hier einfach ein!
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
