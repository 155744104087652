/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncKautionSettingsComponent,
	
} from 'routes';
const kautionsettings = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
				<ProtectedRoute path={`${match.url}`} component={AsyncKautionSettingsComponent} route={"product_import"} ></ProtectedRoute>
		
			</FirebaseContextProvider>
		</Store>

	</Switch>
)
export default kautionsettings;