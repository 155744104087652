import React, { createContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {

    const [initialState, setinitialState] = useState(
        {
            closed: false,
            company_id: null,
            contract_status: "active",
            created_at: null,
            created_by: "",
            created_remote: true,
            id: '',
            kaution_calculator: {
                amount: null,
                deposit_amount: null,
                deposit_converted: false,
            },
            landlord: {
                address: {
                    address_note: null,
                    city: null,
                    house_number: null,
                    plz: null,
                    street: null,
                },
                company_name: null,
                email: null,
                first_name: null,
                last_name: null,
                phone: null,
                salutation: 'noChoice',
                title: 'noChoice',
                type: 'noChoice',
            },
            landlord_is_makler: false,
            object: {
                address: {
                    address_note: null,
                    city: null,
                    house_number: null,
                    plz: null,
                    position: null,
                    street: null,
                },
                date_of_renovation: null,
                has_many_object: false,
                id_object: null,
                id_realEstate: null,
                note: null,
                object_list_number: 0,
            },
            pdf_created: false,
            renter: {
                address: {
                    address_note: null,
                    city: null,
                    house_number: null,
                    new_address: false,
                    plz: null,
                    street: null,
                },
                date_of_birth: null,
                email: null,
                first_name: null,
                last_name: null,
                phone: null,
                salutation: "noChoice",
                title: "noChoice",
                signature_renter: {
                    signature_renter: false,
                    signature_renter_image_name: null,
                    signature_renter_image_remote_url: null,
                    signature_renter_local_path_image: null,
                    signature_renter_time_stamp: null,
                    signature_renter_thumb: null,
                },
            },
            has_corenter: false,
            coRenter: {
                first_name: null,
                last_name: null,
                salutaion: "noChoice",
                title: "noChoice",
                date_of_birth: null,
                email: null,
                phone: null,
                address: {
                    address_note: null,
                    city: null,
                    house_number: null,
                    plz: null,
                    street: null,
                },
            },
            deposit_amount_information: {
                cold_rent: null,
                rental_agreement_signed_on: null,
                rental_starts_on: null,
                deposit_other_options: {
                    deposit_for_newRrentalProperty: false,
                    convert_security_deposit: true,
                    rental_agreement_limited: false,
                    rental_agreement_limited_date: null,
                    rental_agreement_terminated: false,
                    paid_rent_late_more_than_once: false,
                    currently_damage_to_the_rentalProperty: false,
                    already_fully_paid_the_agreed_rentalDeposit: false,
                    rentalDeposit_already_usedUp: false,
                    guarantee_sent_to: null,
                }
            },
            contract_leagl: false,
            sepa_account_information: {
                sepa_account_holder: null,
                sepa_bank: null,
                sepa_bic: null,
                sepa_check_box: false,
                sepa_iban: null,
            },
            send_email: null,
            send_pdf: false,
            synched_remote: true,
            updated_at: null,
        });

    const [sharedKautionState, setSharedKautionState] = useState(() => {
        const storedState = localStorage.getItem("Kaution");
        return storedState ? JSON.parse(storedState) : initialState;
    });

    return (
        <MyContext.Provider value={{ sharedKautionState, setSharedKautionState }}>
            {children}
        </MyContext.Provider>
    );
};
export { MyContext };

