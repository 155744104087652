import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  getDoc,
  onSnapshot,
  limit,
  Timestamp,
} from "firebase/firestore";
import { db } from "../config/firebase";

export const contractService = {
  updateContract,
  duplicateContract,
  getList,
  getContractListRealTime,
  deleteContract,
  getContract,
  getinDraftList,
  getContractLimitList,
  getinDraftContractLimitList,
  getContractLimitListbyType,
  getContractListlength,
  getContractindraftListlength,
  getContractListbystatusnotclosed,
  downloadPdf,
  deleteOrArchivContracts,
};

async function updateContract(contract_id, contract) {
  const docRef = doc(db, "xc_contract", contract_id);
  return await updateDoc(docRef, contract);
}

/*----------------------------------------------------------------*/
async function getContractListlength(user_id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "active"),
    where("created_by", "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const documentCount = querySnapshot.size;
  return documentCount;
}

async function getContractindraftListlength(user_id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "inDraft"),
    where("created_by", "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const documentCount = querySnapshot.size;
  return documentCount;
}

async function getContractListbystatusnotclosed(user_id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "active"),
    where("created_by", "==", user_id),
    where("product_sealed", "==", false)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getContractLimitList(user_id, paginationsize) {
  let size = paginationsize ? paginationsize : 10;
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "active"),
    where("created_by", "==", user_id),
    limit(size)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getContractLimitListbyType(user_id, paginationsize, type) {
  let size = paginationsize ? paginationsize : 10;
  const queryRef = query(
    collection(db, "xc_contract"),
    where("product_type", "==", type),
    where("contractStatus", "==", "active"),
    where("created_by", "==", user_id),
    limit(size)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getinDraftContractLimitList(user_id, paginationsize) {
  let size = paginationsize ? paginationsize : 10;
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "inDraft"),
    where("created_by", "==", user_id),
    limit(size)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
/*----------------------------------------------------------------*/

async function getList(field, id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "active"),
    where(field, "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getinDraftList(field, user_id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "inDraft"),
    where(field, "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function duplicateContract(contract) {
  const docRef = doc(db, "xc_contract", contract.id);
  try {
    await setDoc(docRef, contract);
    return docRef.id;
  } catch (error) {
    console.log(error);
  }
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};

async function deleteContract(contract_id) {
  const docRef = doc(db, "xc_contract", contract_id);
  await deleteDoc(docRef);
}

async function deleteOrArchivContracts(documents, type) {
  try {
    await Promise.all(documents.map(async (id) => {
      const docRef = doc(db, "xc_contract", id);
      let updateData = {
        updated_at: Timestamp.now()
      };
      if (type === "delete") {
        updateData.contractStatus = "deleted";
      } else {
        updateData.contractStatus = "inDraft";
      }
      const docSnapshot = await getDoc(docRef);
      const docData = docSnapshot.data();

      // Check if pdf_created is true, then set status to deleted and add is_deleted field
      if (type === "delete" && docData.pdf_created === true) {
        updateData.contractStatus = "deleted";
        updateData.is_deleted = true;
      }

      await updateDoc(docRef, updateData);
    }));
    console.log("Documents updated successfully!");
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}
async function getContract(contract_id) {
  const docRef = doc(db, "xc_contract", contract_id);
  return await getDoc(docRef);
}

async function getContractById(contract_id) {
  const docRef = doc(db, "xc_contract", contract_id);
  return await getDoc(docRef);
}

function getContractListRealTime(user_id) {
  const queryRef = query(
    collection(db, "xc_contract"),
    where("contractStatus", "==", "active"),
    where("created_by", "==", user_id)
  );

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      queryRef,
      (snapshot) => {
        const formattedArray = formatQuerySnapshotToArray(snapshot);
        resolve(formattedArray);
      },
      reject
    );

    // Attach the unsubscribe function to the resolved Promise
    resolve.unsubscribe = unsubscribe;
  });
}

async function downloadPdf(contract_id, type) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/download/${type}/${contract_id}`,
    requestOptions
  ).then(handleResponse);
}
// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
